.cd-page-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: @gutter;

  .cd-page-header__title {
    font-size: 1.28em;
    font-weight: 700;
    line-height: 1.2857em;
    text-transform: none;
    color: rgba(0, 0, 0, 0.87);
    margin: 0;
  }

  .cd-page-header__subtitle {
    line-height: 1.4em;
    color: rgba(0, 0, 0, 0.6);
  }

  .cd-page-header__actions {
    display: flex;
    align-items: center;
  }

  .cd-page-header__actions > * {
    margin: 0 0 0 10px;
  }
}
