.cd-statistics {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }

  cd-statistic {
    flex: 1;
    padding: 10px;
    margin-bottom: 20px;

    @media (max-width: 768px) {
      padding: 0;
    }

    .panel {
      height: 100%;
      margin-bottom: 0;
    }

    &:first-child {
      padding-left: 0px;
    }

    &:last-child {
      padding-right: 0px;
    }

    .cd-statistic__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid rgba(34, 36, 38, 0.1);
      padding: 1em;

      i {
        margin-right: 0.5em;
      }
    }

    .cd-statistic__title {
      display: flex;
      align-items: center;
      text-transform: uppercase;
      font-weight: bold;
    }

    .popover-content {
      white-space: pre-line;
    }
  }
}
