.event-detail-intentions {
  .panel-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .panel-heading__right {
      align-items: flex-end;
      button {
        margin: 0px 10px 0px 0px;
      }
    }
  }
}
