#resourceSelector {
  .resource__selector {
    width: 100%;
    max-height: 250px !important;
    .resource__selector--church__div {
      .resource__selector--church__li {
        border-bottom: 1px solid #ddd;
      }
    }
  }

  button.btn.btn-basic:focus {
    border-color: #66afe9;
  }

  #print-calendar__records__search__input {
    width: 450px;
    @media (max-width: 768px) {
      width: 250px;
    }
    @media (max-width: 425px) {
      width: 150px;
    }
    margin-right: 10px;
    margin-bottom: 10px;
  }
}
