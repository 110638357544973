.new-multi-parish-modal__image__container {
  position: relative;
  color: white;
  display: flex;
  align-items: center;
  text-align: center;

  .new-multi-parish-modal__image__text {
    position: absolute;
    margin: 0px 30px;
    max-width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.new-multi-parish-modal__body {
  padding: 20px;
  display: flex;

  .new-multi-parish-modal__body__left {
    flex: 2;
  }

  .new-multi-parish-modal__body__right {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex: 1;

    img {
      border-radius: 50%;
      border: 2px solid #bcbcbc;
      width: 100px;
      height: 100px;
      margin-bottom: 10px;
    }

    .new-multi-parish-modal__body__right__links {
      display: flex;
      justify-content: space-around;
      width: 100%;
    }
  }
}
