.padding() {
  padding: 15px;
}

.multi-churching {
  .multi-churching__dropdown {
    min-width: 220px;
  }

  .multi-churching__empty-state,
  .multi-churching__search,
  .multi-churching__controls {
    .padding();
  }

  .multi-churching__empty-state {
    text-align: center;

    i {
      margin-bottom: 12px;

      &:before {
        width: auto;
      }
    }
  }

  .multi-churching__item {
    padding: 8px 10px;
    line-height: 20px;
    text-transform: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    clear: both;
    font-weight: 400;
    display: block;
    color: inherit;
    margin: 0;

    &:hover {
      text-decoration: none;
      background-color: #f5f5f5;
    }

    &.active {
      background-color: rgba(0, 0, 0, 0.05);
    }

    i {
      margin-right: 5px;
    }
  }

  .multi-churching__church-name {
    margin-left: 5px;
  }

  .divider {
    margin: 0;
  }
}
