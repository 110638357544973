.scrollable {
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.pos-rlt {
  position: relative;
}

.pos-stc {
  position: static;
}

.pos-abt {
  position: absolute;
}

.line {
  *width: 100%;
  height: 2px;
  margin: 10px 0;
  font-size: 0;
  overflow: hidden;
  background-color: #fff;
  border-width: 0;
  border-top: 1px solid #eaedef;
}

.line-xs {
  margin: 0;
}

.line-lg {
  margin-top: 15px;
  margin-bottom: 15px;
}

.line-dashed {
  border-style: dashed;
  background: transparent;
}

.no-line {
  border-width: 0;
}

.no-border {
  border-color: transparent !important;
}

.no-radius {
  border-radius: 0;
}

.block {
  display: block;
}

.block.hide {
  display: none;
}

.inline {
  display: inline-block;
}

.pull-right-lg {
  float: right;
}

.pull-none {
  float: none;
}

.form-control-trans {
  border: none;
  background: transparent;
}

.btn-s-xs {
  min-width: 90px;
}

.btn-s-sm {
  min-width: 100px;
}

.btn-s-md {
  min-width: 120px;
}

.btn-s-lg {
  min-width: 150px;
}

.btn-s-xl {
  min-width: 200px;
}

.l-h-2x {
  line-height: 2em;
}

.l-h-1x {
  line-height: 1.2;
}

.v-middle {
  vertical-align: middle !important;
}

.v-top {
  vertical-align: top;
}

.v-bottom {
  vertical-align: bottom;
}

.font-thin {
  font-weight: 300;
}

.font-normal {
  font-weight: normal;
}

.font-bold {
  font-weight: 700;
}

.text-md {
  font-size: 13px;
}

.text-sm {
  font-size: 11px;
}

.text-xs {
  font-size: 10px;
}

.text-ellipsis {
  display: block;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-uc {
  text-transform: uppercase;
}

.wrapper {
  padding: 15px;
}

.wrapper-lg {
  padding: 30px;
}

.wrapper-xl {
  padding: 50px;
}

.padder {
  padding-left: 15px;
  padding-right: 15px;
}

.padder-v {
  padding-top: 15px;
  padding-bottom: 15px;
}

.no-padder {
  padding: 0 !important;
}

.p-v-none {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.p-h-none {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.p-t-none {
  padding-top: 0 !important;
}

.p-r-none {
  padding-right: 0 !important;
}

.p-b-none {
  padding-bottom: 0 !important;
}

.p-l-none {
  padding-left: 0 !important;
}

.pull-in {
  margin-left: -15px;
  margin-right: -15px;
}

.pull-out {
  margin: -10px -15px;
}

.b-t {
  border-top: 1px solid #e0e4e8;
}

.b-r {
  border-right: 1px solid #e0e4e8;
}

.b-b {
  border-bottom: 1px solid #e0e4e8;
}

.b-l {
  border-left: 1px solid #e0e4e8;
}

.r {
  border-radius: 2px 2px 2px 2px;
}

.r-l {
  border-radius: 2px 0 0 2px;
}

.r-r {
  border-radius: 0 2px 2px 0;
}

.r-t {
  border-radius: 2px 2px 0 0;
}

.r-b {
  border-radius: 0 0 2px 2px;
}

.m-n {
  margin: 0;
}

.m-l {
  margin-left: 15px;
}

.m-l-none {
  margin-left: 0;
}

.m-l-xs {
  margin-left: 5px;
}

.m-l-sm {
  margin-left: 10px;
}

.m-l-lg {
  margin-left: 20px;
}

.m-l-n {
  margin-left: -15px;
}

.m-l-n-xxs {
  margin-left: -1px;
}

.m-l-n-xs {
  margin-left: -5px;
}

.m-l-n-sm {
  margin-left: -10px;
}

.m-l-n-lg {
  margin-left: -20px;
}

.m-t {
  margin-top: 15px;
}

.m-t-none {
  margin-top: 0;
}

.m-t-xxs {
  margin-top: 1px;
}

.m-t-xs {
  margin-top: 5px;
}

.m-t-sm {
  margin-top: 10px;
}

.m-t-lg {
  margin-top: 20px;
}

.m-t-n {
  margin-top: -15px !important;
}

.m-t-n-xxs {
  margin-top: -1px;
}

.m-t-n-xs {
  margin-top: -5px;
}

.m-t-n-sm {
  margin-top: -10px;
}

.m-t-n-lg {
  margin-top: -20px;
}

.m-r {
  margin-right: 15px;
}

.m-r-none {
  margin-right: 0;
}

.m-r-xs {
  margin-right: 5px;
}

.m-r-sm {
  margin-right: 10px;
}

.m-r-lg {
  margin-right: 20px;
}

.m-r-n {
  margin-right: -15px;
}

.m-r-n-xxs {
  margin-right: -1px;
}

.m-r-n-xs {
  margin-right: -5px;
}

.m-r-n-sm {
  margin-right: -10px;
}

.m-r-n-lg {
  margin-right: -20px;
}

.m-b {
  margin-bottom: 15px;
}

.m-b-none {
  margin-bottom: 0;
}

.m-b-xs {
  margin-bottom: 5px;
}

.m-b-sm {
  margin-bottom: 10px;
}

.m-b-lg {
  margin-bottom: 20px;
}

.m-b-n {
  margin-bottom: -15px;
}

.m-b-n-xxs {
  margin-bottom: -1px;
}

.m-b-n-xs {
  margin-bottom: -5px;
}

.m-b-n-sm {
  margin-bottom: -10px;
}

.m-b-n-lg {
  margin-bottom: -20px;
}

.media-xs {
  min-width: 50px;
}

.media-sm {
  min-width: 80px;
}

.media-md {
  min-width: 90px;
}

.media-lg {
  min-width: 120px;
}

.thumb {
  width: 64px;
  display: inline-block;
}

.thumb-lg {
  width: 90px;
  display: inline-block;
}

.thumb-xs {
  width: 18px;
  display: inline-block;
}

.thumb-sm {
  width: 36px;
  display: inline-block;
}

.clear {
  display: block;
  overflow: hidden;
}

.m-xl {
  margin-top: 50px;
  margin-bottom: 50px;
}

.resize-v {
  resize: vertical;
}
