.list-switcher {
  .list-group-item {
    border: 0;
    padding: 10px 15px;

    .fa-pencil-alt,
    .fa-layers,
    .fa-money-check-edit {
      display: none;
    }

    &:hover {
      .fa-pencil-alt,
      .fa-layers,
      .fa-money-check-edit {
        display: block;
        color: #777777;

        &:hover {
          color: #333333;
        }
      }
    }
  }
  .badge {
    white-space: nowrap;
    background-color: #ccc;
  }

  .list-group-item__selected {
    background-color: #eeeeee;
    font-weight: bold;
  }
}
