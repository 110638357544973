.cd-legal-documents {
  .cd-legal-document {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:last-child) {
      border-bottom: 1px solid #ebebeb;
      margin-bottom: 10px;
      padding-bottom: 10px;
    }
  }

  .cd-legal-document__header {
    display: flex;
    flex-direction: column;
  }

  .cd-legal-document__title {
    margin-right: 1em;
    font-weight: bold;
  }

  .cd-legal-document__subheader {
    margin-top: 5px;
    font-size: 85%;
    color: #777;
  }
}
