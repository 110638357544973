/**
 * This file contains styles that overwrite external libraries. Use this for:
 *
 * 1) Overwriting existing third-party styles
 * 2) Adding new styles that extend the third-party ones
 *
 * Divide each library in its own section for easier lookup
 */

/*------------------------------------*\
  #BOOTSTRAP
\*------------------------------------*/

.tooltip {
  z-index: 15002;
}

/**
 * Buttons
 */

.btn {
  cursor: pointer;
  font-size: @font-size-base;
  outline: none;
  text-shadow: none;

  &:focus,
  &:active,
  &.active {
    outline: 0 !important;
  }

  &.btn-basic {
    .button-variant(@btn-basic-color; @btn-basic-bg; @btn-basic-border);

    &.btn-primary {
      .button-variant(@brand-primary; @btn-basic-bg; @brand-primary);
    }

    &.btn-primary {
      .button-variant(@brand-success; @btn-basic-bg; @brand-success);
    }

    &.btn-danger {
      .button-variant(@brand-danger; @btn-basic-bg; @brand-danger);
    }

    &.btn-info {
      .button-variant(@brand-info; @btn-basic-bg; @brand-info);
    }

    &.btn-warning {
      .button-variant(@brand-warning; @btn-basic-bg; @brand-warning);
    }
  }

  &.lockbutton {
    height: auto;
    margin-right: 0;
  }

  >i.fa:not(:only-child) {
    margin-right: 5px;
  }

  &:not(.btn-default)&:not(.btn-basic)&[disabled] {
    color: #ffffff;
  }

  &.btn-lg {
    .button-size(@padding-large-vertical; @padding-large-horizontal; 14px; @line-height-large; @border-radius-large);
  }

  &.btn-sm {
    .button-size(5px; 10px; 12px; 1.5; 3px);
  }

  &.btn-xs {
    .button-size(1px; 5px; 12px; 1.5; 3px);
  }

  &.btn-link:focus,
  &.btn-link:hover {
    text-decoration: none;
  }
}

.btn-group {
  .btn {
    margin: 0;
  }

  &.btn-group-justified .btn {
    width: 100%;
  }
}

.btn-fluid-xs {
  @media (max-width: 767px) {
    width: 100%;
  }
}

/*
 * Tables
 */

.table {
  width: 100%;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  box-shadow: none;
  border-radius: 0.28571429rem;
  text-align: left;
  color: rgba(0, 0, 0, 0.87);
  border-spacing: 0;
  margin-bottom: 0;

  thead {
    tr {
      height: 48px;

      th {
        background: #f9fafb;
        padding: 1em;
        line-height: normal;
        border-bottom: 0;

        span,
        a {
          color: rgba(0, 0, 0, 0.95);
          text-transform: uppercase;
          font-size: 12px;

          .fa {
            margin-left: 0.5em;
          }

          &:hover,
          &:focus {
            text-decoration: none;
          }
        }
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 1em;

        a>i {
          padding: 0 !important;
          height: auto;
          background: transparent !important;
          color: rgba(0, 0, 0, 0.87);
          opacity: 0.45 !important;
          cursor: pointer;

          &:hover {
            opacity: 1 !important;
          }
        }

        .dropdown-menu li>a>i {
          color: inherit;
          padding-right: 8px !important;
          cursor: inherit;
          opacity: 1 !important;
        }
      }
    }
  }

  thead>tr>th {
    border-bottom: 1px solid #ddd;
    height: 52px;
    text-transform: none;
  }

  td.fit,
  th.fit {
    white-space: nowrap;
    width: 1%;
    text-align: center;
  }

  thead>tr>th,
  tbody>tr>th,
  tfoot>tr>th,
  thead>tr>td,
  tbody>tr>td,
  tfoot>tr>td {
    vertical-align: middle;
  }

  &.table-sortable {
    th:not(.disabled):hover {
      cursor: pointer;
      background: #f0f2f5 !important;
    }
  }

  tbody tr td.disabled {
    pointer-events: none;
    color: rgba(40, 40, 40, 0.3);
  }

  thead tr th.check,
  tbody tr td.check {
    padding: 0;

    label {
      margin: 0;
      display: block;
      padding: 1em;
    }
  }

  .table {
    background-color: white;
  }
}

/**
 * Overflowing table, horizontally scrollable
 */
.table-h-scroll {
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  background: linear-gradient(90deg, #fff 100%, rgba(255, 255, 255, 0)) 0 0,
    linear-gradient(270deg, #fff 100%, rgba(255, 255, 255, 0)) 100% 0,
    linear-gradient(90deg, #f0f0f0 0, rgba(255, 255, 255, 0)) 0 0,
    linear-gradient(270deg, #f0f0f0 0, rgba(255, 255, 255, 0)) 100% 0;
  background-repeat: no-repeat;
  background-color: #fff;
  background-size: 16px 100%;
  background-attachment: local, local, scroll, scroll;

  table {
    background: transparent;
  }
}

/*
 * Table with fixed header
 *
 * The following rules must be added to the parent .app-main:
 *
 * .app-main {
 *   display: flex;
 *   position: relative;
 *   overflow-y: hidden;
 *   overflow-x: auto;
 *   padding-bottom: 0;
 * }
 *
 * Example of usage:
 *
 * <div class="app-main">
 *   <div class="table-fixed">
 *     <table class="table">
 *       <thead>...</thead>
 *       <tbody>...</tbody>
 *     </table>
 *   </div>
 * </div>
 */
.table-fixed {
  min-width: 100%;
  align-self: flex-start;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  margin-top: calc(@gutter * 1.5);

  .table {
    thead {
      tr {
        height: 0;
      }

      th {
        position: static;
        height: 0;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        border: 0 !important;

        &.check label {
          padding-top: 0 !important;
          padding-bottom: 0 !important;
        }
      }

      .table__cell-width-el {
        visibility: hidden;
        display: block;
        height: 0;
        white-space: nowrap;
      }

      .table__cell-content {
        position: absolute;
        top: @gutter;
        white-space: nowrap;
      }
    }

    th.fit,
    td.fit {
      width: auto;
    }

    tr:first-child td {
      border-top: 0;
    }
  }
}

/*
 * Dropdowns
 */

a:disabled,
a[disabled] {
  color: #00000040 !important;
}

.dropdown {
  .dropdown-toggle .caret {
    margin-left: 5px;
  }
}

.dropdown-menu {
  overflow: hidden;
  box-shadow: none;
  margin: 0 0 0;
  min-width: 200px;
  width: auto;
  text-align: left;
  padding: 0;
  font-size: 14px;

  i:before {
    width: 16px;
    text-align: center;
    display: inline-block;
  }

  li {
    position: relative;

    .help-info {
      position: absolute;
      right: 7px;
      top: 7px;
      width: 16px;
      height: 20px;
      font-size: 16px;
      display: block;
      color: @gray-light;

      &:hover {
        color: @brand-primary;
      }
    }

    &.active>a {
      background: rgba(0, 0, 0, 0.03) !important;
      font-weight: 700 !important;
    }
  }

  li>a {
    padding: 8px 10px;
    line-height: 20px;
    text-transform: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
  }

  li:not(.disabled):not(:disabled):not([disabled])>a:not(.disabled):not(:disabled):not([disabled]) {
    color: @text-color !important;
  }

  li>a>i {
    padding-right: 8px;
  }

  .dropdown-header {
    margin: 1rem 0 0.75rem;
    padding: 0 1.14285714rem;
    color: rgba(0, 0, 0, 0.85);
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
  }

  .divider {
    margin: 0.5em 0;
  }

  table tr td[id^='datepicker'] button,
  table tr th button {
    width: auto !important;
    min-width: 100% !important;
  }

  li>a {
    padding: 8px 10px;

    i {
      font-size: 1em;
    }

    &:hover {
      color: #333333;
    }
  }

  &:focus {
    outline: 0;
  }

  // Datepicker dropdown
  div[ng-switch='datepickerMode'] {
    table {
      padding: 0 !important;

      thead {
        tr:first-child {
          background: #f0f0f0 !important;

          th {
            padding: 4px 0 !important;

            &:hover {
              background: #ddd !important;
            }
          }

          .btn {
            background: transparent !important;
          }
        }

        tr:nth-child(2) th {
          padding: 6px 0 !important;
        }
      }

      &:focus {
        outline: 0 !important;
      }

      .btn {
        border: none !important;
        border-radius: 0 !important;
        padding: 6px 12px !important;
        box-shadow: none !important;
        background-color: #fff !important;

        &:hover {
          background: #f0f0f0 !important;
        }

        &.active {
          background-image: none !important;
          color: #333 !important;
          background-color: #e6e6e6 !important;
          border-color: #adadad !important;
        }

        .text-info {
          color: #e03997 !important;
        }

        // Active day in the picker
        &.btn-info {
          background: #e03997 !important;

          >span {
            color: #fff !important;
          }
        }
      }

      tbody>tr>td:first-child>em {
        color: #ccc !important;
      }
    }
  }

  &.dropdown-menu--scrollable {
    height: auto;
    max-height: 360px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }

  &.dropdown-menu--groupable {
    li>a {
      display: block;
      white-space: nowrap;

      &:hover,
      &:focus {
        text-decoration: none;
        color: @dropdown-link-hover-color;
        background-color: @dropdown-link-hover-bg;
      }
    }
  }
}

/*
 * Panels
 */

.panel {
  border: none;
  box-shadow: none;
  box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;

  .panel-heading {
    background: #ffffff;
    border-bottom: 1px solid rgba(34, 36, 38, 0.1);
    padding: 1em;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;

    a {
      padding: 15px 15px 15px 0;
    }

    a.btn.btn-qtip {
      padding: 1px 5px;
    }

    a.pull-right {
      font-size: 13px;
    }

    .panel-title {
      padding: 0;
      font-weight: 700;
      font-size: 13px;
      line-height: 1.2857em;
    }
  }

  .panel-footer {
    background-color: white;
  }

  .steps {
    margin: 0;
    border: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    .step {
      border-bottom: 1px solid rgba(34, 36, 38, 0.15);

      &:first-child {
        border-top-left-radius: inherit;
      }

      &:last-child {
        border-right: none;
        border-top-right-radius: inherit;
      }

      &.active {
        border-bottom-color: transparent;
      }
    }
  }

  .list-group {
    .list-group-item {
      border-color: rgba(34, 36, 38, 0.1);
      border-width: 1px 0;

      .pagination {
        display: inline-block;
        padding-left: 0;
        margin: 5px 0 0 0;
        border-radius: 4px;
      }
    }
  }

  &.panel-fixed {
    position: fixed;
    z-index: 101;
    margin: 0;
    width: 100%;
    border-radius: 0 !important;
    top: 0;
    left: 0;
    right: auto;
    bottom: auto;
    opacity: 0.9;
    box-shadow: 0 0 10px 0 rgba(34, 36, 38, 0.15);
    margin-left: @navWidth;
    padding: 0 @navWidth 0 0;

    &.panel-fixed-bottom {
      border-bottom: none;
      border-left: none;
      border-right: none;
      bottom: 0;
      left: 0;
      top: auto;
      right: auto;
    }
  }
}

.panel-group .panel {
  +.panel {
    margin-top: 20px;
  }

  .panel-heading {
    border: 0;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;

    a {
      padding: 0;
      color: #333;
      display: block;

      &:focus,
      &:hover {
        text-decoration: none;
      }
    }
  }

  &.panel-open {
    .panel-heading {
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      border-bottom: 1px solid rgba(34, 36, 38, 0.1);
    }

    .panel-body {
      border: 0;
    }
  }
}

.col-xs-12.col-sm-12.col-md-8.col-lg-8 {
  .panel .panel-heading {
    overflow: hidden;
  }
}

/**
 * Modals
 */

.modal-dialog {
  margin: 40px auto;
}

.modal-header .modal-title {
  font-size: 16px;
}

.modal-header,
.modal-body,
.modal-footer {
  padding: 20px;
}

// Remove modal overflow hidden when using select2 elements
// to avoid dropdowns being cut off at the bottom.
.modal-ui-select {

  .modal-content,
  .modal-body {
    overflow: visible;
    max-height: none;
  }
}

// Custom class to make modal body scrollable.
.modal-scrollable {
  .modal-body {
    max-height: calc(~'100vh - 220px');
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
}

/*
 * Grid
 */

.container-compact {
  max-width: 700px !important;
}

@media (min-width: 1440px) {

  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12 {
    float: left;
  }

  .col-xl-12 {
    width: 100%;
  }

  .col-xl-11 {
    width: 91.66666666666666%;
  }

  .col-xl-10 {
    width: 83.33333333333334%;
  }

  .col-xl-9 {
    width: 75%;
  }

  .col-xl-8 {
    width: 66.66666666666666%;
  }

  .col-xl-7 {
    width: 58.333333333333336%;
  }

  .col-xl-6 {
    width: 50%;
  }

  .col-xl-5 {
    width: 41.66666666666667%;
  }

  .col-xl-4 {
    width: 33.33333333333333%;
  }

  .col-xl-3 {
    width: 25%;
  }

  .col-xl-2 {
    width: 16.666666666666664%;
  }

  .col-xl-1 {
    width: 8.333333333333332%;
  }

  .col-xl-offset-12 {
    margin-left: 100%;
  }

  .col-xl-offset-11 {
    margin-left: 91.66666667%;
  }

  .col-xl-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-xl-offset-9 {
    margin-left: 75%;
  }

  .col-xl-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-xl-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-xl-offset-6 {
    margin-left: 50%;
  }

  .col-xl-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-xl-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-xl-offset-3 {
    margin-left: 25%;
  }

  .col-xl-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-xl-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-xl-offset-0 {
    margin-left: 0;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 100%;
  }
}

/*
 * Forms
 */

.form-control {
  &:focus {
    box-shadow: none;
  }
}

.form-control,
.btn:active,
.btn.active,
.btn-group.open .dropdown-toggle {
  box-shadow: none;
}

.form-inline {
  .form-group {
    p {
      margin: 7px 1em 7px 0;
    }
  }

  &.pull-right {
    .btn {
      margin: 0 0 0 0.25em;
    }
  }
}

form.ng-dirty {

  .form-control.ng-invalid,
  .ui-select-container.ng-invalid>.ui-select-match>.ui-select-toggle {
    border-color: @brand-danger;
  }
}

.input-group-addon--basic {
  background-color: #fff;
}

/**
 * Pagination
 */

// Pagination
ul.pagination {
  margin: 0;
}

ul.pagination li {

  span,
  a {
    vertical-align: baseline;
    background: #e0e1e2 !important;
    color: rgba(0, 0, 0, 0.6);
    margin: 0;
    padding: 0.78571429em 1.5em !important;
    font-weight: 700;
    line-height: 1em;
    border: none;

    &:hover {
      background-color: #c0c1c2 !important;
      background-image: none;
      color: rgba(0, 0, 0, 0.95);
      text-decoration: none;
    }
  }

  &.active {

    span,
    a {
      background-color: #c0c1c2 !important;
      background-image: none;
      color: rgba(0, 0, 0, 0.95);
      text-decoration: none;

      &:hover {
        background-color: #c0c1c2 !important;
        background-image: none;
        color: rgba(0, 0, 0, 0.95);
        text-decoration: none;
      }
    }
  }

  &.disabled {

    span,
    a {
      color: rgba(0, 0, 0, 0.6);
      opacity: 0.7;

      &:hover {
        color: rgba(0, 0, 0, 0.6);
        background: #e0e1e2 !important;
      }
    }
  }
}

/**
 * Breadcrumbs
 */
.breadcrumb {
  margin-bottom: 0;

  li.active>a {
    color: @breadcrumb-active-color;
  }
}

/*
 * Others
 */

.popover-title {
  font-weight: 700;
}

a.list-group-item.active,
a.list-group-item.active:hover,
a.list-group-item.active:focus {
  background-color: white;
  color: @text-color;
  border-color: #ddd;
}

#system-preferences,
#church-screen,
#group-preferences,
#print-calendar {
  .panel .panel-heading .panel-title {
    display: block;
  }
}

li.media {
  list-style-type: none;
}

.alert {
  margin-bottom: @gutter;
}

.pull-right .btn {
  margin: 0 0 0 0.25em;
}

/*------------------------------------*\
  #TOASTR
\*------------------------------------*/

#toast-container {
  div.toast {
    box-shadow: none;
    padding: 15px 30px 15px 15px;
  }

  &:hover {
    opacity: 0.9;
  }

  .toast-error,
  .toast-success,
  .toast-warning,
  .toast-info {
    background-image: none !important;
  }

  .toast-error {
    background-color: #e74c3c;
  }

  .toast-success {
    background-color: #2ecc71;
  }

  .toast-info {
    background-color: #55acee;
  }

  .toast-warning {
    background-color: #f1c40f;
  }

  &.toast-bottom-right {
    bottom: 20px;
    right: 20px;
  }

  &.toast-top-right {
    top: 20px;
    right: 20px;
  }

  &.toast-bottom-left {
    bottom: 20px;
    left: 20px;
  }

  &.toast-top-left {
    top: 20px;
    left: 20px;
  }
}

/*------------------------------------*\
  #HOTKEYS
\*------------------------------------*/

.cfp-hotkeys-container {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji' !important;
}

.cfp-hotkeys-title {
  line-height: 36px;
}

.cfp-hotkeys-close {
  right: 30px;
  min-height: 30px;
  min-width: 30px;
  font-weight: 700;
  font-size: 24px;
  padding: 0;
  border: none;
  border-radius: 0;
  text-align: center;
  font-family: 'FontAwesome';
}

.cfp-hotkeys-close:hover {
  background: none;
}

/*------------------------------------*\
  #UI-SELECT
\*------------------------------------*/

// Ensure ui-select dropdowns are scrollable.
.ui-select-dropdown.dropdown-menu {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

// Adjust multiple ui-select field to match height of bootstrap inputs.
.ui-select-multiple.ui-select-bootstrap {
  padding: 4px 3px 3px;
}

.ui-select-match {
  outline: 0;
  box-shadow: none;

  &.btn-default-focus .ui-select-toggle {
    border-color: @input-border-focus;
  }
}

// Showing both required border and focus border
.ui-select-container.ng-invalid {
  .ui-select-match.btn-default-focus {
    outline: 3px solid #66afe9;
  }
}

.btn-default-focus>.ui-select-toggle {
  border: 1px solid @brand-primary;
}

.ui-select-choices[hide-disabled-choice] .ui-select-choices-row.disabled {
  display: none;
}

.ui-select-choices-group-label {
  text-transform: uppercase;
}

.ui-match {
  font-weight: 700 !important;
}

.ui-select-choices .ui-select-choices-row.active>.ui-select-choices-row-inner {
  background-color: #f5f5f5;
  color: inherit;
}

.ui-select-choices .ui-select-choices-row.disabled {
  .ui-select-choices-row-inner * {
    color: #ccc;
  }
}

.ui-select-match {
  .btn-default {
    border-color: #ccc;
  }
}

.ui-select-toggle>.caret {
  pointer-events: none;
}

.ui-select-toggle.btn-default {
  background-color: white;

  &.btn-default.active,
  &.btn-default:active,
  &.btn-default:focus,
  &.btn-default:hover,
  &.open>.dropdown-toggle.btn-default,
  &[disabled],
  &.disabled {
    background-color: white;
  }
}

.ui-select-container.direction-up .ui-select-dropdown {
  bottom: 100% !important;
  top: auto !important;
}

.ui-select-invalid-outside-form {
  .ui-select-container.ng-invalid>.ui-select-match>.ui-select-toggle {
    border-color: @brand-danger;
  }
}

.ui-select-match-item.select-locked {
  pointer-events: none;
  opacity: 0.8;
}

.ui-select-highlight {
  font-weight: inherit;
  background-color: #ff0;
}

/*------------------------------------*\
  #OTHERS
\*------------------------------------*/

.intl-tel-input {
  width: 100%;
}

// Accordion floated right arrow.
i.fa.fa-angle-down.pull-right {
  margin-top: 4px;
}

i.label span {
  font-weight: 700;
}

.dropdown-menu-modal {
  z-index: 1051;
}

/*------------------------------------*\
  #COLORPICKER
\*------------------------------------*/

.color-picker-container {
  display: block;
  position: relative;

  .color-picker-clear-button {
    position: absolute;
    z-index: 2;
    right: 0;
    top: 0;
    margin: 6px 10px;
  }
}

input.color-picker-input {
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 0 4px 4px 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    outline: 0;
  }
}

/*------------------------------------*\
  #STRIPE-ELEMENTS
\*------------------------------------*/

.StripeElement {
  display: block;
  width: 100%;
  height: @input-height-base;
  padding: @padding-base-vertical @padding-base-horizontal;
  font-size: @font-size-base;
  line-height: @line-height-base;
  color: @input-color;
  background-color: @input-bg;
  background-image: none;
  border: 1px solid @input-border;
  border-radius: @input-border-radius;
  .transition(~'border-color ease-in-out .15s, box-shadow ease-in-out .15s');
  .placeholder();

  &.StripeElement--focus {
    @color: @input-border-focus;
    @color-rgba: rgba(red(@color), green(@color), blue(@color), 0.6);
    border-color: @color;
    outline: 0;
  }

  &.StripeElement--invalid {
    border-color: @brand-danger;
  }
}

/*------------------------------------*\
  #ui-select with allow-clear=true
\*------------------------------------*/

i.glyphicon {
  &.glyphicon-remove {
    position: absolute;
    right: 5px;
    top: 5px;
  }

  &.glyphicon-remove::after {
    content: '\f00d';
    font: normal normal normal 14px/1 'Font Awesome 5 Pro';
  }
}

/*------------------------------------*\
  #UIB Datepicker
\*------------------------------------*/

.uib-datepicker-popup {
  table {
    i.glyphicon {
      &.glyphicon-chevron-left::after {
        content: '\f104';
        font: normal normal normal 14px/1 'Font Awesome 5 Pro';
      }

      &.glyphicon-chevron-right::after {
        content: '\f105';
        font: normal normal normal 14px/1 'Font Awesome 5 Pro';
      }
    }
  }
}

label {
  // BC: nicer looking with Apple fonts.
  font-weight: 600;
}

/*------------------------------------*\
  Ensures that Bootstrap 3 buttons and inputs are similar to Ant Design
\*------------------------------------*/
html body {
  .form-control {
    height: 32px;
  }

  textarea.form-control {
    height: auto;
  }

  .input-sm {
    height: 30px;
  }

  .btn {
    line-height: 22px;
    padding: 4px 15px;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
  }

  .input-style {
    height: 32px;
    border: 1px solid #d9d9d9;
    padding: 4px 7px;
    border-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

label.ant-radio-wrapper {
  font-weight: 400;
}

.ant-dropdown-menu-item .anticon+span {
  margin-left: 8px;
}

.vertical-align-top {
  vertical-align: top;
}

// To avoid weird overflow on Windows and Linux machines
// https://github.com/ant-design/ant-design/issues/10144
/*.ant-row {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.ant-col:first-child {
  padding-left: 0 !important;
}

.ant-col:last-child {
  padding-right: 0 !important;
}*/
// DISABLED FOR NOW since it breaks the layout on Row wrap=true (see calendar create dropdown and event drawer location)