.cd-statistics__form-tickets,
.cd-statistics__form-submissions {
  padding: 10px 0 10px 10px;
}

.ticket-statistics {
  .ticket-statistics__ticket-name {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    font-weight: bold;
  }
  .ticket-statistics__progressbar {
    display: block;
    margin-bottom: 5px;

    .progress {
      margin: 0;
    }
  }
  .ticket-statistics__view-more {
    display: block;
    text-align: center;
  }

  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.qr-code {
  min-width: 100px;
}

code {
  display: flex;
  align-items: center;
}
