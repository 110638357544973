.image-drop-area {
  .image-drop-area__image img {
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  }

  .image-drop-area__header {
    margin-top: 25px;
    color: #008db6;
  }
}
