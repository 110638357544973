
/* new 23 fonts tp match the variable font of widget   */
/* cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-cyrillic-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/open-sans/files/open-sans-cyrillic-300-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-cyrillic-ext-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/open-sans/files/open-sans-cyrillic-ext-300-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-greek-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/open-sans/files/open-sans-greek-300-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* greek-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-greek-ext-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/open-sans/files/open-sans-greek-ext-300-normal.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* hebrew */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-hebrew-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/open-sans/files/open-sans-hebrew-300-normal.woff) format('woff');
  unicode-range: U+0590-05FF,U+200C-2010,U+20AA,U+25CC,U+FB1D-FB4F;
}

/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-latin-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/open-sans/files/open-sans-latin-300-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-latin-ext-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/open-sans/files/open-sans-latin-ext-300-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-vietnamese-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/open-sans/files/open-sans-vietnamese-300-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-cyrillic-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/open-sans/files/open-sans-cyrillic-400-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-cyrillic-ext-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/open-sans/files/open-sans-cyrillic-ext-400-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-greek-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/open-sans/files/open-sans-greek-400-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* greek-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-greek-ext-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/open-sans/files/open-sans-greek-ext-400-normal.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* hebrew */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-hebrew-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/open-sans/files/open-sans-hebrew-400-normal.woff) format('woff');
  unicode-range: U+0590-05FF,U+200C-2010,U+20AA,U+25CC,U+FB1D-FB4F;
}

/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-latin-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/open-sans/files/open-sans-latin-400-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-latin-ext-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/open-sans/files/open-sans-latin-ext-400-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-vietnamese-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/open-sans/files/open-sans-vietnamese-400-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-cyrillic-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/open-sans/files/open-sans-cyrillic-500-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-cyrillic-ext-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/open-sans/files/open-sans-cyrillic-ext-500-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-greek-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/open-sans/files/open-sans-greek-500-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* greek-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-greek-ext-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/open-sans/files/open-sans-greek-ext-500-normal.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* hebrew */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-hebrew-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/open-sans/files/open-sans-hebrew-500-normal.woff) format('woff');
  unicode-range: U+0590-05FF,U+200C-2010,U+20AA,U+25CC,U+FB1D-FB4F;
}

/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-latin-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/open-sans/files/open-sans-latin-500-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-latin-ext-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/open-sans/files/open-sans-latin-ext-500-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-vietnamese-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/open-sans/files/open-sans-vietnamese-500-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-cyrillic-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/open-sans/files/open-sans-cyrillic-600-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-cyrillic-ext-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/open-sans/files/open-sans-cyrillic-ext-600-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-greek-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/open-sans/files/open-sans-greek-600-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* greek-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-greek-ext-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/open-sans/files/open-sans-greek-ext-600-normal.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* hebrew */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-hebrew-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/open-sans/files/open-sans-hebrew-600-normal.woff) format('woff');
  unicode-range: U+0590-05FF,U+200C-2010,U+20AA,U+25CC,U+FB1D-FB4F;
}

/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-latin-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/open-sans/files/open-sans-latin-600-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-latin-ext-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/open-sans/files/open-sans-latin-ext-600-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-vietnamese-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/open-sans/files/open-sans-vietnamese-600-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-cyrillic-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/open-sans/files/open-sans-cyrillic-700-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-cyrillic-ext-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/open-sans/files/open-sans-cyrillic-ext-700-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-greek-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/open-sans/files/open-sans-greek-700-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* greek-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-greek-ext-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/open-sans/files/open-sans-greek-ext-700-normal.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* hebrew */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-hebrew-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/open-sans/files/open-sans-hebrew-700-normal.woff) format('woff');
  unicode-range: U+0590-05FF,U+200C-2010,U+20AA,U+25CC,U+FB1D-FB4F;
}

/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-latin-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/open-sans/files/open-sans-latin-700-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-latin-ext-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/open-sans/files/open-sans-latin-ext-700-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-vietnamese-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/open-sans/files/open-sans-vietnamese-700-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-cyrillic-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/open-sans/files/open-sans-cyrillic-800-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-cyrillic-ext-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/open-sans/files/open-sans-cyrillic-ext-800-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-greek-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/open-sans/files/open-sans-greek-800-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* greek-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-greek-ext-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/open-sans/files/open-sans-greek-ext-800-normal.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* hebrew */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-hebrew-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/open-sans/files/open-sans-hebrew-800-normal.woff) format('woff');
  unicode-range: U+0590-05FF,U+200C-2010,U+20AA,U+25CC,U+FB1D-FB4F;
}

/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-latin-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/open-sans/files/open-sans-latin-800-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-latin-ext-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/open-sans/files/open-sans-latin-ext-800-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/open-sans/files/open-sans-vietnamese-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/open-sans/files/open-sans-vietnamese-800-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto Flex';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/roboto-flex/files/roboto-flex-cyrillic-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/roboto-flex/files/roboto-flex-cyrillic-400-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Roboto Flex';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/roboto-flex/files/roboto-flex-cyrillic-ext-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/roboto-flex/files/roboto-flex-cyrillic-ext-400-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Roboto Flex';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/roboto-flex/files/roboto-flex-greek-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/roboto-flex/files/roboto-flex-greek-400-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* latin */
@font-face {
  font-family: 'Roboto Flex';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/roboto-flex/files/roboto-flex-latin-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/roboto-flex/files/roboto-flex-latin-400-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Roboto Flex';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/roboto-flex/files/roboto-flex-latin-ext-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/roboto-flex/files/roboto-flex-latin-ext-400-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Roboto Flex';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/roboto-flex/files/roboto-flex-vietnamese-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/roboto-flex/files/roboto-flex-vietnamese-400-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/space-grotesk/files/space-grotesk-latin-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/space-grotesk/files/space-grotesk-latin-300-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/space-grotesk/files/space-grotesk-latin-ext-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/space-grotesk/files/space-grotesk-latin-ext-300-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/space-grotesk/files/space-grotesk-vietnamese-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/space-grotesk/files/space-grotesk-vietnamese-300-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/space-grotesk/files/space-grotesk-latin-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/space-grotesk/files/space-grotesk-latin-400-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/space-grotesk/files/space-grotesk-latin-ext-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/space-grotesk/files/space-grotesk-latin-ext-400-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/space-grotesk/files/space-grotesk-vietnamese-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/space-grotesk/files/space-grotesk-vietnamese-400-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/space-grotesk/files/space-grotesk-latin-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/space-grotesk/files/space-grotesk-latin-500-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/space-grotesk/files/space-grotesk-latin-ext-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/space-grotesk/files/space-grotesk-latin-ext-500-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/space-grotesk/files/space-grotesk-vietnamese-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/space-grotesk/files/space-grotesk-vietnamese-500-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/space-grotesk/files/space-grotesk-latin-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/space-grotesk/files/space-grotesk-latin-600-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/space-grotesk/files/space-grotesk-latin-ext-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/space-grotesk/files/space-grotesk-latin-ext-600-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/space-grotesk/files/space-grotesk-vietnamese-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/space-grotesk/files/space-grotesk-vietnamese-600-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/space-grotesk/files/space-grotesk-latin-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/space-grotesk/files/space-grotesk-latin-700-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/space-grotesk/files/space-grotesk-latin-ext-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/space-grotesk/files/space-grotesk-latin-ext-700-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/space-grotesk/files/space-grotesk-vietnamese-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/space-grotesk/files/space-grotesk-vietnamese-700-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}
/* cyrillic */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-cyrillic-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/alegreya/files/alegreya-cyrillic-400-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-cyrillic-ext-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/alegreya/files/alegreya-cyrillic-ext-400-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-greek-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/alegreya/files/alegreya-greek-400-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* greek-ext */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-greek-ext-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/alegreya/files/alegreya-greek-ext-400-normal.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-latin-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/alegreya/files/alegreya-latin-400-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-latin-ext-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/alegreya/files/alegreya-latin-ext-400-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-vietnamese-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/alegreya/files/alegreya-vietnamese-400-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-cyrillic-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/alegreya/files/alegreya-cyrillic-500-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-cyrillic-ext-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/alegreya/files/alegreya-cyrillic-ext-500-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-greek-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/alegreya/files/alegreya-greek-500-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* greek-ext */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-greek-ext-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/alegreya/files/alegreya-greek-ext-500-normal.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-latin-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/alegreya/files/alegreya-latin-500-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-latin-ext-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/alegreya/files/alegreya-latin-ext-500-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-vietnamese-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/alegreya/files/alegreya-vietnamese-500-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-cyrillic-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/alegreya/files/alegreya-cyrillic-600-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-cyrillic-ext-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/alegreya/files/alegreya-cyrillic-ext-600-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-greek-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/alegreya/files/alegreya-greek-600-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* greek-ext */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-greek-ext-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/alegreya/files/alegreya-greek-ext-600-normal.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-latin-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/alegreya/files/alegreya-latin-600-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-latin-ext-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/alegreya/files/alegreya-latin-ext-600-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-vietnamese-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/alegreya/files/alegreya-vietnamese-600-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-cyrillic-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/alegreya/files/alegreya-cyrillic-700-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-cyrillic-ext-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/alegreya/files/alegreya-cyrillic-ext-700-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-greek-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/alegreya/files/alegreya-greek-700-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* greek-ext */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-greek-ext-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/alegreya/files/alegreya-greek-ext-700-normal.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-latin-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/alegreya/files/alegreya-latin-700-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-latin-ext-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/alegreya/files/alegreya-latin-ext-700-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-vietnamese-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/alegreya/files/alegreya-vietnamese-700-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-cyrillic-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/alegreya/files/alegreya-cyrillic-800-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-cyrillic-ext-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/alegreya/files/alegreya-cyrillic-ext-800-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-greek-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/alegreya/files/alegreya-greek-800-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* greek-ext */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-greek-ext-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/alegreya/files/alegreya-greek-ext-800-normal.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-latin-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/alegreya/files/alegreya-latin-800-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-latin-ext-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/alegreya/files/alegreya-latin-ext-800-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/alegreya/files/alegreya-vietnamese-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/alegreya/files/alegreya-vietnamese-800-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Cinzel';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cinzel/files/cinzel-latin-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/cinzel/files/cinzel-latin-400-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Cinzel';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cinzel/files/cinzel-latin-ext-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/cinzel/files/cinzel-latin-ext-400-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Cinzel';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cinzel/files/cinzel-latin-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/cinzel/files/cinzel-latin-500-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Cinzel';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cinzel/files/cinzel-latin-ext-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/cinzel/files/cinzel-latin-ext-500-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Cinzel';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cinzel/files/cinzel-latin-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/cinzel/files/cinzel-latin-600-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Cinzel';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cinzel/files/cinzel-latin-ext-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/cinzel/files/cinzel-latin-ext-600-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Cinzel';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cinzel/files/cinzel-latin-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/cinzel/files/cinzel-latin-700-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Cinzel';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cinzel/files/cinzel-latin-ext-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/cinzel/files/cinzel-latin-ext-700-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Cinzel';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cinzel/files/cinzel-latin-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/cinzel/files/cinzel-latin-800-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Cinzel';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cinzel/files/cinzel-latin-ext-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/cinzel/files/cinzel-latin-ext-800-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-cyrillic-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/inter/files/inter-cyrillic-300-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-cyrillic-ext-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/inter/files/inter-cyrillic-ext-300-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-greek-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/inter/files/inter-greek-300-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* greek-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-greek-ext-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/inter/files/inter-greek-ext-300-normal.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-latin-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/inter/files/inter-latin-300-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-latin-ext-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/inter/files/inter-latin-ext-300-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-vietnamese-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/inter/files/inter-vietnamese-300-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-cyrillic-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/inter/files/inter-cyrillic-400-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-cyrillic-ext-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/inter/files/inter-cyrillic-ext-400-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-greek-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/inter/files/inter-greek-400-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* greek-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-greek-ext-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/inter/files/inter-greek-ext-400-normal.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-latin-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/inter/files/inter-latin-400-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-latin-ext-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/inter/files/inter-latin-ext-400-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-vietnamese-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/inter/files/inter-vietnamese-400-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-cyrillic-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/inter/files/inter-cyrillic-500-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-cyrillic-ext-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/inter/files/inter-cyrillic-ext-500-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-greek-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/inter/files/inter-greek-500-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* greek-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-greek-ext-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/inter/files/inter-greek-ext-500-normal.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-latin-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/inter/files/inter-latin-500-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-latin-ext-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/inter/files/inter-latin-ext-500-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-vietnamese-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/inter/files/inter-vietnamese-500-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-cyrillic-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/inter/files/inter-cyrillic-600-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-cyrillic-ext-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/inter/files/inter-cyrillic-ext-600-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-greek-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/inter/files/inter-greek-600-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* greek-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-greek-ext-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/inter/files/inter-greek-ext-600-normal.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-latin-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/inter/files/inter-latin-600-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-latin-ext-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/inter/files/inter-latin-ext-600-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-vietnamese-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/inter/files/inter-vietnamese-600-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-cyrillic-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/inter/files/inter-cyrillic-700-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-cyrillic-ext-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/inter/files/inter-cyrillic-ext-700-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-greek-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/inter/files/inter-greek-700-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* greek-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-greek-ext-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/inter/files/inter-greek-ext-700-normal.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-latin-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/inter/files/inter-latin-700-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-latin-ext-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/inter/files/inter-latin-ext-700-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-vietnamese-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/inter/files/inter-vietnamese-700-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-cyrillic-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/inter/files/inter-cyrillic-800-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-cyrillic-ext-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/inter/files/inter-cyrillic-ext-800-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-greek-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/inter/files/inter-greek-800-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* greek-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-greek-ext-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/inter/files/inter-greek-ext-800-normal.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-latin-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/inter/files/inter-latin-800-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-latin-ext-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/inter/files/inter-latin-ext-800-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/inter/files/inter-vietnamese-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/inter/files/inter-vietnamese-800-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-cyrillic-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/manrope/files/manrope-cyrillic-300-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-cyrillic-ext-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/manrope/files/manrope-cyrillic-ext-300-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-greek-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/manrope/files/manrope-greek-300-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* latin */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-latin-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/manrope/files/manrope-latin-300-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-latin-ext-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/manrope/files/manrope-latin-ext-300-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-vietnamese-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/manrope/files/manrope-vietnamese-300-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-cyrillic-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/manrope/files/manrope-cyrillic-400-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-cyrillic-ext-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/manrope/files/manrope-cyrillic-ext-400-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-greek-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/manrope/files/manrope-greek-400-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* latin */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-latin-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/manrope/files/manrope-latin-400-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-latin-ext-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/manrope/files/manrope-latin-ext-400-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-vietnamese-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/manrope/files/manrope-vietnamese-400-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-cyrillic-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/manrope/files/manrope-cyrillic-500-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-cyrillic-ext-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/manrope/files/manrope-cyrillic-ext-500-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-greek-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/manrope/files/manrope-greek-500-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* latin */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-latin-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/manrope/files/manrope-latin-500-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-latin-ext-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/manrope/files/manrope-latin-ext-500-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-vietnamese-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/manrope/files/manrope-vietnamese-500-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-cyrillic-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/manrope/files/manrope-cyrillic-600-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-cyrillic-ext-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/manrope/files/manrope-cyrillic-ext-600-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-greek-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/manrope/files/manrope-greek-600-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* latin */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-latin-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/manrope/files/manrope-latin-600-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-latin-ext-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/manrope/files/manrope-latin-ext-600-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-vietnamese-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/manrope/files/manrope-vietnamese-600-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-cyrillic-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/manrope/files/manrope-cyrillic-700-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-cyrillic-ext-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/manrope/files/manrope-cyrillic-ext-700-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-greek-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/manrope/files/manrope-greek-700-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* latin */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-latin-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/manrope/files/manrope-latin-700-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-latin-ext-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/manrope/files/manrope-latin-ext-700-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-vietnamese-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/manrope/files/manrope-vietnamese-700-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-cyrillic-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/manrope/files/manrope-cyrillic-800-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-cyrillic-ext-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/manrope/files/manrope-cyrillic-ext-800-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-greek-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/manrope/files/manrope-greek-800-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* latin */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-latin-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/manrope/files/manrope-latin-800-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-latin-ext-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/manrope/files/manrope-latin-ext-800-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/manrope/files/manrope-vietnamese-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/manrope/files/manrope-vietnamese-800-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-cyrillic-ext-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-cyrillic-ext-300-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-latin-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-latin-300-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-latin-ext-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-latin-ext-300-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-vietnamese-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-vietnamese-300-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-cyrillic-ext-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-cyrillic-ext-400-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-latin-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-latin-400-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-latin-ext-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-latin-ext-400-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-vietnamese-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-vietnamese-400-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-cyrillic-ext-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-cyrillic-ext-500-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-latin-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-latin-500-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-latin-ext-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-latin-ext-500-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-vietnamese-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-vietnamese-500-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-cyrillic-ext-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-cyrillic-ext-600-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-latin-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-latin-600-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-latin-ext-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-latin-ext-600-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-vietnamese-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-vietnamese-600-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-cyrillic-ext-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-cyrillic-ext-700-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-latin-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-latin-700-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-latin-ext-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-latin-ext-700-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-vietnamese-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-vietnamese-700-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-cyrillic-ext-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-cyrillic-ext-800-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-latin-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-latin-800-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-latin-ext-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-latin-ext-800-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-vietnamese-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/merriweather-sans/files/merriweather-sans-vietnamese-800-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}
/* cyrillic */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/playfair-display/files/playfair-display-cyrillic-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/playfair-display/files/playfair-display-cyrillic-400-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/playfair-display/files/playfair-display-latin-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/playfair-display/files/playfair-display-latin-400-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/playfair-display/files/playfair-display-latin-ext-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/playfair-display/files/playfair-display-latin-ext-400-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/playfair-display/files/playfair-display-vietnamese-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/playfair-display/files/playfair-display-vietnamese-400-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/playfair-display/files/playfair-display-cyrillic-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/playfair-display/files/playfair-display-cyrillic-500-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/playfair-display/files/playfair-display-latin-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/playfair-display/files/playfair-display-latin-500-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/playfair-display/files/playfair-display-latin-ext-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/playfair-display/files/playfair-display-latin-ext-500-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/playfair-display/files/playfair-display-vietnamese-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/playfair-display/files/playfair-display-vietnamese-500-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/playfair-display/files/playfair-display-cyrillic-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/playfair-display/files/playfair-display-cyrillic-600-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/playfair-display/files/playfair-display-latin-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/playfair-display/files/playfair-display-latin-600-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/playfair-display/files/playfair-display-latin-ext-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/playfair-display/files/playfair-display-latin-ext-600-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/playfair-display/files/playfair-display-vietnamese-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/playfair-display/files/playfair-display-vietnamese-600-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/playfair-display/files/playfair-display-cyrillic-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/playfair-display/files/playfair-display-cyrillic-700-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/playfair-display/files/playfair-display-latin-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/playfair-display/files/playfair-display-latin-700-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/playfair-display/files/playfair-display-latin-ext-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/playfair-display/files/playfair-display-latin-ext-700-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/playfair-display/files/playfair-display-vietnamese-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/playfair-display/files/playfair-display-vietnamese-700-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/playfair-display/files/playfair-display-cyrillic-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/playfair-display/files/playfair-display-cyrillic-800-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/playfair-display/files/playfair-display-latin-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/playfair-display/files/playfair-display-latin-800-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/playfair-display/files/playfair-display-latin-ext-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/playfair-display/files/playfair-display-latin-ext-800-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/playfair-display/files/playfair-display-vietnamese-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/playfair-display/files/playfair-display-vietnamese-800-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/playfair-display/files/playfair-display-cyrillic-900-normal.woff2) format('woff2'), url(https://fonts.bunny.net/playfair-display/files/playfair-display-cyrillic-900-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/playfair-display/files/playfair-display-latin-900-normal.woff2) format('woff2'), url(https://fonts.bunny.net/playfair-display/files/playfair-display-latin-900-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/playfair-display/files/playfair-display-latin-ext-900-normal.woff2) format('woff2'), url(https://fonts.bunny.net/playfair-display/files/playfair-display-latin-ext-900-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/playfair-display/files/playfair-display-vietnamese-900-normal.woff2) format('woff2'), url(https://fonts.bunny.net/playfair-display/files/playfair-display-vietnamese-900-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-cyrillic-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-cyrillic-300-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-cyrillic-ext-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-cyrillic-ext-300-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-greek-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-greek-300-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* greek-ext */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-greek-ext-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-greek-ext-300-normal.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-latin-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-latin-300-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-latin-ext-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-latin-ext-300-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-vietnamese-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-vietnamese-300-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-cyrillic-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-cyrillic-400-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-cyrillic-ext-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-cyrillic-ext-400-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-greek-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-greek-400-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* greek-ext */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-greek-ext-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-greek-ext-400-normal.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-latin-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-latin-400-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-latin-ext-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-latin-ext-400-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-vietnamese-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-vietnamese-400-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-cyrillic-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-cyrillic-500-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-cyrillic-ext-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-cyrillic-ext-500-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-greek-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-greek-500-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* greek-ext */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-greek-ext-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-greek-ext-500-normal.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-latin-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-latin-500-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-latin-ext-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-latin-ext-500-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-vietnamese-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-vietnamese-500-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-cyrillic-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-cyrillic-600-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-cyrillic-ext-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-cyrillic-ext-600-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-greek-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-greek-600-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* greek-ext */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-greek-ext-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-greek-ext-600-normal.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-latin-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-latin-600-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-latin-ext-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-latin-ext-600-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-vietnamese-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-vietnamese-600-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-cyrillic-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-cyrillic-700-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-cyrillic-ext-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-cyrillic-ext-700-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-greek-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-greek-700-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* greek-ext */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-greek-ext-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-greek-ext-700-normal.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-latin-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-latin-700-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-latin-ext-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-latin-ext-700-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-vietnamese-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-vietnamese-700-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-cyrillic-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-cyrillic-800-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-cyrillic-ext-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-cyrillic-ext-800-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-greek-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-greek-800-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* greek-ext */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-greek-ext-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-greek-ext-800-normal.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-latin-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-latin-800-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-latin-ext-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-latin-ext-800-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-vietnamese-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/source-sans-3/files/source-sans-3-vietnamese-800-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/archivo/files/archivo-latin-200-normal.woff2) format('woff2'), url(https://fonts.bunny.net/archivo/files/archivo-latin-200-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/archivo/files/archivo-latin-ext-200-normal.woff2) format('woff2'), url(https://fonts.bunny.net/archivo/files/archivo-latin-ext-200-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/archivo/files/archivo-vietnamese-200-normal.woff2) format('woff2'), url(https://fonts.bunny.net/archivo/files/archivo-vietnamese-200-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/archivo/files/archivo-latin-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/archivo/files/archivo-latin-300-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/archivo/files/archivo-latin-ext-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/archivo/files/archivo-latin-ext-300-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/archivo/files/archivo-vietnamese-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/archivo/files/archivo-vietnamese-300-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/archivo/files/archivo-latin-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/archivo/files/archivo-latin-400-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/archivo/files/archivo-latin-ext-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/archivo/files/archivo-latin-ext-400-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/archivo/files/archivo-vietnamese-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/archivo/files/archivo-vietnamese-400-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/archivo/files/archivo-latin-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/archivo/files/archivo-latin-500-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/archivo/files/archivo-latin-ext-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/archivo/files/archivo-latin-ext-500-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/archivo/files/archivo-vietnamese-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/archivo/files/archivo-vietnamese-500-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/archivo/files/archivo-latin-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/archivo/files/archivo-latin-600-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/archivo/files/archivo-latin-ext-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/archivo/files/archivo-latin-ext-600-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/archivo/files/archivo-vietnamese-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/archivo/files/archivo-vietnamese-600-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/archivo/files/archivo-latin-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/archivo/files/archivo-latin-700-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/archivo/files/archivo-latin-ext-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/archivo/files/archivo-latin-ext-700-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/archivo/files/archivo-vietnamese-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/archivo/files/archivo-vietnamese-700-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/asap/files/asap-latin-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/asap/files/asap-latin-300-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/asap/files/asap-latin-ext-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/asap/files/asap-latin-ext-300-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/asap/files/asap-vietnamese-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/asap/files/asap-vietnamese-300-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/asap/files/asap-latin-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/asap/files/asap-latin-400-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/asap/files/asap-latin-ext-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/asap/files/asap-latin-ext-400-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/asap/files/asap-vietnamese-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/asap/files/asap-vietnamese-400-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/asap/files/asap-latin-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/asap/files/asap-latin-500-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/asap/files/asap-latin-ext-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/asap/files/asap-latin-ext-500-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/asap/files/asap-vietnamese-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/asap/files/asap-vietnamese-500-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/asap/files/asap-latin-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/asap/files/asap-latin-600-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/asap/files/asap-latin-ext-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/asap/files/asap-latin-ext-600-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/asap/files/asap-vietnamese-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/asap/files/asap-vietnamese-600-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/asap/files/asap-latin-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/asap/files/asap-latin-700-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/asap/files/asap-latin-ext-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/asap/files/asap-latin-ext-700-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/asap/files/asap-vietnamese-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/asap/files/asap-vietnamese-700-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/asap/files/asap-latin-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/asap/files/asap-latin-800-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/asap/files/asap-latin-ext-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/asap/files/asap-latin-ext-800-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/asap/files/asap-vietnamese-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/asap/files/asap-vietnamese-800-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-cyrillic-200-normal.woff2) format('woff2'), url(https://fonts.bunny.net/montserrat/files/montserrat-cyrillic-200-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-cyrillic-ext-200-normal.woff2) format('woff2'), url(https://fonts.bunny.net/montserrat/files/montserrat-cyrillic-ext-200-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-latin-200-normal.woff2) format('woff2'), url(https://fonts.bunny.net/montserrat/files/montserrat-latin-200-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-latin-ext-200-normal.woff2) format('woff2'), url(https://fonts.bunny.net/montserrat/files/montserrat-latin-ext-200-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-vietnamese-200-normal.woff2) format('woff2'), url(https://fonts.bunny.net/montserrat/files/montserrat-vietnamese-200-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-cyrillic-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/montserrat/files/montserrat-cyrillic-300-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-cyrillic-ext-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/montserrat/files/montserrat-cyrillic-ext-300-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-latin-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/montserrat/files/montserrat-latin-300-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-latin-ext-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/montserrat/files/montserrat-latin-ext-300-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-vietnamese-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/montserrat/files/montserrat-vietnamese-300-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-cyrillic-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/montserrat/files/montserrat-cyrillic-400-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-cyrillic-ext-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/montserrat/files/montserrat-cyrillic-ext-400-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-latin-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/montserrat/files/montserrat-latin-400-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-latin-ext-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/montserrat/files/montserrat-latin-ext-400-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-vietnamese-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/montserrat/files/montserrat-vietnamese-400-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-cyrillic-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/montserrat/files/montserrat-cyrillic-500-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-cyrillic-ext-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/montserrat/files/montserrat-cyrillic-ext-500-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-latin-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/montserrat/files/montserrat-latin-500-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-latin-ext-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/montserrat/files/montserrat-latin-ext-500-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-vietnamese-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/montserrat/files/montserrat-vietnamese-500-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-cyrillic-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/montserrat/files/montserrat-cyrillic-600-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-cyrillic-ext-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/montserrat/files/montserrat-cyrillic-ext-600-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-latin-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/montserrat/files/montserrat-latin-600-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-latin-ext-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/montserrat/files/montserrat-latin-ext-600-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-vietnamese-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/montserrat/files/montserrat-vietnamese-600-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-cyrillic-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/montserrat/files/montserrat-cyrillic-700-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-cyrillic-ext-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/montserrat/files/montserrat-cyrillic-ext-700-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-latin-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/montserrat/files/montserrat-latin-700-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-latin-ext-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/montserrat/files/montserrat-latin-ext-700-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-vietnamese-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/montserrat/files/montserrat-vietnamese-700-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-cyrillic-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/montserrat/files/montserrat-cyrillic-800-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-cyrillic-ext-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/montserrat/files/montserrat-cyrillic-ext-800-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-latin-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/montserrat/files/montserrat-latin-800-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-latin-ext-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/montserrat/files/montserrat-latin-ext-800-normal.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/montserrat/files/montserrat-vietnamese-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/montserrat/files/montserrat-vietnamese-800-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}

/* New fonts */
/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 100;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-100-italic.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-latin-100-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 100;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-100-italic.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-100-italic.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 100;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-100-italic.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-100-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 100;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-100-italic.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-100-italic.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 100;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-100-italic.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-100-italic.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-200-italic.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-latin-200-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-200-italic.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-200-italic.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-200-italic.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-200-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-200-italic.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-200-italic.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-200-italic.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-200-italic.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-300-italic.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-latin-300-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-300-italic.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-300-italic.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-300-italic.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-300-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-300-italic.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-300-italic.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-300-italic.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-300-italic.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-400-italic.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-latin-400-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-400-italic.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-400-italic.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-400-italic.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-400-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-400-italic.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-400-italic.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-400-italic.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-400-italic.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-500-italic.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-latin-500-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-500-italic.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-500-italic.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-500-italic.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-500-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-500-italic.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-500-italic.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-500-italic.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-500-italic.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-600-italic.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-latin-600-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-600-italic.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-600-italic.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-600-italic.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-600-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-600-italic.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-600-italic.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-600-italic.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-600-italic.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-700-italic.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-latin-700-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-700-italic.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-700-italic.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-700-italic.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-700-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-700-italic.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-700-italic.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-700-italic.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-700-italic.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-800-italic.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-latin-800-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-800-italic.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-800-italic.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-800-italic.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-800-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-800-italic.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-800-italic.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-800-italic.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-800-italic.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-900-italic.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-latin-900-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-900-italic.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-900-italic.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-900-italic.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-900-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-900-italic.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-900-italic.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-900-italic.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-900-italic.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 100;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-100-normal.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-latin-100-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 100;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-100-normal.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-100-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 100;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-100-normal.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-100-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 100;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-100-normal.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-100-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 100;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-100-normal.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-100-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-200-normal.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-latin-200-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-200-normal.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-200-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-200-normal.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-200-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-200-normal.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-200-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-200-normal.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-200-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-latin-300-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-300-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-300-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-300-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-300-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-latin-400-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-400-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-400-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-400-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-400-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-latin-500-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-500-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-500-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-500-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-500-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-latin-600-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-600-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-600-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-600-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-600-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-latin-700-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-700-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-700-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-700-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-700-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-latin-800-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-800-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-800-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-800-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-800-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-900-normal.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-latin-900-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-900-normal.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-900-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-900-normal.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-latin-ext-900-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-900-normal.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-vietnamese-900-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-900-normal.woff2) format('woff2'), url(https://fonts.bunny.net/raleway/files/raleway-cyrillic-ext-900-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-latin-300-italic.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-latin-300-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-hebrew-300-italic.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-hebrew-300-italic.woff) format('woff');
  unicode-range: U+0590-05FF,U+200C-2010,U+20AA,U+25CC,U+FB1D-FB4F;
}

/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-300-italic.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-300-italic.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-latin-ext-300-italic.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-latin-ext-300-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-ext-300-italic.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-ext-300-italic.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-latin-400-italic.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-latin-400-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-hebrew-400-italic.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-hebrew-400-italic.woff) format('woff');
  unicode-range: U+0590-05FF,U+200C-2010,U+20AA,U+25CC,U+FB1D-FB4F;
}

/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-400-italic.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-400-italic.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-latin-ext-400-italic.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-latin-ext-400-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-ext-400-italic.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-ext-400-italic.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-latin-500-italic.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-latin-500-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-hebrew-500-italic.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-hebrew-500-italic.woff) format('woff');
  unicode-range: U+0590-05FF,U+200C-2010,U+20AA,U+25CC,U+FB1D-FB4F;
}

/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-500-italic.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-500-italic.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-latin-ext-500-italic.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-latin-ext-500-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-ext-500-italic.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-ext-500-italic.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-latin-600-italic.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-latin-600-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-hebrew-600-italic.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-hebrew-600-italic.woff) format('woff');
  unicode-range: U+0590-05FF,U+200C-2010,U+20AA,U+25CC,U+FB1D-FB4F;
}

/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-600-italic.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-600-italic.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-latin-ext-600-italic.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-latin-ext-600-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-ext-600-italic.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-ext-600-italic.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-latin-700-italic.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-latin-700-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-hebrew-700-italic.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-hebrew-700-italic.woff) format('woff');
  unicode-range: U+0590-05FF,U+200C-2010,U+20AA,U+25CC,U+FB1D-FB4F;
}

/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-700-italic.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-700-italic.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-latin-ext-700-italic.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-latin-ext-700-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-ext-700-italic.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-ext-700-italic.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-latin-800-italic.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-latin-800-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-hebrew-800-italic.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-hebrew-800-italic.woff) format('woff');
  unicode-range: U+0590-05FF,U+200C-2010,U+20AA,U+25CC,U+FB1D-FB4F;
}

/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-800-italic.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-800-italic.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-latin-ext-800-italic.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-latin-ext-800-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-ext-800-italic.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-ext-800-italic.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-latin-900-italic.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-latin-900-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-hebrew-900-italic.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-hebrew-900-italic.woff) format('woff');
  unicode-range: U+0590-05FF,U+200C-2010,U+20AA,U+25CC,U+FB1D-FB4F;
}

/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-900-italic.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-900-italic.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-latin-ext-900-italic.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-latin-ext-900-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-ext-900-italic.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-ext-900-italic.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-latin-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-latin-300-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-hebrew-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-hebrew-300-normal.woff) format('woff');
  unicode-range: U+0590-05FF,U+200C-2010,U+20AA,U+25CC,U+FB1D-FB4F;
}

/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-300-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-latin-ext-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-latin-ext-300-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-ext-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-ext-300-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-latin-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-latin-400-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-hebrew-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-hebrew-400-normal.woff) format('woff');
  unicode-range: U+0590-05FF,U+200C-2010,U+20AA,U+25CC,U+FB1D-FB4F;
}

/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-400-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-latin-ext-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-latin-ext-400-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-ext-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-ext-400-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-latin-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-latin-500-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-hebrew-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-hebrew-500-normal.woff) format('woff');
  unicode-range: U+0590-05FF,U+200C-2010,U+20AA,U+25CC,U+FB1D-FB4F;
}

/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-500-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-latin-ext-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-latin-ext-500-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-ext-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-ext-500-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-latin-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-latin-600-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-hebrew-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-hebrew-600-normal.woff) format('woff');
  unicode-range: U+0590-05FF,U+200C-2010,U+20AA,U+25CC,U+FB1D-FB4F;
}

/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-600-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-latin-ext-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-latin-ext-600-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-ext-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-ext-600-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-latin-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-latin-700-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-hebrew-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-hebrew-700-normal.woff) format('woff');
  unicode-range: U+0590-05FF,U+200C-2010,U+20AA,U+25CC,U+FB1D-FB4F;
}

/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-700-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-latin-ext-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-latin-ext-700-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-ext-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-ext-700-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-latin-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-latin-800-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-hebrew-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-hebrew-800-normal.woff) format('woff');
  unicode-range: U+0590-05FF,U+200C-2010,U+20AA,U+25CC,U+FB1D-FB4F;
}

/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-800-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-latin-ext-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-latin-ext-800-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-ext-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-ext-800-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-latin-900-normal.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-latin-900-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* hebrew */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-hebrew-900-normal.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-hebrew-900-normal.woff) format('woff');
  unicode-range: U+0590-05FF,U+200C-2010,U+20AA,U+25CC,U+FB1D-FB4F;
}

/* cyrillic */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-900-normal.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-900-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-latin-ext-900-normal.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-latin-ext-900-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-ext-900-normal.woff2) format('woff2'), url(https://fonts.bunny.net/rubik/files/rubik-cyrillic-ext-900-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Epilogue';
  font-style: italic;
  font-weight: 100;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-100-italic.woff2) format('woff2'), url(https://fonts.bunny.net/epilogue/files/epilogue-latin-100-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Epilogue';
  font-style: italic;
  font-weight: 100;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-100-italic.woff2) format('woff2'), url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-100-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Epilogue';
  font-style: italic;
  font-weight: 100;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-100-italic.woff2) format('woff2'), url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-100-italic.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Epilogue';
  font-style: italic;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-200-italic.woff2) format('woff2'), url(https://fonts.bunny.net/epilogue/files/epilogue-latin-200-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Epilogue';
  font-style: italic;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-200-italic.woff2) format('woff2'), url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-200-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Epilogue';
  font-style: italic;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-200-italic.woff2) format('woff2'), url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-200-italic.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Epilogue';
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-300-italic.woff2) format('woff2'), url(https://fonts.bunny.net/epilogue/files/epilogue-latin-300-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Epilogue';
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-300-italic.woff2) format('woff2'), url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-300-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Epilogue';
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-300-italic.woff2) format('woff2'), url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-300-italic.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Epilogue';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-400-italic.woff2) format('woff2'), url(https://fonts.bunny.net/epilogue/files/epilogue-latin-400-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Epilogue';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-400-italic.woff2) format('woff2'), url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-400-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Epilogue';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-400-italic.woff2) format('woff2'), url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-400-italic.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Epilogue';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-500-italic.woff2) format('woff2'), url(https://fonts.bunny.net/epilogue/files/epilogue-latin-500-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Epilogue';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-500-italic.woff2) format('woff2'), url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-500-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Epilogue';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-500-italic.woff2) format('woff2'), url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-500-italic.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Epilogue';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-600-italic.woff2) format('woff2'), url(https://fonts.bunny.net/epilogue/files/epilogue-latin-600-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Epilogue';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-600-italic.woff2) format('woff2'), url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-600-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Epilogue';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-600-italic.woff2) format('woff2'), url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-600-italic.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Epilogue';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-700-italic.woff2) format('woff2'), url(https://fonts.bunny.net/epilogue/files/epilogue-latin-700-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Epilogue';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-700-italic.woff2) format('woff2'), url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-700-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Epilogue';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-700-italic.woff2) format('woff2'), url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-700-italic.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Epilogue';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-800-italic.woff2) format('woff2'), url(https://fonts.bunny.net/epilogue/files/epilogue-latin-800-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Epilogue';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-800-italic.woff2) format('woff2'), url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-800-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Epilogue';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-800-italic.woff2) format('woff2'), url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-800-italic.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Epilogue';
  font-style: italic;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-900-italic.woff2) format('woff2'), url(https://fonts.bunny.net/epilogue/files/epilogue-latin-900-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Epilogue';
  font-style: italic;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-900-italic.woff2) format('woff2'), url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-900-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Epilogue';
  font-style: italic;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-900-italic.woff2) format('woff2'), url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-900-italic.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 100;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-100-normal.woff2) format('woff2'), url(https://fonts.bunny.net/epilogue/files/epilogue-latin-100-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 100;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-100-normal.woff2) format('woff2'), url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-100-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 100;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-100-normal.woff2) format('woff2'), url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-100-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-200-normal.woff2) format('woff2'), url(https://fonts.bunny.net/epilogue/files/epilogue-latin-200-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-200-normal.woff2) format('woff2'), url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-200-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-200-normal.woff2) format('woff2'), url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-200-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/epilogue/files/epilogue-latin-300-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-300-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-300-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/epilogue/files/epilogue-latin-400-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-400-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-400-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/epilogue/files/epilogue-latin-500-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-500-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-500-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/epilogue/files/epilogue-latin-600-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-600-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-600-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/epilogue/files/epilogue-latin-700-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-700-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-700-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/epilogue/files/epilogue-latin-800-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-800-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-800-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-900-normal.woff2) format('woff2'), url(https://fonts.bunny.net/epilogue/files/epilogue-latin-900-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-900-normal.woff2) format('woff2'), url(https://fonts.bunny.net/epilogue/files/epilogue-latin-ext-900-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-900-normal.woff2) format('woff2'), url(https://fonts.bunny.net/epilogue/files/epilogue-vietnamese-900-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/oswald/files/oswald-latin-200-normal.woff2) format('woff2'), url(https://fonts.bunny.net/oswald/files/oswald-latin-200-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/oswald/files/oswald-cyrillic-200-normal.woff2) format('woff2'), url(https://fonts.bunny.net/oswald/files/oswald-cyrillic-200-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/oswald/files/oswald-latin-ext-200-normal.woff2) format('woff2'), url(https://fonts.bunny.net/oswald/files/oswald-latin-ext-200-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/oswald/files/oswald-vietnamese-200-normal.woff2) format('woff2'), url(https://fonts.bunny.net/oswald/files/oswald-vietnamese-200-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/oswald/files/oswald-cyrillic-ext-200-normal.woff2) format('woff2'), url(https://fonts.bunny.net/oswald/files/oswald-cyrillic-ext-200-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/oswald/files/oswald-latin-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/oswald/files/oswald-latin-300-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/oswald/files/oswald-cyrillic-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/oswald/files/oswald-cyrillic-300-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/oswald/files/oswald-latin-ext-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/oswald/files/oswald-latin-ext-300-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/oswald/files/oswald-vietnamese-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/oswald/files/oswald-vietnamese-300-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/oswald/files/oswald-cyrillic-ext-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/oswald/files/oswald-cyrillic-ext-300-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/oswald/files/oswald-latin-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/oswald/files/oswald-latin-400-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/oswald/files/oswald-cyrillic-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/oswald/files/oswald-cyrillic-400-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/oswald/files/oswald-latin-ext-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/oswald/files/oswald-latin-ext-400-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/oswald/files/oswald-vietnamese-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/oswald/files/oswald-vietnamese-400-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/oswald/files/oswald-cyrillic-ext-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/oswald/files/oswald-cyrillic-ext-400-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/oswald/files/oswald-latin-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/oswald/files/oswald-latin-500-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/oswald/files/oswald-cyrillic-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/oswald/files/oswald-cyrillic-500-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/oswald/files/oswald-latin-ext-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/oswald/files/oswald-latin-ext-500-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/oswald/files/oswald-vietnamese-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/oswald/files/oswald-vietnamese-500-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/oswald/files/oswald-cyrillic-ext-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/oswald/files/oswald-cyrillic-ext-500-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/oswald/files/oswald-latin-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/oswald/files/oswald-latin-600-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/oswald/files/oswald-cyrillic-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/oswald/files/oswald-cyrillic-600-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/oswald/files/oswald-latin-ext-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/oswald/files/oswald-latin-ext-600-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/oswald/files/oswald-vietnamese-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/oswald/files/oswald-vietnamese-600-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/oswald/files/oswald-cyrillic-ext-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/oswald/files/oswald-cyrillic-ext-600-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/oswald/files/oswald-latin-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/oswald/files/oswald-latin-700-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/oswald/files/oswald-cyrillic-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/oswald/files/oswald-cyrillic-700-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/oswald/files/oswald-latin-ext-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/oswald/files/oswald-latin-ext-700-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/oswald/files/oswald-vietnamese-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/oswald/files/oswald-vietnamese-700-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/oswald/files/oswald-cyrillic-ext-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/oswald/files/oswald-cyrillic-ext-700-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-200-italic.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-latin-200-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-200-italic.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-200-italic.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-200-italic.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-200-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-200-italic.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-200-italic.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-200-italic.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-200-italic.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-300-italic.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-latin-300-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-300-italic.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-300-italic.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-300-italic.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-300-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-300-italic.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-300-italic.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-300-italic.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-300-italic.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-400-italic.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-latin-400-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-400-italic.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-400-italic.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-400-italic.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-400-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-400-italic.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-400-italic.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-400-italic.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-400-italic.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-500-italic.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-latin-500-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-500-italic.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-500-italic.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-500-italic.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-500-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-500-italic.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-500-italic.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-500-italic.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-500-italic.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-600-italic.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-latin-600-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-600-italic.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-600-italic.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-600-italic.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-600-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-600-italic.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-600-italic.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-600-italic.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-600-italic.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-700-italic.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-latin-700-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-700-italic.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-700-italic.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-700-italic.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-700-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-700-italic.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-700-italic.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-700-italic.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-700-italic.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-800-italic.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-latin-800-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-800-italic.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-800-italic.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-800-italic.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-800-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-800-italic.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-800-italic.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-800-italic.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-800-italic.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-900-italic.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-latin-900-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-900-italic.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-900-italic.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-900-italic.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-900-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-900-italic.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-900-italic.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-900-italic.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-900-italic.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-200-normal.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-latin-200-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-200-normal.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-200-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-200-normal.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-200-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-200-normal.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-200-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-200-normal.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-200-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-latin-300-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-300-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-300-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-300-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-300-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-latin-400-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-400-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-400-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-400-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-400-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-latin-500-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-500-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-500-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-500-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-500-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-latin-600-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-600-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-600-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-600-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-600-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-latin-700-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-700-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-700-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-700-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-700-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-latin-800-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-800-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-800-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-800-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-800-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-900-normal.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-latin-900-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-900-normal.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-900-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-900-normal.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-latin-ext-900-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-900-normal.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-vietnamese-900-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-900-normal.woff2) format('woff2'), url(https://fonts.bunny.net/nunito/files/nunito-cyrillic-ext-900-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 100;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-100-italic.woff2) format('woff2'), url(https://fonts.bunny.net/work-sans/files/work-sans-latin-100-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 100;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-100-italic.woff2) format('woff2'), url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-100-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 100;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-100-italic.woff2) format('woff2'), url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-100-italic.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-200-italic.woff2) format('woff2'), url(https://fonts.bunny.net/work-sans/files/work-sans-latin-200-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-200-italic.woff2) format('woff2'), url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-200-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-200-italic.woff2) format('woff2'), url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-200-italic.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-300-italic.woff2) format('woff2'), url(https://fonts.bunny.net/work-sans/files/work-sans-latin-300-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-300-italic.woff2) format('woff2'), url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-300-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-300-italic.woff2) format('woff2'), url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-300-italic.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-400-italic.woff2) format('woff2'), url(https://fonts.bunny.net/work-sans/files/work-sans-latin-400-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-400-italic.woff2) format('woff2'), url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-400-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-400-italic.woff2) format('woff2'), url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-400-italic.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-500-italic.woff2) format('woff2'), url(https://fonts.bunny.net/work-sans/files/work-sans-latin-500-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-500-italic.woff2) format('woff2'), url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-500-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-500-italic.woff2) format('woff2'), url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-500-italic.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-600-italic.woff2) format('woff2'), url(https://fonts.bunny.net/work-sans/files/work-sans-latin-600-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-600-italic.woff2) format('woff2'), url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-600-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-600-italic.woff2) format('woff2'), url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-600-italic.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-700-italic.woff2) format('woff2'), url(https://fonts.bunny.net/work-sans/files/work-sans-latin-700-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-700-italic.woff2) format('woff2'), url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-700-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-700-italic.woff2) format('woff2'), url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-700-italic.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-800-italic.woff2) format('woff2'), url(https://fonts.bunny.net/work-sans/files/work-sans-latin-800-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-800-italic.woff2) format('woff2'), url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-800-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-800-italic.woff2) format('woff2'), url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-800-italic.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-900-italic.woff2) format('woff2'), url(https://fonts.bunny.net/work-sans/files/work-sans-latin-900-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-900-italic.woff2) format('woff2'), url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-900-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-900-italic.woff2) format('woff2'), url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-900-italic.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 100;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-100-normal.woff2) format('woff2'), url(https://fonts.bunny.net/work-sans/files/work-sans-latin-100-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 100;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-100-normal.woff2) format('woff2'), url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-100-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 100;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-100-normal.woff2) format('woff2'), url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-100-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-200-normal.woff2) format('woff2'), url(https://fonts.bunny.net/work-sans/files/work-sans-latin-200-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-200-normal.woff2) format('woff2'), url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-200-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-200-normal.woff2) format('woff2'), url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-200-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/work-sans/files/work-sans-latin-300-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-300-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-300-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/work-sans/files/work-sans-latin-400-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-400-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-400-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/work-sans/files/work-sans-latin-500-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-500-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-500-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/work-sans/files/work-sans-latin-600-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-600-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-600-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/work-sans/files/work-sans-latin-700-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-700-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-700-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/work-sans/files/work-sans-latin-800-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-800-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-800-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-900-normal.woff2) format('woff2'), url(https://fonts.bunny.net/work-sans/files/work-sans-latin-900-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-900-normal.woff2) format('woff2'), url(https://fonts.bunny.net/work-sans/files/work-sans-latin-ext-900-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-900-normal.woff2) format('woff2'), url(https://fonts.bunny.net/work-sans/files/work-sans-vietnamese-900-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Cabin';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cabin/files/cabin-latin-400-italic.woff2) format('woff2'), url(https://fonts.bunny.net/cabin/files/cabin-latin-400-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Cabin';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cabin/files/cabin-latin-ext-400-italic.woff2) format('woff2'), url(https://fonts.bunny.net/cabin/files/cabin-latin-ext-400-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Cabin';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cabin/files/cabin-vietnamese-400-italic.woff2) format('woff2'), url(https://fonts.bunny.net/cabin/files/cabin-vietnamese-400-italic.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Cabin';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cabin/files/cabin-latin-500-italic.woff2) format('woff2'), url(https://fonts.bunny.net/cabin/files/cabin-latin-500-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Cabin';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cabin/files/cabin-latin-ext-500-italic.woff2) format('woff2'), url(https://fonts.bunny.net/cabin/files/cabin-latin-ext-500-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Cabin';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cabin/files/cabin-vietnamese-500-italic.woff2) format('woff2'), url(https://fonts.bunny.net/cabin/files/cabin-vietnamese-500-italic.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Cabin';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cabin/files/cabin-latin-600-italic.woff2) format('woff2'), url(https://fonts.bunny.net/cabin/files/cabin-latin-600-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Cabin';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cabin/files/cabin-latin-ext-600-italic.woff2) format('woff2'), url(https://fonts.bunny.net/cabin/files/cabin-latin-ext-600-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Cabin';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cabin/files/cabin-vietnamese-600-italic.woff2) format('woff2'), url(https://fonts.bunny.net/cabin/files/cabin-vietnamese-600-italic.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Cabin';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cabin/files/cabin-latin-700-italic.woff2) format('woff2'), url(https://fonts.bunny.net/cabin/files/cabin-latin-700-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Cabin';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cabin/files/cabin-latin-ext-700-italic.woff2) format('woff2'), url(https://fonts.bunny.net/cabin/files/cabin-latin-ext-700-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Cabin';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cabin/files/cabin-vietnamese-700-italic.woff2) format('woff2'), url(https://fonts.bunny.net/cabin/files/cabin-vietnamese-700-italic.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cabin/files/cabin-latin-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/cabin/files/cabin-latin-400-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cabin/files/cabin-latin-ext-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/cabin/files/cabin-latin-ext-400-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cabin/files/cabin-vietnamese-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/cabin/files/cabin-vietnamese-400-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cabin/files/cabin-latin-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/cabin/files/cabin-latin-500-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cabin/files/cabin-latin-ext-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/cabin/files/cabin-latin-ext-500-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cabin/files/cabin-vietnamese-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/cabin/files/cabin-vietnamese-500-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cabin/files/cabin-latin-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/cabin/files/cabin-latin-600-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cabin/files/cabin-latin-ext-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/cabin/files/cabin-latin-ext-600-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cabin/files/cabin-vietnamese-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/cabin/files/cabin-vietnamese-600-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cabin/files/cabin-latin-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/cabin/files/cabin-latin-700-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cabin/files/cabin-latin-ext-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/cabin/files/cabin-latin-ext-700-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/cabin/files/cabin-vietnamese-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/cabin/files/cabin-vietnamese-700-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* greek */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-greek-400-italic.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-greek-400-italic.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* latin */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-latin-400-italic.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-latin-400-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* hebrew */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-hebrew-400-italic.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-hebrew-400-italic.woff) format('woff');
  unicode-range: U+0590-05FF,U+200C-2010,U+20AA,U+25CC,U+FB1D-FB4F;
}

/* cyrillic */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-400-italic.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-400-italic.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-greek-ext-400-italic.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-greek-ext-400-italic.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin-ext */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-latin-ext-400-italic.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-latin-ext-400-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-vietnamese-400-italic.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-vietnamese-400-italic.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-ext-400-italic.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-ext-400-italic.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-greek-500-italic.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-greek-500-italic.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* latin */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-latin-500-italic.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-latin-500-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* hebrew */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-hebrew-500-italic.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-hebrew-500-italic.woff) format('woff');
  unicode-range: U+0590-05FF,U+200C-2010,U+20AA,U+25CC,U+FB1D-FB4F;
}

/* cyrillic */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-500-italic.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-500-italic.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-greek-ext-500-italic.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-greek-ext-500-italic.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin-ext */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-latin-ext-500-italic.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-latin-ext-500-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-vietnamese-500-italic.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-vietnamese-500-italic.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-ext-500-italic.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-ext-500-italic.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-greek-600-italic.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-greek-600-italic.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* latin */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-latin-600-italic.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-latin-600-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* hebrew */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-hebrew-600-italic.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-hebrew-600-italic.woff) format('woff');
  unicode-range: U+0590-05FF,U+200C-2010,U+20AA,U+25CC,U+FB1D-FB4F;
}

/* cyrillic */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-600-italic.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-600-italic.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-greek-ext-600-italic.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-greek-ext-600-italic.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin-ext */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-latin-ext-600-italic.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-latin-ext-600-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-vietnamese-600-italic.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-vietnamese-600-italic.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-ext-600-italic.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-ext-600-italic.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-greek-700-italic.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-greek-700-italic.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* latin */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-latin-700-italic.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-latin-700-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* hebrew */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-hebrew-700-italic.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-hebrew-700-italic.woff) format('woff');
  unicode-range: U+0590-05FF,U+200C-2010,U+20AA,U+25CC,U+FB1D-FB4F;
}

/* cyrillic */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-700-italic.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-700-italic.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-greek-ext-700-italic.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-greek-ext-700-italic.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin-ext */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-latin-ext-700-italic.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-latin-ext-700-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-vietnamese-700-italic.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-vietnamese-700-italic.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-ext-700-italic.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-ext-700-italic.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-greek-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-greek-400-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* latin */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-latin-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-latin-400-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* hebrew */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-hebrew-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-hebrew-400-normal.woff) format('woff');
  unicode-range: U+0590-05FF,U+200C-2010,U+20AA,U+25CC,U+FB1D-FB4F;
}

/* cyrillic */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-400-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-greek-ext-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-greek-ext-400-normal.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin-ext */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-latin-ext-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-latin-ext-400-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-vietnamese-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-vietnamese-400-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-ext-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-ext-400-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-greek-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-greek-500-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* latin */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-latin-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-latin-500-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* hebrew */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-hebrew-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-hebrew-500-normal.woff) format('woff');
  unicode-range: U+0590-05FF,U+200C-2010,U+20AA,U+25CC,U+FB1D-FB4F;
}

/* cyrillic */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-500-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-greek-ext-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-greek-ext-500-normal.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin-ext */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-latin-ext-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-latin-ext-500-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-vietnamese-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-vietnamese-500-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-ext-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-ext-500-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-greek-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-greek-600-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* latin */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-latin-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-latin-600-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* hebrew */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-hebrew-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-hebrew-600-normal.woff) format('woff');
  unicode-range: U+0590-05FF,U+200C-2010,U+20AA,U+25CC,U+FB1D-FB4F;
}

/* cyrillic */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-600-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-greek-ext-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-greek-ext-600-normal.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin-ext */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-latin-ext-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-latin-ext-600-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-vietnamese-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-vietnamese-600-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-ext-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-ext-600-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-greek-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-greek-700-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* latin */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-latin-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-latin-700-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* hebrew */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-hebrew-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-hebrew-700-normal.woff) format('woff');
  unicode-range: U+0590-05FF,U+200C-2010,U+20AA,U+25CC,U+FB1D-FB4F;
}

/* cyrillic */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-700-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-greek-ext-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-greek-ext-700-normal.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin-ext */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-latin-ext-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-latin-ext-700-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-vietnamese-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-vietnamese-700-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-ext-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/arimo/files/arimo-cyrillic-ext-700-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-400-italic.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-400-italic.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* latin */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-400-italic.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-400-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-400-italic.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-400-italic.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-ext-400-italic.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-ext-400-italic.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin-ext */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-ext-400-italic.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-ext-400-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-vietnamese-400-italic.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-vietnamese-400-italic.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-ext-400-italic.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-ext-400-italic.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-500-italic.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-500-italic.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* latin */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-500-italic.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-500-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-500-italic.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-500-italic.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-ext-500-italic.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-ext-500-italic.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin-ext */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-ext-500-italic.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-ext-500-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-vietnamese-500-italic.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-vietnamese-500-italic.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-ext-500-italic.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-ext-500-italic.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-600-italic.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-600-italic.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* latin */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-600-italic.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-600-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-600-italic.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-600-italic.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-ext-600-italic.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-ext-600-italic.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin-ext */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-ext-600-italic.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-ext-600-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-vietnamese-600-italic.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-vietnamese-600-italic.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-ext-600-italic.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-ext-600-italic.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-700-italic.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-700-italic.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* latin */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-700-italic.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-700-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-700-italic.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-700-italic.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-ext-700-italic.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-ext-700-italic.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin-ext */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-ext-700-italic.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-ext-700-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-vietnamese-700-italic.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-vietnamese-700-italic.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-ext-700-italic.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-ext-700-italic.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-800-italic.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-800-italic.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* latin */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-800-italic.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-800-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-800-italic.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-800-italic.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-ext-800-italic.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-ext-800-italic.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin-ext */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-ext-800-italic.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-ext-800-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-vietnamese-800-italic.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-vietnamese-800-italic.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'EB Garamond';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-ext-800-italic.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-ext-800-italic.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-400-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* latin */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-400-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-400-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-ext-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-ext-400-normal.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin-ext */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-ext-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-ext-400-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-vietnamese-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-vietnamese-400-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-ext-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-ext-400-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-500-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* latin */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-500-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-500-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-ext-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-ext-500-normal.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin-ext */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-ext-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-ext-500-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-vietnamese-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-vietnamese-500-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-ext-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-ext-500-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-600-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* latin */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-600-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-600-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-ext-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-ext-600-normal.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin-ext */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-ext-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-ext-600-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-vietnamese-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-vietnamese-600-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-ext-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-ext-600-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-700-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* latin */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-700-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-700-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-ext-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-ext-700-normal.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin-ext */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-ext-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-ext-700-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-vietnamese-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-vietnamese-700-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-ext-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-ext-700-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* greek */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-800-normal.woff) format('woff');
  unicode-range: U+0370-03FF;
}

/* latin */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-800-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-800-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-ext-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-greek-ext-800-normal.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}

/* latin-ext */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-ext-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-latin-ext-800-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-vietnamese-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-vietnamese-800-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-ext-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/eb-garamond/files/eb-garamond-cyrillic-ext-800-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Faustina';
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-latin-300-italic.woff2) format('woff2'), url(https://fonts.bunny.net/faustina/files/faustina-latin-300-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Faustina';
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-latin-ext-300-italic.woff2) format('woff2'), url(https://fonts.bunny.net/faustina/files/faustina-latin-ext-300-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Faustina';
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-vietnamese-300-italic.woff2) format('woff2'), url(https://fonts.bunny.net/faustina/files/faustina-vietnamese-300-italic.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Faustina';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-latin-400-italic.woff2) format('woff2'), url(https://fonts.bunny.net/faustina/files/faustina-latin-400-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Faustina';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-latin-ext-400-italic.woff2) format('woff2'), url(https://fonts.bunny.net/faustina/files/faustina-latin-ext-400-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Faustina';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-vietnamese-400-italic.woff2) format('woff2'), url(https://fonts.bunny.net/faustina/files/faustina-vietnamese-400-italic.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Faustina';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-latin-500-italic.woff2) format('woff2'), url(https://fonts.bunny.net/faustina/files/faustina-latin-500-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Faustina';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-latin-ext-500-italic.woff2) format('woff2'), url(https://fonts.bunny.net/faustina/files/faustina-latin-ext-500-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Faustina';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-vietnamese-500-italic.woff2) format('woff2'), url(https://fonts.bunny.net/faustina/files/faustina-vietnamese-500-italic.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Faustina';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-latin-600-italic.woff2) format('woff2'), url(https://fonts.bunny.net/faustina/files/faustina-latin-600-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Faustina';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-latin-ext-600-italic.woff2) format('woff2'), url(https://fonts.bunny.net/faustina/files/faustina-latin-ext-600-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Faustina';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-vietnamese-600-italic.woff2) format('woff2'), url(https://fonts.bunny.net/faustina/files/faustina-vietnamese-600-italic.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Faustina';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-latin-700-italic.woff2) format('woff2'), url(https://fonts.bunny.net/faustina/files/faustina-latin-700-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Faustina';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-latin-ext-700-italic.woff2) format('woff2'), url(https://fonts.bunny.net/faustina/files/faustina-latin-ext-700-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Faustina';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-vietnamese-700-italic.woff2) format('woff2'), url(https://fonts.bunny.net/faustina/files/faustina-vietnamese-700-italic.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Faustina';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-latin-800-italic.woff2) format('woff2'), url(https://fonts.bunny.net/faustina/files/faustina-latin-800-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Faustina';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-latin-ext-800-italic.woff2) format('woff2'), url(https://fonts.bunny.net/faustina/files/faustina-latin-ext-800-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Faustina';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-vietnamese-800-italic.woff2) format('woff2'), url(https://fonts.bunny.net/faustina/files/faustina-vietnamese-800-italic.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Faustina';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-latin-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/faustina/files/faustina-latin-300-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Faustina';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-latin-ext-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/faustina/files/faustina-latin-ext-300-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Faustina';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-vietnamese-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/faustina/files/faustina-vietnamese-300-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Faustina';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-latin-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/faustina/files/faustina-latin-400-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Faustina';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-latin-ext-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/faustina/files/faustina-latin-ext-400-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Faustina';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-vietnamese-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/faustina/files/faustina-vietnamese-400-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Faustina';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-latin-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/faustina/files/faustina-latin-500-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Faustina';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-latin-ext-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/faustina/files/faustina-latin-ext-500-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Faustina';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-vietnamese-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/faustina/files/faustina-vietnamese-500-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Faustina';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-latin-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/faustina/files/faustina-latin-600-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Faustina';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-latin-ext-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/faustina/files/faustina-latin-ext-600-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Faustina';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-vietnamese-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/faustina/files/faustina-vietnamese-600-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Faustina';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-latin-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/faustina/files/faustina-latin-700-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Faustina';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-latin-ext-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/faustina/files/faustina-latin-ext-700-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Faustina';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-vietnamese-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/faustina/files/faustina-vietnamese-700-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Faustina';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-latin-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/faustina/files/faustina-latin-800-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Faustina';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-latin-ext-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/faustina/files/faustina-latin-ext-800-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Faustina';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/faustina/files/faustina-vietnamese-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/faustina/files/faustina-vietnamese-800-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* latin */
@font-face {
  font-family: 'Karla';
  font-style: italic;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/karla/files/karla-latin-200-italic.woff2) format('woff2'), url(https://fonts.bunny.net/karla/files/karla-latin-200-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Karla';
  font-style: italic;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/karla/files/karla-latin-ext-200-italic.woff2) format('woff2'), url(https://fonts.bunny.net/karla/files/karla-latin-ext-200-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Karla';
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/karla/files/karla-latin-300-italic.woff2) format('woff2'), url(https://fonts.bunny.net/karla/files/karla-latin-300-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Karla';
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/karla/files/karla-latin-ext-300-italic.woff2) format('woff2'), url(https://fonts.bunny.net/karla/files/karla-latin-ext-300-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Karla';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/karla/files/karla-latin-400-italic.woff2) format('woff2'), url(https://fonts.bunny.net/karla/files/karla-latin-400-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Karla';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/karla/files/karla-latin-ext-400-italic.woff2) format('woff2'), url(https://fonts.bunny.net/karla/files/karla-latin-ext-400-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Karla';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/karla/files/karla-latin-500-italic.woff2) format('woff2'), url(https://fonts.bunny.net/karla/files/karla-latin-500-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Karla';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/karla/files/karla-latin-ext-500-italic.woff2) format('woff2'), url(https://fonts.bunny.net/karla/files/karla-latin-ext-500-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Karla';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/karla/files/karla-latin-600-italic.woff2) format('woff2'), url(https://fonts.bunny.net/karla/files/karla-latin-600-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Karla';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/karla/files/karla-latin-ext-600-italic.woff2) format('woff2'), url(https://fonts.bunny.net/karla/files/karla-latin-ext-600-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Karla';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/karla/files/karla-latin-700-italic.woff2) format('woff2'), url(https://fonts.bunny.net/karla/files/karla-latin-700-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Karla';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/karla/files/karla-latin-ext-700-italic.woff2) format('woff2'), url(https://fonts.bunny.net/karla/files/karla-latin-ext-700-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Karla';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/karla/files/karla-latin-800-italic.woff2) format('woff2'), url(https://fonts.bunny.net/karla/files/karla-latin-800-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Karla';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/karla/files/karla-latin-ext-800-italic.woff2) format('woff2'), url(https://fonts.bunny.net/karla/files/karla-latin-ext-800-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/karla/files/karla-latin-200-normal.woff2) format('woff2'), url(https://fonts.bunny.net/karla/files/karla-latin-200-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/karla/files/karla-latin-ext-200-normal.woff2) format('woff2'), url(https://fonts.bunny.net/karla/files/karla-latin-ext-200-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/karla/files/karla-latin-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/karla/files/karla-latin-300-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/karla/files/karla-latin-ext-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/karla/files/karla-latin-ext-300-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/karla/files/karla-latin-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/karla/files/karla-latin-400-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/karla/files/karla-latin-ext-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/karla/files/karla-latin-ext-400-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/karla/files/karla-latin-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/karla/files/karla-latin-500-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/karla/files/karla-latin-ext-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/karla/files/karla-latin-ext-500-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/karla/files/karla-latin-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/karla/files/karla-latin-600-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/karla/files/karla-latin-ext-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/karla/files/karla-latin-ext-600-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/karla/files/karla-latin-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/karla/files/karla-latin-700-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/karla/files/karla-latin-ext-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/karla/files/karla-latin-ext-700-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/karla/files/karla-latin-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/karla/files/karla-latin-800-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/karla/files/karla-latin-ext-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/karla/files/karla-latin-ext-800-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-latin-400-italic.woff2) format('woff2'), url(https://fonts.bunny.net/lora/files/lora-latin-400-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-cyrillic-400-italic.woff2) format('woff2'), url(https://fonts.bunny.net/lora/files/lora-cyrillic-400-italic.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-latin-ext-400-italic.woff2) format('woff2'), url(https://fonts.bunny.net/lora/files/lora-latin-ext-400-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-vietnamese-400-italic.woff2) format('woff2'), url(https://fonts.bunny.net/lora/files/lora-vietnamese-400-italic.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-cyrillic-ext-400-italic.woff2) format('woff2'), url(https://fonts.bunny.net/lora/files/lora-cyrillic-ext-400-italic.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-latin-500-italic.woff2) format('woff2'), url(https://fonts.bunny.net/lora/files/lora-latin-500-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-cyrillic-500-italic.woff2) format('woff2'), url(https://fonts.bunny.net/lora/files/lora-cyrillic-500-italic.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-latin-ext-500-italic.woff2) format('woff2'), url(https://fonts.bunny.net/lora/files/lora-latin-ext-500-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-vietnamese-500-italic.woff2) format('woff2'), url(https://fonts.bunny.net/lora/files/lora-vietnamese-500-italic.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-cyrillic-ext-500-italic.woff2) format('woff2'), url(https://fonts.bunny.net/lora/files/lora-cyrillic-ext-500-italic.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-latin-600-italic.woff2) format('woff2'), url(https://fonts.bunny.net/lora/files/lora-latin-600-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-cyrillic-600-italic.woff2) format('woff2'), url(https://fonts.bunny.net/lora/files/lora-cyrillic-600-italic.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-latin-ext-600-italic.woff2) format('woff2'), url(https://fonts.bunny.net/lora/files/lora-latin-ext-600-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-vietnamese-600-italic.woff2) format('woff2'), url(https://fonts.bunny.net/lora/files/lora-vietnamese-600-italic.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-cyrillic-ext-600-italic.woff2) format('woff2'), url(https://fonts.bunny.net/lora/files/lora-cyrillic-ext-600-italic.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-latin-700-italic.woff2) format('woff2'), url(https://fonts.bunny.net/lora/files/lora-latin-700-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-cyrillic-700-italic.woff2) format('woff2'), url(https://fonts.bunny.net/lora/files/lora-cyrillic-700-italic.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-latin-ext-700-italic.woff2) format('woff2'), url(https://fonts.bunny.net/lora/files/lora-latin-ext-700-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-vietnamese-700-italic.woff2) format('woff2'), url(https://fonts.bunny.net/lora/files/lora-vietnamese-700-italic.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-cyrillic-ext-700-italic.woff2) format('woff2'), url(https://fonts.bunny.net/lora/files/lora-cyrillic-ext-700-italic.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-latin-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/lora/files/lora-latin-400-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-cyrillic-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/lora/files/lora-cyrillic-400-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-latin-ext-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/lora/files/lora-latin-ext-400-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-vietnamese-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/lora/files/lora-vietnamese-400-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-cyrillic-ext-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/lora/files/lora-cyrillic-ext-400-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-latin-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/lora/files/lora-latin-500-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-cyrillic-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/lora/files/lora-cyrillic-500-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-latin-ext-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/lora/files/lora-latin-ext-500-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-vietnamese-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/lora/files/lora-vietnamese-500-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-cyrillic-ext-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/lora/files/lora-cyrillic-ext-500-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-latin-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/lora/files/lora-latin-600-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-cyrillic-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/lora/files/lora-cyrillic-600-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-latin-ext-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/lora/files/lora-latin-ext-600-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-vietnamese-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/lora/files/lora-vietnamese-600-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-cyrillic-ext-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/lora/files/lora-cyrillic-ext-600-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-latin-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/lora/files/lora-latin-700-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* cyrillic */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-cyrillic-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/lora/files/lora-cyrillic-700-normal.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}

/* latin-ext */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-latin-ext-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/lora/files/lora-latin-ext-700-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* vietnamese */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-vietnamese-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/lora/files/lora-vietnamese-700-normal.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1EA0-1EF9,U+20AB;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/lora/files/lora-cyrillic-ext-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/lora/files/lora-cyrillic-ext-700-normal.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}

/* latin */
@font-face {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 100;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/sora/files/sora-latin-100-normal.woff2) format('woff2'), url(https://fonts.bunny.net/sora/files/sora-latin-100-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 100;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/sora/files/sora-latin-ext-100-normal.woff2) format('woff2'), url(https://fonts.bunny.net/sora/files/sora-latin-ext-100-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/sora/files/sora-latin-200-normal.woff2) format('woff2'), url(https://fonts.bunny.net/sora/files/sora-latin-200-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/sora/files/sora-latin-ext-200-normal.woff2) format('woff2'), url(https://fonts.bunny.net/sora/files/sora-latin-ext-200-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/sora/files/sora-latin-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/sora/files/sora-latin-300-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/sora/files/sora-latin-ext-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/sora/files/sora-latin-ext-300-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/sora/files/sora-latin-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/sora/files/sora-latin-400-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/sora/files/sora-latin-ext-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/sora/files/sora-latin-ext-400-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/sora/files/sora-latin-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/sora/files/sora-latin-500-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/sora/files/sora-latin-ext-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/sora/files/sora-latin-ext-500-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/sora/files/sora-latin-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/sora/files/sora-latin-600-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/sora/files/sora-latin-ext-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/sora/files/sora-latin-ext-600-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/sora/files/sora-latin-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/sora/files/sora-latin-700-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/sora/files/sora-latin-ext-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/sora/files/sora-latin-ext-700-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/sora/files/sora-latin-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/sora/files/sora-latin-800-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/sora/files/sora-latin-ext-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/sora/files/sora-latin-ext-800-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Urbanist';
  font-style: italic;
  font-weight: 100;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-100-italic.woff2) format('woff2'), url(https://fonts.bunny.net/urbanist/files/urbanist-latin-100-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Urbanist';
  font-style: italic;
  font-weight: 100;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-100-italic.woff2) format('woff2'), url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-100-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Urbanist';
  font-style: italic;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-200-italic.woff2) format('woff2'), url(https://fonts.bunny.net/urbanist/files/urbanist-latin-200-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Urbanist';
  font-style: italic;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-200-italic.woff2) format('woff2'), url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-200-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Urbanist';
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-300-italic.woff2) format('woff2'), url(https://fonts.bunny.net/urbanist/files/urbanist-latin-300-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Urbanist';
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-300-italic.woff2) format('woff2'), url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-300-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Urbanist';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-400-italic.woff2) format('woff2'), url(https://fonts.bunny.net/urbanist/files/urbanist-latin-400-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Urbanist';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-400-italic.woff2) format('woff2'), url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-400-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Urbanist';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-500-italic.woff2) format('woff2'), url(https://fonts.bunny.net/urbanist/files/urbanist-latin-500-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Urbanist';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-500-italic.woff2) format('woff2'), url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-500-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Urbanist';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-600-italic.woff2) format('woff2'), url(https://fonts.bunny.net/urbanist/files/urbanist-latin-600-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Urbanist';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-600-italic.woff2) format('woff2'), url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-600-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Urbanist';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-700-italic.woff2) format('woff2'), url(https://fonts.bunny.net/urbanist/files/urbanist-latin-700-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Urbanist';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-700-italic.woff2) format('woff2'), url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-700-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Urbanist';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-800-italic.woff2) format('woff2'), url(https://fonts.bunny.net/urbanist/files/urbanist-latin-800-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Urbanist';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-800-italic.woff2) format('woff2'), url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-800-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Urbanist';
  font-style: italic;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-900-italic.woff2) format('woff2'), url(https://fonts.bunny.net/urbanist/files/urbanist-latin-900-italic.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Urbanist';
  font-style: italic;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-900-italic.woff2) format('woff2'), url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-900-italic.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 100;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-100-normal.woff2) format('woff2'), url(https://fonts.bunny.net/urbanist/files/urbanist-latin-100-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 100;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-100-normal.woff2) format('woff2'), url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-100-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-200-normal.woff2) format('woff2'), url(https://fonts.bunny.net/urbanist/files/urbanist-latin-200-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 200;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-200-normal.woff2) format('woff2'), url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-200-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/urbanist/files/urbanist-latin-300-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-300-normal.woff2) format('woff2'), url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-300-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/urbanist/files/urbanist-latin-400-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-400-normal.woff2) format('woff2'), url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-400-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/urbanist/files/urbanist-latin-500-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-500-normal.woff2) format('woff2'), url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-500-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/urbanist/files/urbanist-latin-600-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-600-normal.woff2) format('woff2'), url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-600-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/urbanist/files/urbanist-latin-700-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-700-normal.woff2) format('woff2'), url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-700-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/urbanist/files/urbanist-latin-800-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-800-normal.woff2) format('woff2'), url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-800-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-900-normal.woff2) format('woff2'), url(https://fonts.bunny.net/urbanist/files/urbanist-latin-900-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 900;
  font-stretch: 100%;
  src: url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-900-normal.woff2) format('woff2'), url(https://fonts.bunny.net/urbanist/files/urbanist-latin-ext-900-normal.woff) format('woff');
  unicode-range: U+0100-02AF,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/*
New fonts className
*/
.Open-Sans{
  font-family: "Open Sans";
}
.Roboto_Flex{
  font-family: "Roboto Flex";
}
.Space-Grotesk{
  font-family: "Space Grotesk";
}
.Alegreya {
  font-family: 'Alegreya';
}
.Cinzel{
  font-family: 'Cinzel';
}
.Inter{
  font-family: "Inter";
}
.Manrope{
  font-family: "Manrope";
}
.Source_Sans_3{
  font-family: "Source Sans 3";
}
.Playfair_Display{
  font-family: "Playfair Display";
}
.Archivo{
  font-family: "Archivo";
}
.Asap{
  font-family: "Asap";
}
.Montserrat{
  font-family: "Montserrat";
}
/* New fonts */
.Raleway{
  font-family: "Raleway";
}
.Rubik{
  font-family: "Rubik";
}
.Epilogue {
  font-family: "Epilogue";
}
.Oswald{
  font-family: "Oswald";
}
.Nunito{
    font-family: "Nunito";
}
.Work_Sans{
  font-family: "Work Sans";
}
.Cabin{
  font-family: "Cabin";
}
.Arimo{
  font-family: "Arimo";
}
.EB_Garamond{
  font-family: "EB Garamond";
}
.Faustina{
  font-family: "Faustina";
}
.Karla{
    font-family: "Karla";
}
.Lora{
    font-family: "Lora";
}
.Sora{
    font-family: "Sora";
}
.Urbanist{
    font-family: "Urbanist";
}
/*
web safe fonts className
*/
.Helevetica{
  font-family: "Helvetica";
}
.Arial{
  font-family: "Arial";
}
.Arial_Black{
  font-family: "Arial Black";
}
.Verdana{
  font-family: "Verdana";
}
.Tahoma{
  font-family: "Tahoma";
}
.Trebuchet_MS{
  font-family: "Trebuchet MS";
}
.Impact{
  font-family: "Impact";
}
.Times_New_Roman{
  font-family: "Times New Roman";
}
.Georgia{
  font-family: "Georgia";
}
