/*
 * Mixins
 */

.menuItemPadding() {
  padding: 0 1.5em;
}

/*
 * Styles
 */

.app-menu {
  display: flex;
  justify-content: space-between;
  min-height: @menuHeight;
  max-height: @menuHeight;
  color: white;
  background-color: @brand-primary;
  box-shadow: 0 2px 2px 0 rgba(34, 36, 38, 0.15);
  z-index: @menuPosition;

  /**
   * Generic
   */

  .app-menu__left,
  .app-menu__right {
    display: flex;
  }

  .app-menu__item-no-hover {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    border-left: 1px solid @brand-primary-hover;
    .menuItemPadding();
  }

  .app-menu__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    border-left: 1px solid @brand-primary-hover;
    cursor: pointer;
    .menuItemPadding();

    &:hover {
      background-color: @brand-primary-hover;
    }

    .fa:not(:only-child) {
      margin-right: 5px;
    }

    &.dropdown {
      padding: 0;

      .dropdown-toggle {
        height: 100%;
        display: flex;
        align-items: center;
        .menuItemPadding();
      }
    }
  }

  .dropdown-menu {
    min-width: 100%;
    max-height:~'calc(100vh - @{menuHeight} - @{gutter})';
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    border-radius: 0 0 4px 4px;
    border-top: 0;
  }

  /**
   * App selector
   */

  .app-selector.app-menu__item {
    margin: 0.7em;
    background-color: #127d9f;
    border: 1px solid #127594;
    border-radius: 4px;

    &:hover,
    &:focus {
      background-color: darken(#127d9f, 5%);
    }

    .dropdown-toggle {
      padding: 0 1em;
      border-left: 0;
    }

    .dropdown-menu {
      border-radius: 4px;
      top: calc(~'100% + 0.4em');
      left: -1px;
    }

    .app-selector__info {
      display: flex;
      flex-direction: column;
    }

    .fa-angle-down {
      display: flex;
      flex-direction: column;
      margin: 0;
    }

    .app-selector__brand {
      font-size: 11px;
      opacity: 0.8;
    }

    .app-selector__module {
      font-size: 14px;
      text-transform: uppercase;
      margin-right: @gutter;
      display: flex;
      align-items: center;
      letter-spacing: 0.5px;
    }

    .app-selector__upgrade {
      font-size: 12px;
      float: right;
    }

    .app-selector__link--disabled {
      color: #777 !important;
    }

    .app-selector__header {
      padding: 5px 10px;
      font-size: 12px;
      font-weight: 600;
      color: black;
      background-color: #f5f5f5;
      border: 1px solid #ddd;
      border-right: none;
      border-left: none;
      cursor: default;
    }

    .app-selector__icon {
      margin-right: 20px !important;
      font-size: 24px;

      &:before {
        width: 24px;
      }
    }

    .app-selector__link {
      padding: 0.8em 1.5em;

      .app-selector__icon {
        color: rgba(0, 0, 0, 0.2);
      }
    }
  }

  /**
   * Church selector
   */

  .church-selector.app-menu__item {
    ul.dropdown-menu {
      li {
        span {
          text-transform: capitalize;
        }

        &.active {
          span.active {
            color: #333;

            &::after {
              font: normal normal normal 14px/1 'Font Awesome 5 Pro';
              content: '\f00c';
              color: #7ab800;
              margin-left: 10px;
            }
          }
        }

        &:not(.current):not(:hover) {
          .text-avatar {
            background-color: #777 !important;
          }
        }

        .church-selector__promo {
          padding: 0.8em 1.5em;
          border-bottom: 1px solid #ddd;
          white-space: normal;

          .church-selector__promo-header span {
            color: #008db6;
            white-space: nowrap;
          }

          p span {
            text-transform: inherit;
            font-weight: 400;
            font-size: 12px;
          }

          &:hover,
          &:focus {
            background-color: #f5f5f5;
            cursor: pointer;

            .church-selector__promo-header span {
              color: #006583;
            }

            p span {
              color: #333;
            }
          }
        }

        .church-selector__parish {
          padding: 0.5em 1.3em;
          color: #777;

          span {
            flex: 1;
          }

          &:hover,
          &:focus {
            background-color: #f5f5f5;
            cursor: pointer;

            span {
              color: #333;
            }
          }
        }

        // Churches in the organization
        .church-selector__churches {
          ul {
            padding: 0;

            li {
              list-style: none;
              color: #777;
              white-space: nowrap;

              .church-selector__church {
                padding: 0.5em 0.8em 0.8em 4em;

                span {
                  font-size: 12px;

                  &::before {
                    font: normal normal normal 12px/1 'Font Awesome 5 Pro';
                    content: '\f1ad';
                    color: #777;
                    margin-right: 10px;
                  }
                }
              }

              &:hover,
              &:focus {
                background-color: #f5f5f5;
                cursor: pointer;

                span {
                  color: #333;

                  &::before {
                    color: #333;
                  }
                }
              }
            }
          }

          ul.church-selector__church-add {
            li {
              padding: 0.5em 0 0.8em 4em;

              a {
                padding: 0;

                span {
                  padding: 0;
                  text-transform: inherit;
                  font-weight: 400;
                  color: #7ab800;

                  &:hover,
                  &:focus {
                    color: #517b00;
                  }
                }
              }

              &:hover,
              &:focus {
                background: none;
              }
            }
          }
        }
      }
    }

    .church-selector__organization {
      font-size: 11px;
      opacity: 0.8;
    }

    .church-selector__module {
      display: flex;
      align-items: center;
      font-size: 14px;
      margin-right: @gutter;

      span {
        @media (max-width: 768px) {
          white-space: nowrap;
          max-width: 130px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .church-selector__upgrade {
      font-size: 12px;
      float: right;
    }

    .fa-angle-down {
      display: flex;
      flex-direction: column;
      margin: 0;
    }
  }

  /**
   * Create content
   */

  .create-content-dropdown {
    .create-content-dropdown__toggle {
      height: @menuHeight;
      width: 64px;
      text-align: center;
      border: 0;
      color: white;
      background-color: @brand-success;

      &:hover,
      &:focus,
      &:active {
        background-color: @brand-success-hover;
      }

      i {
        line-height: @menuHeight;
      }
    }

    .dropdown-menu {
      li {
        padding: 30px 20px;
        text-align: center;
        cursor: pointer;

        i {
          color: #ccc;

          &::before {
            width: 100%;
          }
        }

        span {
          color: @text-color;
          text-transform: uppercase;
          font-size: 14px;
          display: block;
          margin-top: 10px;
          font-weight: 700;
        }

        small {
          border-radius: 4px;
          box-shadow: none;
          border: none;
          min-width: 18px;
          min-height: 18px;
          text-align: center;
          padding: 2px 4px;
          font-size: 12px;
          display: inline-block;
          color: #fff;
          background-color: #ccc;
          margin-top: 10px;
        }

        &:not(:first-of-type) {
          border-left: 1px solid rgba(0, 0, 0, 0.03);
        }

        &:hover {
          background-color: rgba(0, 0, 0, 0.03);
        }
      }
    }
  }

  /*
   * User menu
   */
  .user-dropdown {
    .current-user__info {
      margin: 0 12px;
      line-height: 1.1;
    }

    .dropdown-menu {
      .dropdown-header {
        cursor: default;
      }

      .divider {
        cursor: default;
      }
    }
  }

  /*
   * Support
   */
  .support-dropdown {
    @light-gray: rgba(0, 0, 0, 0.03);

    .dropdown-menu {
      cursor: default;
      min-width: 300px;
    }

    .support-dropdown__item {
      display: flex;
      flex: 1;
      align-items: center;
      padding: 20px;
      color: @text-color;
      font-weight: 700;

      &:focus {
        text-decoration: none;
      }

      &:not(:last-child) {
        border-bottom: 1px solid @light-gray;
      }

      i,
      img {
        width: 30px;
        margin-right: 20px !important;
        text-align: left;
      }
    }

    a.support-dropdown__item:hover {
      cursor: pointer;
      background-color: @light-gray;
      text-decoration: none;
    }
  }
}