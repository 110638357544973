@left-width: 58%;

.view-form-responses {
  .form-response-panel {
    position: relative;
  }

  .form-response-panel__background {
    position: absolute;
    width: @left-width;
    height: 100%;
    z-index: -1;

    &.full-width {
      width: 100%;
    }
  }

  .form-response-panel__header {
    display: flex;
    align-items: center;
    width: @left-width;
    border-bottom: 1px solid #eee;
    padding: 1em;

    &.full-width {
      width: 100%;
    }
  }

  .form-response-panel__heading {
    flex: 1;
  }

  .form-response-panel__actions {
    opacity: 0.5;
  }

  .form-response-panel__subheader {
    width: @left-width;
    padding: 1em;

    &.full-width {
      width: 100%;
    }
  }

  .form-response-panel__body {
    padding-bottom: 0.1em;
  }
}
