#dashboard {
  padding: 24px;

  .panel-body {
    padding: 0;
  }

  .panel-nav-group {
    .nav-group li {
      text-align: left;
    }
  }

  .panel-footer {
    border-top-color: @body-bg;
  }

  .show-declined {
    border-top: none;
  }

  .panel .panel-heading {
    border-bottom: 0;
  }

  .form-control-feedback {
    top: 0;
  }

  .ms-gdpr-items-container {
    @media (min-width: 1440px) {
      margin-right: 8.3%;
      margin-left: 8.3%;
    }
  }

  .ms-gdpr-items {
    display: flex;
    margin-bottom: 5px;

    @media (max-width: 992px) {
      flex-direction: column;
    }

    .ms-gdpr-item {
      display: flex;
      flex: 1;

      @media (max-width: 992px) {
        margin: 10px 0px;
      }

      .ms-gdpr-item__icon {
        margin-top: 10px;
      }

      .ms-gdpr-item__content {
        h5 {
          font-weight: bold;
        }

        p {
          flex: 1;
        }
      }
    }
  }
}

.qtip {
  .qtip-content {
    .nav-group {
      li {
        font-size: 14px;

        a {
          padding: 10px 10px 10px 0;
        }
      }
    }
    .createEvent,
    .createAbsence {
      .col-sm-3 {
        &.date-label {
          padding-top: 0;
          padding-right: 0;
        }
        &.control-label {
          padding-right: 0;
        }
      }
      .qtip-controls {
        margin-bottom: 0;
      }
    }
  }
}

.qtip-default {
  background-color: white;
}

#dashboard,
.declined-invitations {
  .btn-label {
    &.btn-primary {
      background-color: #fff !important;
      color: @brand-primary !important;
      border-color: @brand-primary !important;

      &:hover {
        color: darken(@brand-primary, 5%) !important;
        border-color: darken(@brand-primary, 5%) !important;
      }
    }

    &.btn-warning {
      background-color: #fff !important;
      color: @brand-warning !important;
      border-color: @brand-warning !important;

      &:hover {
        color: darken(@brand-warning, 5%) !important;
        border-color: darken(@brand-warning, 5%) !important;
      }
    }

    &.btn.btn-default.btn-danger,
    &.btn-danger {
      background-color: #fff !important;
      color: @brand-danger !important;
      border-color: @brand-danger !important;

      &:hover {
        color: darken(@brand-danger, 5%) !important;
        border-color: darken(@brand-danger, 5%) !important;
      }
    }

    &.btn-primary {
      background-color: #fff !important;
      color: @brand-success !important;
      border-color: @brand-success !important;

      &:hover {
        color: darken(@brand-success, 5%) !important;
        border-color: darken(@brand-success, 5%) !important;
      }
    }

    &.btn-default {
      background-color: #fff !important;
      color: #797979;
      border-color: rgba(34, 36, 38, 0.15) !important;

      &:hover {
        color: #5d5d5d !important;
        border-color: darken(rgba(34, 36, 38, 0.15), 5%) !important;
      }
    }
  }
}
