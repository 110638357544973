/* Variables
   ============================= */

@loaderTiny: 13px;
@loaderMini: 16px;
@loaderSmall: 24px;
@loaderMedium: 32px;
@loaderLarge: 48px;
@loaderTinyOffset: 0em 0em 0em - (@loaderTiny / 2);
@loaderMiniOffset: 0em 0em 0em - (@loaderMini / 2);
@loaderSmallOffset: 0em 0em 0em - (@loaderSmall / 2);
@loaderMediumOffset: 0em 0em 0em - (@loaderMedium / 2);
@loaderLargeOffset: 0em 0em 0em - (@loaderLarge / 2);

/* Animation
   ============================= */

@-webkit-keyframes loader {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* Basic styling
   ============================= */

.cd-loader {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0px;
  text-align: center;
  z-index: 1000;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);

  // Static state
  &:before {
    position: absolute;
    content: '';
    top: 0%;
    left: 50%;
    width: 100%;
    height: 100%;
    border-radius: 500rem;
    border: 0.2em solid rgba(0, 0, 0, 0.1);
  }

  // Active state
  &:after {
    position: absolute;
    content: '';
    top: 0;
    left: 50%;
    -webkit-animation: loader 0.6s linear;
    animation: loader 0.6s linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    border-radius: 500rem;
    border-color: #767676 transparent transparent;
    border-style: solid;
    border-width: 0.2em;
    box-shadow: 0 0 0 1px transparent;
  }
}

/* Sizing
   ============================= */

// Tiny
.cd-loader--tiny,
.cd-loader--tiny:after,
.cd-loader--tiny:before {
  width: @loaderTiny;
  height: @loaderTiny;
}
.cd-loader--tiny:after,
.cd-loader--tiny:before {
  margin: @loaderTinyOffset;
  border-width: 0.15em;
}

// Mini
.cd-loader--mini,
.cd-loader--mini:after,
.cd-loader--mini:before {
  width: @loaderMini;
  height: @loaderMini;
}
.cd-loader--mini:after,
.cd-loader--mini:before {
  margin: @loaderMiniOffset;
  border-width: 0.15em;
}

// Small
.cd-loader--small,
.cd-loader--small:after,
.cd-loader--small:before {
  width: @loaderSmall;
  height: @loaderSmall;
}
.cd-loader--small:after,
.cd-loader--small:before {
  margin: @loaderSmallOffset;
}

// Medium (default)
.cd-loader--medium,
.cd-loader--medium:after,
.cd-loader--medium:before {
  width: @loaderMedium;
  height: @loaderMedium;
}
.cd-loader--medium:after,
.cd-loader--medium:before {
  margin: @loaderMediumOffset;
}

// Large
.cd-loader--large,
.cd-loader--large:after,
.cd-loader--large:before {
  width: @loaderLarge;
  height: @loaderLarge;
}
.cd-loader--large:after,
.cd-loader--large:before {
  margin: @loaderLargeOffset;
}

/* With dimmer (white default)
   ============================= */

.cd-loader-dimmer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 1;
  display: block;
  z-index: 800;
  background-color: rgba(255, 255, 255, 0.85);
  border-radius: 4px;

  .cd-loader {
    color: rgba(0, 0, 0, 0.87);

    &:before {
      border-color: rgba(0, 0, 0, 0.1);
    }
    &:after {
      border-color: #767676 transparent transparent;
    }
  }

  // Dark dimmer
  &.cd-loader-dimmer--inverted {
    background-color: rgba(0, 0, 0, 0.85);

    .cd-loader {
      color: rgba(255, 255, 255, 0.9);

      &:before {
        border-color: rgba(255, 255, 255, 0.15);
      }
      &:after {
        border-color: #ffffff transparent transparent;
      }
    }
  }

  // Page dimmer
  &.cd-loader-dimmer--page {
    position: absolute;
    top: auto;
    left: auto;
    border-radius: 0;
    background-color: @body-bg;
  }
}

/* Inverted
   ============================= */

.cd-loader--inverted {
  color: rgba(255, 255, 255, 0.9);

  &:before {
    border-color: rgba(255, 255, 255, 0.15);
  }
  &:after {
    border-top-color: #ffffff;
  }
}

/* Inline
   ============================= */

.cd-loader--inline {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  margin: 0em;
  left: 0em;
  top: 0em;
  -webkit-transform: none;
  transform: none;
  z-index: auto;
}

/* With text
   ============================= */

.cd-loader--with-text {
  width: auto !important;
  height: auto !important;
  text-align: center;
  font-style: normal;
}

.cd-loader--tiny {
  min-width: @loaderTiny;
  padding-top: calc(@loaderTiny * 1.33);
}

.cd-loader--mini {
  min-width: @loaderMini;
  padding-top: calc(@loaderMini * 1.33);
}

.cd-loader--small {
  min-width: @loaderSmall;
  padding-top: calc(@loaderSmall * 1.33);
}

.cd-loader--medium {
  min-width: @loaderMedium;
  padding-top: calc(@loaderMedium * 1.33);
}

.cd-loader--large {
  min-width: @loaderLarge;
  padding-top: calc(@loaderLarge * 1.33);
}
