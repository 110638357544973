.create-content-backdrop {
  opacity: 0.5 !important;
}

.create-content-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;

  .modal-dialog {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
  }

  .modal-content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
    box-shadow: none;
    background: transparent;
    overflow: hidden;

    .modal-header.modal-top-header {
      position: relative;
      border: 0;
      background: #fff;

      ul > li:first-child a {
        pointer: none;

        &:hover {
          background: none;
        }
      }

      ul > li > a.close {
        opacity: 0.6;

        &:hover {
          opacity: 0.7;
        }
      }
    }

    .modal-header.modal-steps-header {
      background: rgba(255, 255, 255, 1);
      z-index: 1;
      border: none;
      position: relative;

      &.sticky {
        width: calc(~'100% - 96px');
      }

      .steps {
        border: none;
        margin: 0;
        box-shadow: 0px 20px 30px -15px rgb(255, 255, 255);

        .step:last-child {
          border-right: none;
        }
      }
    }

    .modal-body {
      position: relative;
      margin: 20px auto;
      width: calc(~'100% - 96px');
      overflow: hidden;
      top: 0;
      border: 1px solid #fff;

      .panel.panel-container {
        position: relative;
        overflow: hidden;

        > .panel-body {
          height: calc(~'100vh - 244px');
          position: relative;
          overflow: auto;
          -webkit-overflow-scrolling: touch;
        }
      }

      .create-content-form {
        padding: 20px;
      }

      .centered-content {
        max-width: 700px;
        margin: 0 auto;
      }

      .form-group {
        margin-bottom: 15px !important; // Overriding some ultra-specific styling in file archive.

        &.form-group--less-bottom-margin {
          margin-bottom: 5px !important;
        }
      }

      .repeat-dropdown-container {
        padding-left: 10px;
        display: flex;
        flex-direction: column;

        &.repeat-dropdown-container--fluid {
          padding-left: 0px;
          flex: 1 0 100%;
        }

        .repeat-dropdown {
          .ui-select-choices {
            width: auto;
          }
        }
      }

      .form-group-panel {
        padding: 15px;
        border: none;
        box-shadow: none;
        border-radius: 2px;
        box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;

        hr {
          margin: 10px -15px;
        }

        &.form-group-facebook {
          z-index: 1;
          background-color: #fcfcfc;

          &.can-share {
            background-color: #fff;
          }

          .should-disable {
            opacity: 0.5;
            pointer-events: none;
          }
        }
      }

      .redactor-editor pre {
        white-space: normal;
      }

      .checkbox.checkbox-inline label {
        padding-left: 0;
      }

      .alert.alert-info {
        box-shadow: none;
        border: none;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        width: 100%;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        position: relative;
        min-height: 1em;
        margin: 1em 0;
        padding: 1em 1.5em;
        line-height: 18px;

        i.fa {
          margin-right: 0.6em;
        }

        &.alert--attached-top {
          margin: -10px 0px 1px 0px;
          border-bottom-left-radius: 0px;
          border-bottom-right-radius: 0px;
        }
      }

      #event-planning-step {
        max-width: 900px;

        .rota-table {
          .rota-table-input {
            position: relative;
            height: 100%;
            padding: 0;

            .table-cell__wrapper {
              height: 100%;
              width: 100%;
            }

            .table-cell__number {
              display: block;
              height: 100%;
              width: 100%;
              margin: 0;
            }

            .table-cell__number {
              padding: 24px 14px;
            }

            .table-cell__note {
              padding: 12px 14px;
              height: 100%;
            }

            .table-cell__number,
            .table-cell__note {
              background-color: transparent;
              border-color: transparent;
              border-radius: 0;
              resize: none;
              overflow: hidden;

              &:focus {
                border-color: @brand-primary;
              }
              &.ng-invalid {
                border-color: @brand-danger;
              }
            }
          }
        }
      }

      .users-select {
        .ui-select-choices-row-inner {
          white-space: normal;
        }
      }

      .selected-users-list {
        .list-group-item {
          padding-left: 5px;
          padding-right: 5px;
          border-radius: 4px;
          border: none;
          transition: background-color 0.2s ease-in-out;

          &:hover {
            background-color: #f0f0f0;
          }
        }
      }
    }

    .modal-footer {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      max-height: 83px;
      padding: 22px 24px;
      background: #fff;
    }
  }
}

#visibility-options,
#edit-recurring-options {
  .radio {
    input[type='radio'] {
      margin-top: 18px;
    }

    .media {
      margin-left: 4px;
    }

    .media-left {
      vertical-align: top;
      padding: 0 4px;
      text-align: center;
    }

    .media-icon {
      background: #eee;
      padding: 10px 8px;
    }

    .media-body {
      padding-bottom: 0;
    }

    .media-body > * {
      margin-left: 10px;
    }

    .media-heading {
      padding-top: 0;
    }
  }
}
