#blogview {
  .full-page-loader {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    span.processing {
      position: absolute;
      left: 50%;
    }
  }
  .dropdown-menu {
    overflow: hidden;
    border-radius: 4px;
    li > a {
      padding: 8px 10px;
      > i {
        padding: 0;
        line-height: 20px;
        color: #008db6;
      }
    }
  }
  .no-results {
    text-align: center;
    margin-top: -15px;
    padding: 50px 0 40px;
    clear: both;
    border-radius: 4px;
  }
  form {
    width: 100%;
    margin-bottom: 8px;
  }
  ul.pagination {
    margin: 0;
  }
  .table > tbody > tr > td {
    position: relative;
  }
  .browse-wrapper {
    position: relative;
    clear: both;
    margin-bottom: 20px;
    .browse-scroll {
      overflow: visible;
      position: relative;
      #browse {
        background: #fff;
        width: 100%;
        border: 1px solid #ddd;
        &.fade-50 {
          opacity: 0.7;
        }
        .browse-header {
          background-color: #f5f5f5;
        }
        .browse-body {
          .dropdown-menu {
            right: 0;
            left: inherit;
            border-radius: 4px;
            overflow: hidden;
            i:before {
              width: inherit;
            }
            li > a {
              padding: 8px 20px;
              i {
                color: inherit;
                padding-right: 8px;
              }
            }
          }
          .image {
            width: 48px;
            height: 48px;
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
            background-size: cover;
            background-position: 50%;
            overflow: hidden;
            cursor: pointer;
            background-color: #e9e9e9;
            text-align: center;
            i {
              color: #999;
              margin: 10px 0;
            }
          }
          .title {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            position: absolute;
            padding: 14px 10px 14px 0;
            width: 70%;
          }
        }
      }
    }
  }
}

#group-route {
  #blogs {
    padding: 0;
    list-style-position: inside;
  }

  #blogview {
    padding: 0;

    form {
      margin-bottom: 8px;
    }

    .browse-wrapper {
      .browse-scroll {
        #browse {
          background: #fff;
          width: 100%;
          border: 0;
          .browse-header {
            background-color: #fff;
          }
        }
      }
    }
  }
}
