.app-container__people-message-editor {
  display: flex;
  flex-direction: column;
  width: 100%;

  /* Church selector for multi-church
  ============================= */
  .church-selector__wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 0 !important;
    padding: 0 17px 0 0;
    border-color: #ddd;
    border-width: 0 0 1px 0;
    background-color: #fff;
    border-radius: 0;

    .church-selector__label {
      margin-right: 15px;
      font-weight: bold;
      padding: 12px 17px;
      background-color: #eeeeee;
      color: #333;
      white-space: nowrap;
    }

    .church-selector__dropdown {
      height: 44px;
      display: flex;
      align-items: center;
      padding: 6px 10px 6px 0;
      border: none;

      div {
        display: flex;
        max-width: 100%;
        overflow-x: scroll;
        width: fit-content;

        .ui-select-match {
          display: flex;
          flex: 1;
          width: 100%;
          min-width: fit-content;

          [type='button'],
          .ui-select-match-item {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            padding: 5px 8px;
            border-radius: 20px;
            background-color: #008db7;
            border: none;
            color: #fff;
            -webkit-appearance: initial;
          }
        }
        input {
          margin: auto 0;
          width: auto;
        }
      }
      .ui-select-choices {
        .ui-select-choices-row {
          width: 100% !important;
          overflow-x: hidden;

          .ui-select-choices-row-inner {
            width: 100%;

            div {
              width: 100%;
              overflow-x: hidden;
            }
          }
        }
      }
    }

    .church-selector__reset-btn {
      flex: 1;
      text-align: end;
      white-space: nowrap;
    }
  }

  .people-message {
    .people-message-editor {
      flex-direction: column;
      position: relative;
      border: 1px solid rgba(34, 36, 38, 0.15);
      border-radius: 4px;
      background-color: white;
    }

    .people-message-editor__from,
    .people-message-editor__to,
    .people-message-editor__churches {
      padding: 6.5px 16px;
    }

    .people-message-editor__type-switch {
      position: relative;
      padding: 12px 16px 0;
      background-color: white;
    }

    .people-message-editor__from {
      padding-top: 16px;
      border-radius: 4px 4px 0 0;

      .ui-select-match-text {
        width: auto;
      }
    }

    .people-message-editor__body {
      border-radius: 0 0 4px 4px;
      background-color: white;
      height: 80vh;
      padding: 16px 8px;
    }

    .people-message-editor__to,
    .people-message-editor__churches {
      .ui-select-multiple {
        .ui-select-match-item {
          padding: 5px 8px;
          border-radius: 20px;
          background-color: white;
          border-color: #e0e1e2;
          color: @text-color;
          -webkit-appearance: initial;

          i.fa {
            color: #d9d9d9;
          }

          img {
            margin-right: 3px;
            margin-top: -1px;
          }

          &:hover {
            background-color: white;
            border-color: #e0e1e2;
          }
        }

        .ui-select-choices {
          .ui-select-choices-row {
            i.fa {
              text-align: left;
              color: #d9d9d9;
              vertical-align: middle;
              width: 28px;
            }

            &.active .ui-select-choices-row-inner {
              background: rgba(0, 0, 0, 0.05);
              color: inherit;
            }

            .ui-select-choices-row-inner {
              padding: 10px 20px;
            }
          }
        }

        .ui-select-search[disabled='disabled'] {
          display: none;
        }
      }
      .form-control[disabled] {
        background-color: #fff;
      }
    }

    .people-message_summary {
      display: flex;
      align-items: center;
      margin: 10px 0 0;
      border-radius: 0;

      .people-message__total {
        padding: 10px 20px 10px 0;
        margin-right: 20px;
        font-weight: 700;
        font-size: 16px;
        border-right: 2px solid #008db6;
      }
    }

    .people-message-editor__sms {
      margin: 15px auto;
      background-color: #fff;

      textarea {
        padding: 20px;
        font-size: 18px;
        white-space: pre-wrap;
      }
    }

    .people-message-editor__iframe-wrapper {
      height: 100%;
      iframe {
        width: 100%;
        height: 100% !important;
      }
    }
  }
}
