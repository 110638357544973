#group,
#graveyard,
#taxonomies {
  .nav-link {
    @media (min-width: 1103px) and (max-width: 1450px) {
      #goToCal {
        display: none;
      }
    }
  }

  @media (min-width: 992px) and (max-width: 1102px) {
    #goToCal {
      display: none;
    }

    .nav-group li a {
      min-width: 100px !important;
    }
  }

  @media (min-width: 873px) and (max-width: 1100px) {
    #goToCal {
      display: inline-block;
    }
  }

  @media (max-width: 872px) {
    #goToCal {
      display: none;
    }
  }

  @media (max-width: 751px) {
    .nav-group li a {
      min-width: 100px !important;
    }
  }

  .action-header {
    display: block;

    .actions {
      display: inline-block;
      position: relative;
      margin-bottom: 15px;
    }

    .search {
      width: 35%;
      margin-bottom: 15px;
    }

    @media (max-width: 862px) {
      .search {
        width: 100%;
        margin-bottom: 20px;
        margin-top: -4px;
      }
    }
  }

  .comment {
    margin-left: 48px;
  }

  .panel-nav-group {
    .nav-group li {
      width: auto;
      min-width: 115px;
      text-align: center;
    }
  }

  .group-image {
    width: 100%;
    height: 220px;
    display: block;
    position: relative;
    overflow: hidden;
    margin-top: -15px;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }

  .group-text {
    padding: 15px 15px 0 15px;
  }

  .browse-header th {
    cursor: pointer;
  }

  .join-group,
  .no-results,
  .sales-contact {
    text-align: center;
    padding: 40px 0 10px 0;

    span i {
      color: @gray-light;
    }

    .text {
      font-size: 24px;
      color: @gray-light;
      padding: 30px 0;
    }
  }

  .comments {
    .divider hr {
      border-color: #ddd;
    }

    .cmt-header {
      height: 20px;
      margin-top: 0;

      span {
        font-size: 18px;
      }

      a span {
        font-size: 13px;
      }
    }

    .media {
      &:first-child {
        margin-top: inherit;
      }

      .media-left {
        display: table-cell;
        float: left;
        vertical-align: top;
        padding-right: 10px;
      }

      .media-body {
        width: 100%;
        display: table-cell;
        vertical-align: middle;

        .title {
          margin-top: 0;
          margin-bottom: 5px;
        }

        .details {
          color: #848484;
          margin-bottom: 5px;
        }
      }
    }

    .cmt-wrapper {
      .comment {
        margin-left: 0;
        transition: 1s;
        -webkit-transition: 1s;
      }
      .comment.ng-enter {
        opacity: 0;
      }
      .comment.ng-leave {
        opacity: 1;
      }
      .comment.ng-enter.ng-enter-active {
        opacity: 1;
      }
      .comment.ng-leave.ng-leave-active {
        opacity: 0;
      }
    }

    .new-comment {
      margin-top: 15px;
    }
  }

  #aboutGroup {
    .panel .panel-heading a {
      padding: 8px 20px;
    }
    .isMemberTrue,
    .isMemberFalse {
      margin: -6px;
    }

    .group-leader {
      img  {
        margin: 0.5em 1em;
      }
      span  {
        vertical-align: middle;
      }
    }
  }
}

#blogs,
#messages {
  .pic-blog {
    margin-left: 20px;
    float: right;
  }

  .pic {
    vertical-align: top;
    margin-right: 20px;
    float: left;

    a {
      display: block;
    }

    .blog-nopicture {
      width: 192px;
      height: 124px;
      text-align: center;
      background-color: #e1e1e1;
      color: #ccc;

      i {
        line-height: 124px;
      }
    }
  }

  .content {
    .details,
    .body,
    .read-more {
      margin-left: 0;
      overflow: hidden;
    }
  }

  .message-content {
    margin-left: 0;

    .details,
    .body {
      margin-left: 0;
    }
  }

  .title {
    font-size: 15px;
    font-weight: 400;
  }

  .details {
    color: #848484;

    .blog-replies,
    .blog-categ {
      padding-left: 15px;

      a {
        color: @brand-primary;
      }
    }

    .body {
      padding-top: 5px;
    }
  }

  .read-more {
    padding-top: 15px;
    margin-left: 212px;
  }

  .item-blog {
    padding-top: 0;
    clear: both;
  }
}

#members {
  .name-header,
  .name-col {
    width: 45%;
  }

  .since-header,
  .since-col {
    width: 20%;
  }

  .role-header,
  .role-col {
    width: 15%;
  }

  .actions-header,
  .actions-col {
    width: 20%;
    text-align: center;
  }

  .name-col {
    div a {
      display: inline-block;

      img {
        vertical-align: middle;
      }
    }

    .user-name {
      line-height: 28px;
      display: inline-block;
    }

    .user-image {
      margin-right: 10px;
    }
  }

  .export {
    margin-top: 15px;
  }
}

/* Messages */
#messages {
  .list {
    clear: both;

    .media {
      .media-body {
        margin-top: 4px;
        .actions-col {
          float: right;
          margin: 10px 0;
        }

        h3.title {
          display: block;
          font-size: 28px;
          line-height: 1.1;
          color: rgba(0, 0, 0, 0.8);
          margin-top: 2px;
          margin-bottom: 0;
          letter-spacing: -0.02em;
          font-weight: 700;
          font-style: normal;
        }

        .title {
          margin-top: 0;
          font-size: 15px;
          font-weight: 400;
        }

        .details {
          color: #848484;
        }

        .details-messagepost {
          color: #848484;
          padding: 5px 0;
          display: inline-block;
        }

        .body {
          padding: 10px 0;
          font-size: 18px;
          line-height: 1.4;
          color: rgba(0, 0, 0, 0.6);
          margin-bottom: 4px;
          letter-spacing: -0.02em;
          font-weight: 400;
          font-style: normal;
        }
      }

      .media-left {
        display: table-cell;
        vertical-align: top;
        padding-right: 10px;
      }

      .media-body {
        display: table-cell;
        vertical-align: middle;
      }

      .comment-image-wrapper {
        overflow: hidden;
        width: 28px;
        text-align: center;
      }
    }
  }

  .media-date {
    margin-top: 5px;
    .pull-left {
      margin-right: 5px;
    }
  }

  .browse-users {
    background: #ffffff;
    border: 1px solid #ddd;

    .browse-header {
      background-color: #f5f5f5;

      th > a > i {
        margin-left: 8px;
      }
    }

    .check-header,
    .check-col {
      width: 5%;
      text-align: center;
    }

    .name-header,
    .name-col {
      width: 40%;
    }

    .since-header,
    .since-col {
      width: 20%;
    }

    .role-header,
    .role-col {
      width: 15%;
    }

    .actions-header,
    .actions-col {
      width: 20%;
      text-align: center;
    }

    .name-col {
      div a {
        display: inline-block;

        img {
          vertical-align: middle;
        }
      }

      .user-name {
        line-height: 28px;
        display: inline-block;
      }

      .user-image {
        display: inline-block;
        margin-right: 10px;
        vertical-align: middle;

        i {
          vertical-align: middle;
        }
      }
    }
  }
}

#angular-controller {
  .pager-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}

.btn-file {
  position: relative;
  overflow: hidden;
}

.btn-file input[type='file'] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  width: 105px;
  height: 34px;
  font-size: 999px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
  overflow: hidden;
}

.group-img {
  .btn-link {
    color: #fff;
    padding: 0px;
    margin: 5px 10px 0 0;
    text-rendering: optimizelegibility;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.8);
  }

  .btn-file {
    overflow: visible;

    input[type='file'] {
      width: 20px;
      height: 19px;
    }
  }
}

.messages {
  display: none;
}

#postComment {
  margin-bottom: 15px;
}

.region-help {
  #block-system-help {
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid #bce8f1;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    color: #31708f;
    background-color: #d9edf7;
  }
}

.notification {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  background-color: #d9534f;
  width: 8px;
  height: 8px;
  position: absolute;
  top: 12px;
  left: 40px;
}

.fa + .notification {
  margin-left: -15px;
  margin-top: -5px;
  vertical-align: top;
}

.group-img .btn-link {
  color: #fff;
  padding: 0 !important;
  margin: 10px;
  background: transparent !important;
  height: auto;

  &:hover {
    background: transparent !important;
    color: #fff !important;
  }
}

#group #files-route {
  padding: 0;
}

#group {
  .no-image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    background-color: #e1e1e1;
    color: #ccc;
    border-radius: 4px;
  }
}
