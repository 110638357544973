// Users
.user-list {
  .roles-dropdown-menu {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    width: 300px;
    height: 600px;

    .dropdown-item > a {
      display: block;
      padding: 8px 10px;
      line-height: 20px;
      text-transform: none;
      overflow: hidden;
      text-overflow: ellipsis;

      &:hover {
        background-color: #f5f5f5;
        text-decoration: none;
      }
    }
  }

  .toolbar {
    position: relative;
    border: 1px solid #d4d4d5;
    border-bottom: none;
    background: #fff;
    padding: 1em;
    border-radius: 0.3em 0.3em 0 0;

    .btn:not(.btn-primary)[disabled] {
      cursor: default;
      background: #e0e1e2 !important;

      &:hover {
        background: #e0e1e2 !important;
        color: rgba(0, 0, 0, 0.6);
        border: 1px solid #e0e2e2 !important;
      }
    }

    @media (max-width: 768px) {
      button {
        i.fa {
          margin: 0;
        }
        .text {
          display: none;
        }
      }
    }
  }
}

// Profile
#userProfile {
  .media-details > span {
    margin-right: 10px;

    i {
      margin-right: 5px;
    }
  }

  .panel-heading {
    .pull-right {
      padding: 10px 15px;
    }
  }

  .app-aside {
    .app-aside__main {
      padding: 0;
    }

    section {
      padding-top: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #e9e9e9;
    }
  }

  .information {
    padding-left: 20px;
    padding-right: 20px;

    .picture {
      margin-bottom: 15px;
    }

    .name {
      line-height: 24px;
      font-size: 16px;
    }

    .email {
      line-height: 24px;
      word-wrap: break-word;
    }
  }

  .details {
    padding-left: 20px;
    padding-right: 20px;

    & > div {
      line-height: 32px;
      width: 210px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    i {
      margin-right: 10px;
    }

    .title {
      text-transform: uppercase;
      font-size: 13px;
    }
  }

  .stats {
    background-color: #e0e0e0;

    &:not(i) {
      font-weight: 700;
      line-height: 24px;
    }

    & > div {
      display: inline-block;
      width: 50%;
      font-size: 13px;
      text-transform: uppercase;

      &:hover {
        color: #333;
        cursor: default;
      }
    }
  }

  // Messages.
  #messages .panel-body {
    padding: 0;
  }

  .message {
    display: block;
    width: 100%;
    clear: both;
    float: left;
    padding: 15px;

    &:not(:last-of-type) {
      border-bottom: 1px solid #e9e9e9;
    }

    .title {
      font-size: 14px;
      font-weight: 400;
      display: block;
    }

    .body {
      color: #333;
      padding-top: 5px;
      display: block;
    }

    .media-details {
      padding-top: 5px;
      display: block;
    }

    &:hover {
      background-color: #f9f9f9;
    }
  }
}

// Edit
#user-edit {
  .panel {
    .list-group-item {
      &:hover {
        cursor: pointer;
      }

      &:first-child {
        border-width: 0;
        border-radius: 4px 4px 0 0;
      }

      h4 {
        margin-bottom: 0;

        i {
          width: 30px;
          float: left;
        }
      }

      .form-body {
        margin-left: 15px;
      }

      .details {
        margin-left: 30px;
        margin-bottom: 10px;

        .media-img {
          background-size: cover;
          background-position: 50%;
          width: 18px;
          height: 18px;
          display: inline-block;
          margin: 0 5px 0 0;
          background-color: #e9e9e9;

          i {
            font-size: 11px;
            margin: 3px 0;
            display: block;
          }
        }

        h6 {
          display: inline-block;
          margin: 0 15px 0 0;
        }
      }
    }
  }

  #notifications {
    table thead tr th i {
      height: 25px;
      display: block;

      &.fa-lg {
        line-height: 25px;
      }
    }
  }

  .intl-tel-input {
    display: block;
  }
}
