/**
 * Advanced filters
 */

.cd-filter-group {
  margin-bottom: 15px;
}

.cd-filter-group__title {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  margin-top: 0;
  padding: 0 @gutter;
}

.cd-filter {
  cursor: pointer;
  padding: 5px @gutter;
  background-color: white;

  &:hover {
    background-color: @gray-lighter;
  }
}

.cd-filter__operator {
  .radio-buttons-group {
    padding: 5px 10px;
  }
}

.cd-filter__comparison {
  margin: 0 auto;
  text-align: center;
  font-size: 10px;
  font-weight: bold;
  line-height: 20px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.4);
}
