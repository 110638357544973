/*------------------------------------*\
  #EXTERNAL-STYLESHEETS
\*------------------------------------*/

/*
 * Fonts
 */

@import 'fontawesome/less/fontawesome.less';
@import 'fontawesome/less/solid.less';
@import 'fontawesome/less/regular.less';
@import 'fontawesome/less/_shims.less';

/*
 * Bootstrap
 */

// Core variables and mixins.
@import 'bootstrap/less/variables.less';
@import 'bootstrap/less/mixins.less';

// Reset and dependencies
@import 'bootstrap/less/normalize.less';

// Core CSS
@import 'bootstrap/less/scaffolding.less';
@import 'bootstrap/less/type.less';
@import 'bootstrap/less/code.less';
@import 'bootstrap/less/grid.less';
@import 'bootstrap/less/tables.less';
@import 'bootstrap/less/forms.less';
@import 'bootstrap/less/buttons.less';

// Components
@import 'bootstrap/less/component-animations.less';
@import 'bootstrap/less/dropdowns.less';
@import 'bootstrap/less/button-groups.less';
@import 'bootstrap/less/input-groups.less';
@import 'bootstrap/less/navs.less';
@import 'bootstrap/less/breadcrumbs.less';
@import 'bootstrap/less/pagination.less';
@import 'bootstrap/less/labels.less';
@import 'bootstrap/less/badges.less';
@import 'bootstrap/less/thumbnails.less';
@import 'bootstrap/less/alerts.less';
@import 'bootstrap/less/progress-bars.less';
@import 'bootstrap/less/media.less';
@import 'bootstrap/less/list-group.less';
@import 'bootstrap/less/panels.less';
@import 'bootstrap/less/wells.less';
@import 'bootstrap/less/close.less';

// Components w/ JavaScript
@import 'bootstrap/less/modals.less';
@import 'bootstrap/less/tooltip.less';
@import 'bootstrap/less/popovers.less';

// Utility classes
@import 'bootstrap/less/utilities.less';
@import 'bootstrap/less/responsive-utilities.less';

// Stripe
@import 'stripe-payment/dist/css/main.css';

/*
 * 3rd party css
 */

// Animate.CSS
@import 'animate.css/animate.min.css';

// Full Calendar
@import 'fullcalendar/dist/fullcalendar.min.css';
@import 'fullcalendar/dist/fullcalendar.print.css' print;

// Redactor
@import 'churchdesk-redactor2/redactor.min.css';

// International Telephone Input
@import 'intl-tel-input/build/css/intlTelInput.css';

// Timepicker
@import 'timepicker/jquery.timepicker.min.css';

// Perfect Scrollbar
@import 'perfect-scrollbar/min/perfect-scrollbar.min.css';

// qTip
@import 'qtip2/dist/jquery.qtip.min.css';

// Select 2
@import 'select2/select2.css';

// Sweetalert
@import 'sweetalert/dist/sweetalert.css';

// Color picker
@import 'angularjs-color-picker/dist/angularjs-color-picker.min.css';
@import 'angularjs-color-picker/dist/themes/angularjs-color-picker-bootstrap.min.css';

// Hotkeys
@import 'angular-hotkeys/build/hotkeys.min.css';

// Toastr
@import 'angular-toastr/dist/angular-toastr.min.css';

// ngDialog
@import 'ng-dialog/css/ngDialog.min.css';
@import 'ng-dialog/css/ngDialog-theme-default.min.css';

// ngTagsInput
@import 'ng-tags-input/build/ng-tags-input.bootstrap.min.css';

// Formio
@import 'ng-formio/dist/formio.min.css';
@import 'ng-formio-builder/dist/ngFormBuilder.min.css';
@import 'formio-custom/dist/formio-custom.min.css';

// UI-select
@import 'ui-select/dist/select.min.css';
@import 'react-virtualized/styles.css';

// RC-Color-Picker
@import 'rc-color-picker/assets/index.css';

/*------------------------------------*\
  #GLOBAL-STYLESHEETS
\*------------------------------------*/

@import 'styles/_variables.less';
@import 'styles/_mixins.less';
@import 'styles/_helpers.less';
@import 'styles/_utilities.less';
@import 'styles/overwrites.less';
@import 'styles/print.less';
@import 'styles/main.less';
@import 'styles/statuspal.less';

/*------------------------------------*\
  #INTERNAL-MODULES
\*------------------------------------*/

@import 'calendar/calendar.less';
@import 'dashboard/dashboard.less';
@import 'forms/forms.less';
@import 'intranet/intranet.less';
@import 'people/people.less';
@import 'settings/settings.less';
@import 'shared/shared.less';
@import 'intentionen/intentionen.less';
@primary-color: #008DB6;@link-color: #008DB6;@success-color: #7ab800;@warning-color: #faad14;@error-color: #f5222d;@border-radius-base: 4px;@heading-1-size: ceil(@font-size-base * 1.71);@heading-2-size: ceil(@font-size-base * 1.42);@heading-3-size: ceil(@font-size-base * 1.14);@heading-4-size: ceil(@font-size-base * 1);@font-size-base: 14px;@page-header-heading-title: @heading-2-size;@btn-text-hover-bg: rgba(0, 0, 0, 0.12);@progress-default-color: #008DB6;