.select-facebook-page-modal {
  .facebook-pages {
    display: flex;
    flex-direction: column;
  }
  .facebook-page {
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px #ccc solid;
    border-radius: 4px;
    margin-bottom: 10px;

    transition: 0.2s background-color ease-in-out;
    background: #ffffff;

    .facebook-page__input {
      margin: 0px 5px;
    }

    .facebook-page__name {
      margin-left: 10px;
      font-weight: normal;
    }

    &:hover {
      cursor: pointer;
      background-color: #f3f3f3;
    }
  }
}
