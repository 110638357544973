.attendance-events {
  position: relative;

  .table {
    // Weird solution to make table cells behave correctly
    // when using `height: 100%` across all browsers
    height: 1px;
  }

  @media (min-width: @screen-lg-min) {
    .table {
      table-layout: fixed;

      thead > tr > th {
        overflow: hidden;
        text-overflow: ellipsis;

        &:nth-child(2) {
          width: 20%;
        }
      }
    }
  }

  @media (max-width: @screen-md-max) {
    .table > thead > tr > th:nth-child(2) {
      width: 30%;
    }
  }

  .attendance-cell {
    position: relative;
    height: 100%;
    padding: 0;

    > :only-child {
      display: flex;
      height: 100%;
      width: 100%;
    }

    .cd-loader-dimmer {
      border-radius: 0;
    }
  }

  .attendance-cell__wrapper {
    height: 100%;
    width: 100%;
  }

  .attendance-cell__select,
  .attendance-cell__number {
    display: block;
    height: 100%;
    width: 100%;
    margin: 0;
  }

  .attendance-cell__number {
    background-color: transparent;
    border-color: transparent;
    border-radius: 0;
    resize: none;
    overflow: hidden;
    padding: 24px 14px;

    &:focus {
      border-color: @brand-primary;
    }
    &.ng-invalid {
      border-color: @brand-danger;
    }
  }

  .attendance-cell__select {
    overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      width: 0;
      height: 0;
      right: 10px;
      bottom: 0;
      margin: auto;
      border-style: solid;
      border-width: 4px 4px 0 4px;
      border-color: @text-color transparent transparent transparent;
      pointer-events: none;
    }

    select {
      height: 100%;
      width: 100%;
      cursor: pointer;
      outline: none;
      padding-right: 24px;
      background-color: transparent;
      border-color: transparent;
      border-radius: 0;

      // Disable default styling on Firefox
      -moz-appearance: none;

      // Disable ugly ass outline on Firefox
      &:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 @text-color;
      }

      // Disable default styling on webkit browsers
      -webkit-appearance: none;

      // Disable default arrow on IE 11+
      &::-ms-expand {
        display: none;
      }

      // Disable default IE selection background color
      &:focus::-ms-value {
        color: inherit;
        background: transparent;
      }

      &:focus {
        border-color: @brand-primary;
      }
    }
  }
}
