.rotas-table-container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;

  #rotas-table-container__table,
  #rotas-table-container__table #rotas-table-container__data {
    display: flex;
    flex: 1;
  }

  #rotas-table-container__table #rotas-table-container__data cdr-rota-table {
    flex: 1;
  }

  .cd-loader-dimmer {
    opacity: 0.6;
  }
}
