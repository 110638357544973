@event-popup-max-width: 450px;
@event-popup-header-height: 100px;
@event-popup-body-height: 512px;
@event-popup-footer-height: 48px;
@event-popup-spacing-xs: 4px;
@event-popup-spacing-sm: 8px;
@event-popup-spacing-md: 12px;
@event-popup-spacing-lg: 16px;

.event-popups {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 15000;
  pointer-events: none;
}

.event-popup {
  max-width: @event-popup-max-width;
  pointer-events: auto;
  background-color: #fff;
  border: none;
  box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2);

  &.event-popup--full-screen {
    top: @gutter !important;
  }

  .qtip-content {
    padding: 0;
    border-radius: @border-radius-base;
    font-size: @font-size-base;
    line-height: @line-height-base;
    color: @text-color;
    overflow: visible;
  }

  .event-popup__loader {
    height: 10em;
  }

  .event-popup__content {
    display: flex;
    flex-direction: column;
  }

  .event-popup__header {
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    justify-content: space-between;
    min-height: @event-popup-header-height;
    padding: 0 @event-popup-spacing-lg @event-popup-spacing-lg;
    color: #fff;
    transition: background-color 0.2s ease-in-out;
  }

  .event-popup__form-group {
    display: flex;
  }

  .event-popup__form-label {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0px 10px 0px 0px;
  }
  .event-popup__form-field {
    flex: 3;
  }

  .event-popup__buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: @event-popup-spacing-sm;
  }

  .event-popup__button {
    color: #fff;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }

    i {
      margin: 0;
    }
  }

  .event-popup__title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: @font-size-large;
    line-height: @headings-line-height;
  }

  .event-popup__category {
    margin-top: @event-popup-spacing-xs;
  }

  .event-popup__body {
    flex: 1 1 auto;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding: @event-popup-spacing-md 0;
    max-height: ~'calc(100vh - @{event-popup-header-height} - @{event-popup-footer-height} - @{menuHeight})';

    a[href]:not(.btn),
    a[ui-sref]:not(.btn) {
      color: @text-color;

      &:hover {
        color: @link-color;
      }
    }
  }

  .event-popup__body__create {
    flex: 1 1 auto;
    position: relative;
    z-index: 2;
    overflow: visible;
    -webkit-overflow-scrolling: touch;
    padding: @event-popup-spacing-md 0;
    max-height: ~'calc(100vh - @{event-popup-header-height} - @{event-popup-footer-height} - @{menuHeight})';

    a[href]:not(.btn),
    a[ui-sref]:not(.btn) {
      color: @text-color;

      &:hover {
        color: @link-color;
      }
    }
  }

  .event-popup__footer {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
    justify-content: space-between;
    flex: 0 0 auto;
    min-height: @event-popup-footer-height;
    padding: @event-popup-spacing-md @event-popup-spacing-lg;
    border-top: 1px solid @input-border;
  }

  .event-data {
    padding: @event-popup-spacing-sm @event-popup-spacing-lg;
    transition: background-color 0.2s ease-in-out;
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-name: fadeIn;

    &.event-data--clickable:hover {
      cursor: pointer;
      background-color: #f0f0f0;
    }
  }

  .event-data__label {
    font-weight: bold;
  }

  /**
   * Create a calendar event
   */
  &.event-popup--create {
    .event-popup__header {
      height: auto;
      min-height: auto;
      padding: @event-popup-spacing-md @event-popup-spacing-lg;
      background-color: @brand-success;
    }

    .event-popup__buttons {
      margin-top: 0;
    }

    .event-popup__toggle {
      .btn {
        color: #fff;

        &.btn-active {
          color: @text-color;
          background-color: #fff;
        }

        &:not(.btn-active) {
          border: 1px solid rgba(255, 255, 255, 0.5);
        }
        &:not(.btn-active):hover {
          border-color: #fff;
        }
      }
    }

    .event-data {
      align-items: center;
    }

    .event-data__icon {
      margin-top: 0;
    }

    .form-group {
      margin-bottom: 0;
    }
  }
}
