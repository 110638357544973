.app-container__people-contacts {
  width: 100%;
  overflow: auto;

  /* Church selector for multi-church
    ============================= */
  .church-selector__wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 0 !important;
    padding: 0 17px 0 0;
    border-color: #ddd;
    border-width: 0 0 1px 0;
    background-color: #fff;
    border-radius: 0;

    .church-selector__label {
      margin-right: 15px;
      font-weight: bold;
      padding: 12px 17px;
      background-color: #eeeeee;
      color: #333;
      white-space: nowrap;
    }

    .church-selector__dropdown {
      height: 44px;
      display: flex;
      align-items: center;
      padding: 6px 10px 6px 0;
      border: none;

      div {
        display: flex;
        max-width: 100%;
        overflow-x: scroll;
        width: fit-content;

        .ui-select-match {
          display: flex;
          flex: 1;
          width: 100%;
          min-width: fit-content;

          [type='button'],
          .ui-select-match-item {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            padding: 5px 8px;
            border-radius: 20px;
            background-color: #008db7;
            border: none;
            color: #fff;
            -webkit-appearance: initial;
          }
        }
        input {
          margin: auto 0;
          width: auto;
        }
      }
      .ui-select-choices {
        .ui-select-choices-row {
          width: 100% !important;
          overflow-x: hidden;

          .ui-select-choices-row-inner {
            width: 100%;

            div {
              width: 100%;
              overflow-x: hidden;
            }
          }
        }
      }
    }

    .church-selector__reset-btn {
      flex: 1;
      text-align: end;
      white-space: nowrap;
    }
  }

  .people-table__container {
    display: flex;
    max-height: 100%;
    height: 100%;

    .people-list {
      position: relative;

      /* Layout
    ============================= */
      .app-main {
        display: flex;
        position: relative;
        overflow-y: hidden;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        padding-bottom: 0;
      }

      .cd-loader-dimmer.cd-loader-dimmer--page {
        z-index: 5;
      }

      /* Toolbar
      ============================= */
      .people-toolbar {
        margin: 0;
        padding: calc(@gutter / 2) @gutter;
        border: none;
        border-radius: 0;
        border-bottom: 1px solid #ddd;
        flex-wrap: wrap;
      }
      .people-list-status {
        flex-basis: 100%;
        background: #eee;
        border-bottom: 1px solid #ddd;
        padding: 5px @gutter;
      }

      /* Table
      ============================= */
      .table {
        .disabled {
          pointer-events: none;
          color: rgba(40, 40, 40, 0.3);
        }

        a {
          color: @text-color;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    /* Filters
    ============================= */
    .people-filters {
      box-shadow: 3px 0px 10px -2px #d4d4d5;

      .app-aside__main {
        padding: 0;
      }
    }

    /* Print mode
    ============================= */
    @media print {
      .people-list {
        .table thead tr th,
        .table tbody tr td {
          padding: 0.5em;
        }
      }
    }
  }
  &.multi-church .people-table__container {
    max-height: calc(100% - 44px);
    height: 100%;
  }
}
