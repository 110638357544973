/**
 * Bootstrap print styles
 */
@media print {
  *,
  *:before,
  *:after {
    background: transparent !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }

  .display-none-in-print {
    display: none !important;
  }

  .no-padding-or-margin-in-print {
    padding: 0 !important;
    margin: 0 !important;
  }

  .ant-popover {
    display: none !important;
  }

  a[href]:after {
    content: ' (' attr(href) ')';
  }

  abbr[title]:after {
    content: ' (' attr(title) ')';
  }

  // Don't show links that are fragment identifiers,
  // or use the `javascript:` pseudo protocol
  a[href^='#']:after,
  a[href^='javascript:']:after {
    content: '';
  }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group; // h5bp.com/t
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  // Bootstrap specific changes start

  // Bootstrap components
  .navbar {
    display: none;
  }
  .btn,
  .dropup > .btn {
    > .caret {
      border-top-color: #000 !important;
    }
  }
  .label {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;

    td,
    th {
      background-color: #fff !important;
    }
  }
  .table-bordered {
    th,
    td {
      border: 1px solid #ddd !important;
    }
  }
}

/**
 * Custom print styles
 */

@media print {
  * {
    -webkit-print-color-adjust: exact;
  }

  html,
  body {
    height: auto !important;

    a {
      color: black !important;
    }

    a[href]:after {
      content: '';
    }
  }

  body {
    overflow: visible;
    margin-left: 0;
    background-color: white !important;
  }

  body,
  .app,
  .app-view,
  .app-view > :only-child,
  .app-content,
  .app-body {
    display: block !important;
  }

  .app-nav,
  .app-menu,
  .action-links,
  .announcements {
    display: none !important;
  }

  .panel {
    margin-bottom: 0;

    .panel-body {
      padding: 0 !important;
      margin: 0 !important;
      background-color: white !important;
    }
  }

  .pager-header {
    background-color: white !important;

    .title {
      padding: 0;
      margin: 0 0 0 15px !important;
      font-weight: 700 !important;
    }
  }

  // Text colors
  .text-success,
  .text-success > span {
    color: @brand-success !important;
  }
  .text-warning,
  .text-warning > span {
    color: @brand-warning !important;
  }
  .text-info,
  .text-info > span {
    color: @brand-info !important;
  }
  .text-danger,
  .text-danger > span {
    color: @brand-danger !important;
  }
  .text-muted,
  .text-muted > span {
    color: @text-muted !important;
  }

  // Calendar
  #print-calendar {
    .fa {
      display: none !important;
    }
    .fa-circle {
      display: inline !important;
      font-size: 1em;
    }
    .panel-heading {
      display: none !important;
    }

    #print-calendar__records__table-wrapper {
      table {
        width: 100%;
        padding: 0;
        page-break-inside: auto;
        -fs-table-paginate: paginate;
        page-break-after: avoid;

        &:last-child {
          page-break-after: avoid;
        }

        tr {
          page-break-inside: avoid;
          page-break-after: auto;
          -webkit-region-break-inside: avoid;
          vertical-align: top;
          border-collapse: collapse;
        }

        th,
        td {
          padding: 8px 4px;
          font-size: 9px;
          border-collapse: collapse;

          span {
            font-size: 9px;
          }
        }
        .media {
          .media-object .fa {
            margin-right: 2px !important;
          }
          .media-left {
            padding-right: 0;
          }
        }
        th {
          border-bottom: 2px solid #ddd !important;
          height: inherit;
        }
      }
    }
  }

  .calendar {
    .btn-toolbar,
    #AddComments {
      display: none !important;
    }
    .pager-header,
    #page-title {
      margin: 0 !important;
    }
    .panel-heading .panel-title {
      padding: 15px 0;
    }

    .list-group-item {
      padding: 10px 0 !important;

      .fa-circle {
        font-size: 1em;
      }
      .media .media-left img {
        max-width: 30px !important;
      }
      .actions.pull-right {
        display: none;
      }
    }
  }

  #workplan-table {
    .selectable td:not(:first-child) {
      background-color: currentColor !important;
      border-width: 1px !important;
    }

    .info-text {
      display: none;
    }

    .btn-group {
      display: block;

      button.btn.btn-default.dropdown-toggle {
        padding: 0 0 20px 0;
        text-align: left;
        border: 0px;
        font-weight: 700;
        border-radius: 0px;

        .caret {
          display: none;
        }
      }
    }

    .help-text,
    .lockbutton {
      display: none;
    }

    .col-lg-2 {
      margin: 0 16px 570px 16px;
      width: auto;

      .worktype {
        padding: 0;
      }

      .worktype-list {
        padding: 5px 0;
        border-bottom: 1px solid #ccc;

        .worktypes-left-side {
          font-size: 12px;
        }

        .badge {
          font-size: 12px;
          font-weight: normal;
          color: white !important;
        }
      }
    }

    .col-lg-10 {
      clear: both;
      margin: 0 16px;
      width: auto;

      .col-lg-12.datepicker-navigator {
        padding: 0px;

        .btn-group > .btn:last-child:not(:first-child),
        .btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
          display: none !important;
        }

        .btn-group
          > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
          border-radius: 0;
          width: auto !important;
          border: 0px;
          font-weight: 700;
          padding: 0 0 10px 30px;
        }
      }
      .col-lg-12 {
        padding: 0 0 0 30px;
        width: 100%;

        thead .day {
          font-size: 10px;
          padding: 0;
        }

        thead .month {
          text-align: center;
          font-size: 8px;
          margin: 0 auto;
          display: block;
        }

        td {
          font-size: 10px;
          padding: 2px;
        }
      }
    }
  }
}
