.app-container__people-messages {
  display: flex;
  flex-direction: column;
  width: 100%;

  /* Church selector for multi-church
  ============================= */
  .church-selector__wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 0 !important;
    padding: 0 17px 0 0;
    border-color: #ddd;
    border-width: 0 0 1px 0;
    background-color: #fff;
    border-radius: 0;

    .church-selector__label {
      margin-right: 15px;
      font-weight: bold;
      padding: 12px 17px;
      background-color: #eeeeee;
      color: #333;
      white-space: nowrap;
    }

    .church-selector__dropdown {
      height: 44px;
      display: flex;
      align-items: center;
      padding: 6px 10px 6px 0;
      border: none;

      div {
        display: flex;
        max-width: 100%;
        overflow-x: scroll;
        width: fit-content;

        .ui-select-match {
          display: flex;
          flex: 1;
          width: 100%;
          min-width: fit-content;

          [type='button'],
          .ui-select-match-item {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            padding: 5px 8px;
            border-radius: 20px;
            background-color: #008db7;
            border: none;
            color: #fff;
            -webkit-appearance: initial;
          }
        }
        input {
          margin: auto 0;
          width: auto;
        }
      }
      .ui-select-choices {
        .ui-select-choices-row {
          width: 100% !important;
          overflow-x: hidden;

          .ui-select-choices-row-inner {
            width: 100%;

            div {
              width: 100%;
              overflow-x: hidden;
            }
          }
        }
      }
    }

    .church-selector__reset-btn {
      flex: 1;
      text-align: end;
      white-space: nowrap;
    }
  }

  .people-message-list {
    /* List of messages
  ============================= */
    .table {
      border-top: none;
      border-left: none;
      border-right: none;

      > tbody > tr:first-child > td {
        border-top: none;
      }
    }

    .message-data-cell__title {
      margin-top: 0;
      margin-bottom: 5px;
      color: inherit;
    }

    .message-data-cell__title-link {
      color: inherit;
    }

    .message-data-cell__author {
      i,
      img {
        margin: 0 5px;
      }
    }
  }
}
