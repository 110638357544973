#locationAccordion {
  fieldset.custom-fieldset {
    border-bottom: 1px solid lightgrey;
    margin-bottom: 25px;
    padding-bottom: 10px;
  }
}

.location--state-navigation a {
  padding: 5px 15px 5px 0 !important;
}

.form-required {
  color: @brand-danger;
}