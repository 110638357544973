.people-detail {
  padding: 0;

  .people-detail-banner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 10em;
    padding: 0 40px;
    background-color: #fff;
  }

  .people-detail-banner__user,
  .people-detail-banner__actions {
    display: flex;
    flex-direction: row;
  }

  .people-detail-banner__user {
    display: flex;
  }

  .people-detail-banner__user-picture {
    position: relative;

    .user-picture-overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 84px;
      width: 84px;
      opacity: 0;
      transition: 0.3s ease;
      background-color: rgba(0, 141, 182, 0.8);
      border-radius: 50%;

      i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
      }
    }

    a:hover .user-picture-overlay {
      opacity: 1;
    }
  }

  .people-detail-banner__user-details {
    display: flex;
    flex-direction: column;
    align-self: center;
  }

  .people-detail-banner__user-labels {
    display: flex;
    flex-direction: row;

    .label:not(:first-child) {
      margin-left: 5px;
    }

    .label {
      display: inline-block;
      padding: 6px 10px;
      font-size: 12px;
      font-weight: 400;
      margin-top: 5px;
    }
  }

  .people-detail-banner__actions {
    .btn {
      margin-left: 10px;
    }
  }

  .people-detail-tabs {
    .steps {
      margin: 0;
      border-left: 0;
      border-radius: 0;
    }
  }

  .people-detail-content {
    padding: 20px;
  }

  .people-detail-list-table {
    .table {
      border-collapse: separate;

      tr {
        height: 32px;

        td {
          padding: 5px 0;
          vertical-align: top;
        }

        // Nested table
        .table {
          margin-bottom: 10px;

          tr td:first-of-type {
            padding-left: 20px;
          }
        }
      }
    }

    &.people-detail-list-table--edit {
      .table td {
        padding: 4px 0;
      }
    }
  }

  // Fix formio datepicker input
  form[name='formioForm'] {
    .form-field-type-datefield,
    div[class*=' form-field-type-lifeEvent'] {
      .input-group {
        display: table;

        input {
          border-radius: 4px 0px 0 4px !important;
        }
      }
    }
  }

  // Fix ui-select input width
  .ui-select-multiple input.ui-select-search {
    width: 100% !important;
  }
}
