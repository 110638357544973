@import 'full-calendar/full-calendar.component.less';
@import 'event-detail/components/rotas/event-detail-rotas.component.less';
@import 'event-detail/components/event-detail-intentions/event-detail-intentions.component.less';
@import 'shared/components/event-popup/event-popup.component.less';
@import 'attendance/attendance.component.less';
@import 'absence-detail/absence-detail.component.less';

.event-popover-generic .ant-popover-inner-content {
  padding: 0;
  @media print {
    display: none !important;
  }
}

.event-popup__content {
  @media print {
    display: none !important;
  }
}

.show-print {
  display: none;
  @media print {
    display: block !important;
  }
}

@media print {
  .hidden-print {
    display: none !important;
  }

  .ant-btn .ant-dropdown-trigger {
    display: none !important;
  }
}

.show-print {
  display: none;
  @media print {
    display: block !important;
  }
}

@media print {
  .hidden-print {
    display: none !important;
  }

  .ant-btn .ant-dropdown-trigger {
    display: none !important;
  }
}

.fc-more-popover .event-popover-generic {
  position: fixed !important;
}
