#rotas {
  @media screen {
    display: flex;
  }

  @media print {
    display: inline;
  }


  #rotas__content {
    display: flex;
    flex: 1;
    flex-direction: column;

    #rotas__content-filters {
      padding-bottom: 0px !important;
      padding: 15px;
    }

    #rotas__content-table,
    #rotas__content-table cd-rotas-table {
      display: flex;
      flex: 1;
    }
  }
}

.empty-state__container {
  width: 100%;
}