.text-avatar {
  @size-base: 0.8rem;
  @size-count: 10;

  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;

  /*
    * Dynamically generate different size classes
    *
    * Base size is 0.8rem. Examples of generated classes:
    *
    * .text-avatar--2x -> 1.6rem
    * .text-avatar--6x -> 4.8rem
    */
  .u-size-classes (@i) when (@i >= 1) {
    &.text-avatar--@{i}x {
      @size: @size-base * @i;

      width: @size;
      height: @size;
      border-radius: (@size / 2);
      font-size: (@size / 2);
    }

    .u-size-classes(@i - 1);
  }

  .u-size-classes(@size-count);
}
