// Icon Sizes
// -------------------------

.larger(@factor) when (@factor > 0) {
  .larger((@factor - 1));

  .@{fa-css-prefix}-@{factor}x {
    font-size: (@factor * 1em);
  }
}

/* makes the font 33% larger relative to the icon container */
.@{fa-css-prefix}-lg {
  font-size: (4em / 3);
  line-height: (3em / 4);
  vertical-align: -0.0667em;
}

.@{fa-css-prefix}-xs {
  font-size: 0.75em;
}

.@{fa-css-prefix}-sm {
  font-size: 0.875em;
}

.larger(10);
