/*!
 * Font Awesome Pro 5.15.3 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@import '_variables.less';

@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 400;
  font-display: @fa-font-display;
  src: url('@{fa-font-path}/fa-regular-400.woff2') format('woff2');
}

.far {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}
