/* Mixins
   ============================= */

.smoothTransition() {
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}

.formInputMixin() {
  color: #333;
  padding: 0 0 6px;
  border-radius: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.18);
  .smoothTransition();
}

.formInputHoverMixin() {
  border-color: #333;
}

.formInputFocusMixin() {
  border-color: #008db6;
  outline: 0;
}

/* Form Builder
   ============================= */

.form-builder {
  position: relative;

  .formbuilder {
    .formcomponents {
      @_offset: 68px; // The height of the steps element

      padding-top: @_offset;
    }

    .form-control.ng-invalid {
      border: 1px solid #ccc;
    }

    .alert {
      margin-bottom: 0;
    }
  }

  .form-information {
    margin-bottom: 24px;

    .form-title-input {
      margin: 0 12px;

      input {
        font-size: 22px;
        font-weight: 700;
        .formInputMixin();

        &:hover {
          .formInputHoverMixin();
        }
        &:focus {
          .formInputFocusMixin();
        }

        &.ng-touched.ng-invalid {
          @color: #e74c3c;

          border-color: @color;

          &::-webkit-input-placeholder {
            color: lighten(@color, 20%);
          }
          &::-moz-placeholder {
            color: lighten(@color, 20%);
          }
          &:-ms-input-placeholder {
            color: lighten(@color, 20%);
          }
          &:-moz-placeholder {
            color: lighten(@color, 20%);
          }
        }
      }
    }

    .form-description-input {
      margin: 15px 12px 0;
    }

    // Church selector
    .form-church-input {
      margin: 15px 12px 0;

      .ui-select-multiple.dropdown.ng-empty,
      .ui-select-multiple.dropdown.ng-invalid {
        @color: #e74c3c;

        border-color: @color;

        &::-webkit-input-placeholder,
        &::-moz-placeholder,
        &:-ms-input-placeholder,
        &:-moz-placeholder {
          color: lighten(@color, 20%);
        }
      }
    }
  }

  .form-settings {
    .panel.panel-default {
      padding: 2rem;
    }

    .panel-heading {
      display: flex;

      i {
        align-self: center;
        margin-right: 5px;
      }

      .panel-title {
        display: flex;
        flex-direction: column;

        .setting-title {
          font-size: 1.15em;
        }

        .setting-description {
          font-weight: 400;
          font-size: 0.9em;
          color: #262626;
          margin-top: 0.25em;
        }
      }
    }

    .panel-body {
      .ui-select-toggle[disabled] {
        opacity: 1;
        border-color: #cecece;
        background-color: #eeeeee;
        color: #999a99;
      }

      .setting-description {
        font-weight: 400;
        font-size: 0.9em;
        color: @text-muted;
        margin-top: 0.25em;
      }
    }

    .form-checkbox {
      display: flex;
      align-items: flex-start;

      .form-checkbox__input {
        margin-top: 0.7em;
      }
      .form-checkbox__icon {
        font-size: 1.3em;
        padding: 0.5em;
        margin: 0em 0.8em 0 0.4em;
        background-color: #eee;
      }
      .form-checkbox__label {
        display: flex;
        flex-direction: column;
      }
      .form-checkbox__label {
        font-weight: bold;
      }
      .form-checkbox__description {
        color: @text-muted;
      }
    }

    .panel--disabled {
      opacity: 0.7;

      .panel-heading,
      .panel-body {
        background-color: #eee;
      }
    }
  }

  &.app-content {
    flex-direction: column;
  }

  .app-header .steps {
    margin: 0;
    border: none;
    border-radius: 0;
    box-shadow: 0 3px 10px -2px #d4d4d5;
  }

  &.form-builder__builder .app-main {
    padding-left: calc(@asideWidth + @gutter);
  }
}

.form-field-type-container > .panel > .panel-heading {
  background-color: #eee;
}
.form-field-type-person > .panel > .panel-heading {
  background-color: #eee;
}
