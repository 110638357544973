.create-form-modal {
  .modal-body {
    padding: 0;
  }

  .form-templates-list {
    flex: 1;
    padding: 1.5em;
    border-right: 1px solid #e5e5e5;
  }

  .form-template {
    color: #000;
    padding: 12px;
    display: flex;
    align-items: center;
    margin-bottom: 1.2em;
    border-radius: 2px;

    box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;

    &:hover {
      box-shadow: 0 1px 3px 1px #bbb, 0 0 0 1px #bbb;
    }

    &.form-template--blank {
      box-shadow: none;
      border: 2px dashed #d4d4d5;

      &:hover {
        border-color: transparent;
        box-shadow: 0 1px 3px 1px #bbb, 0 0 0 1px #bbb;
      }
    }

    &.form-template--active {
      box-shadow: 0 1px 3px 1px rgba(54, 125, 155, 0.81),
        0 0 0 1px rgba(54, 125, 155, 0.77);
    }
  }

  .form-template__header {
    margin: 1.2em 0 0.8em 0;
  }

  .form-template__icon {
    margin-right: 10px;
    color: #555;
  }

  .form-template__title {
    font-size: 15px;
    flex: 1;
  }

  .form-template-preview {
    position: relative;
    flex: 2;
  }

  .form-template-preview__container {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .form-template-preview__header {
    border-bottom: 1px solid #e5e5e5;
  }

  .form-template-preview__use-template {
    padding: 1.5em 1.5em 24px 1.5em;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .form-template-preview__title {
    margin: 0;
  }

  .form-template-preview__custom-fields {
    padding: 1.5em;
    border-bottom: 1px solid #e5e5e5;

    .alert {
      margin-bottom: 0;
    }
  }

  .form-template-preview__iframe-container {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 1.5em 1.5em 0;
  }

  .form-template-preview__iframe-label {
    margin: 0 0 1em;
  }

  .form-template-preview__iframe {
    min-height: 300px;
    border: none;
    flex: 1;
    width: 100%;
  }

  .form-template-preview__church-selector {
    display: block;
    padding: 0 1.5em 24px 1.5em;

    .help-block {
      margin-bottom: 0px;
    }

    .ui-select-multiple.dropdown.ng-empty,
    .ui-select-multiple.dropdown.ng-invalid {
      @color: #e74c3c;

      border-color: @color;

      &::-webkit-input-placeholder,
      &::-moz-placeholder,
      &:-ms-input-placeholder,
      &:-moz-placeholder {
        color: lighten(@color, 20%);
      }
    }
  }
}
