/*------------------------------------*\
  #TEXT
\*------------------------------------*/

/**
 * Positioning
 */

.u-block {
  display: block;

  &-i {
    display: block !important;
  }
}

.u-inline {
  display: inline;

  &-i {
    display: inline !important;
  }
}

.u-inline-block {
  display: inline-block;

  &-i {
    display: inline-block !important;
  }
}

.u-width-full {
  width: 100%;
}

.u-height-full {
  height: 100%;
}

.u-relative {
  position: relative;
}

/**
 * Alignment
 */

.u-text-left {
  text-align: left;

  &-i {
    text-align: left !important;
  }
}

.u-text-right {
  text-align: right;

  &-i {
    text-align: right !important;
  }
}

.u-text-center {
  text-align: center;

  &-i {
    text-align: center !important;
  }
}

.u-text-justify {
  text-align: justify;

  &-i {
    text-align: justify !important;
  }
}

.u-text-nowrap {
  white-space: nowrap;
}

.u-text-wrap {
  white-space: normal;
}

.u-text-break-all {
  word-break: break-all;
}

.u-text-break-word {
  word-break: break-word;
}

/**
 * Style and Weight
 */

.u-text-light {
  font-weight: 400;
}

.u-text-bold {
  font-weight: 700;
}

.u-text-italic {
  font-style: italic;
}

/**
 * Decoration
 */

.u-text-underline {
  text-decoration: underline;
}

.u-text-line-through {
  text-decoration: line-through;
}

/**
 * Transform
 */

.u-text-cap {
  text-transform: capitalize;
}

.u-text-upper {
  text-transform: uppercase;
}

.u-text-lower {
  text-transform: lowercase;
}

/**
 * Color variations
 */

.u-text-muted {
  color: @text-muted;

  &-i {
    color: @text-muted !important;
  }
}

.u-text-default {
  color: @text-color;

  &-i {
    color: @text-color !important;
  }
}

.u-text-primary {
  color: @brand-primary;

  &-i {
    color: @brand-primary !important;
  }
}

.u-text-success {
  color: @brand-success;

  &-i {
    color: @brand-success !important;
  }
}

.u-text-warning {
  color: @brand-warning;

  &-i {
    color: @brand-warning !important;
  }
}

.u-text-danger {
  color: @brand-danger;

  &-i {
    color: @brand-danger !important;
  }
}

.u-text-unassigned-shift {
  color: #c7254e;
}

.u-text-disabled {
  color: rgba(40, 40, 40, 0.3);
}

/*------------------------------------*\
  #FLEXBOX
\*------------------------------------*/

/**
 * Flex behavior
 */

.u-flex {
  display: flex;
}

.u-flex-inline {
  display: inline-flex;
}

/**
 * Direction
 */

.u-flex-row {
  flex-direction: row;
}

.u-flex-row-r {
  flex-direction: row-reverse;
}

.u-flex-column {
  flex-direction: column;
}

.u-flex-column-r {
  flex-direction: column-reverse;
}

/**
 * Align items
 */

.u-align-items-start {
  align-items: flex-start;
}

.u-align-items-end {
  align-items: flex-end;
}

.u-align-items-center {
  align-items: center;
}

.u-align-items-baseline {
  align-items: baseline;
}

.u-align-items-stretch {
  align-items: stretch;
}

/**
 * Align self
 */

.u-align-self-start {
  align-self: flex-start;
}

.u-align-self-end {
  align-self: flex-end;
}

.u-align-self-center {
  align-self: center;
}

.u-align-self-baseline {
  align-self: baseline;
}

.u-align-self-stretch {
  align-self: stretch;
}

/**
 * Align content
 */

.u-align-content-start {
  align-content: flex-start;
}

.u-align-content-end {
  align-content: flex-end;
}

.u-align-content-center {
  align-content: center;
}

.u-align-content-around {
  align-content: around;
}

.u-align-content-stretch {
  align-content: stretch;
}

/**
 * Justify content
 */

.u-justify-content-start {
  justify-content: flex-start;
}

.u-justify-content-end {
  justify-content: flex-end;
}

.u-justify-content-center {
  justify-content: center;
}

.u-justify-content-between {
  justify-content: space-between;
}

.u-justify-content-around {
  justify-content: space-around;
}

/**
 * Wrap
 */

.u-flex-wrap {
  flex-wrap: wrap;
}

.u-flex-wrap-r {
  flex-wrap: wrap-reverse;
}

.u-flex-nowrap {
  flex-wrap: nowrap;
}

/**
 * Grow & shrink
 */

.u-flex-grow {
  flex-grow: 1;
}

.u-flex-shrink {
  flex-shrink: 1;
}

.u-flex-auto {
  flex: 1;
}

/**
 * Truncate
 */

.u-flex-text-truncate {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
}

.u-max-lines-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.u-max-lines-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/*------------------------------------*\
  #MARGINS-PADDINGS
\*------------------------------------*/

@u-step-size: 5;
@u-margin-padding-max: 50;

/*
 * Dynamically generate `margin` and `padding` utility classes
 *
 * Examples of generated classes:
 *
 * .u-m-20 -> { margin: 20px }
 * .u-ml-20 -> { margin-left: 20px }
 * .u-mx-20 -> { margin-left: 20px; margin-right: 20px }
 *
 * Adding the `i` suffix to the class will make it `!important`:
 *
 * .u-m-20i -> { margin: 20px !important }
 */
.u-margin-padding-classes (@i, @size, @prefix, @property) when (@i > 0) {
  // All
  .u-@{prefix}-@{size} {
    @{property}: ~'@{size}px';

    &i {
      @{property}: ~'@{size}px' !important;
    }
  }

  // Top
  .u-@{prefix}t-@{size} {
    @{property}-top: ~'@{size}px';

    &i {
      @{property}-top: ~'@{size}px' !important;
    }
  }

  // Right
  .u-@{prefix}r-@{size} {
    @{property}-right: ~'@{size}px';

    &i {
      @{property}-right: ~'@{size}px' !important;
    }
  }

  // Bottom
  .u-@{prefix}b-@{size} {
    @{property}-bottom: ~'@{size}px';

    &i {
      @{property}-bottom: ~'@{size}px' !important;
    }
  }

  // Left
  .u-@{prefix}l-@{size} {
    @{property}-left: ~'@{size}px';

    &i {
      @{property}-left: ~'@{size}px' !important;
    }
  }

  // Horizontal
  .u-@{prefix}x-@{size} {
    @{property}-right: ~'@{size}px';
    @{property}-left: ~'@{size}px';

    &i {
      @{property}-right: ~'@{size}px' !important;
      @{property}-left: ~'@{size}px' !important;
    }
  }

  // Vertical
  .u-@{prefix}y-@{size} {
    @{property}-top: ~'@{size}px';
    @{property}-bottom: ~'@{size}px';

    &i {
      @{property}-top: ~'@{size}px' !important;
      @{property}-bottom: ~'@{size}px' !important;
    }
  }

  .u-margin-padding-classes(@i - 1, @i * @u-step-size - @u-step-size, @prefix, @property);
}

.u-margin-padding-classes((@u-margin-padding-max / @u-step-size) + 1, 0, m, margin);
.u-margin-padding-classes((@u-margin-padding-max / @u-step-size) + 1, 0, p, padding);

.u-gutter {
  padding: @gutter;
}
.u-gutter-x {
  padding-left: @gutter;
  padding-right: @gutter;
}
.u-gutter-y {
  padding-top: @gutter;
  padding-bottom: @gutter;
}

/*------------------------------------*\
  #BORDERS
\*------------------------------------*/

.u-border-n {
  border: none;

  &i {
    border: none !important;
  }
}

.u-border-radius-n {
  border-radius: 0;

  &i {
    border-radius: 0 !important;
  }
}

/*------------------------------------*\
  #LAYOUT
\*------------------------------------*/

.u-columns-responsive {
  column-count: 1;

  @media (min-width: 800px) {
    column-count: 2;
  }
  @media (min-width: 1200px) {
    column-count: 3;
  }
}

.u-columns-no-gap {
  column-gap: 0;
}

.u-width-full {
  width: 100%;
}

.u-width-full-important {
  width: 100% !important;
}

.u-width-auto {
  width: auto;
}

/**
 * Middle Align Content
 */
.u-middle-align-content {
  .middle-align-content();
}

/*------------------------------------*\
  #SCROLLING
\*------------------------------------*/

.u-scroll-n {
  overflow: hidden;

  &i {
    overflow: hidden !important;
  }
}

.u-scroll {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.u-scroll-x,
.u-scroll-y {
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}

.u-scroll-y {
  overflow-y: auto;
}

.u-scroll-x {
  overflow-x: auto;
}

/*------------------------------------*\
  #CALENDAR-COLORS
\*------------------------------------*/

@u-total-colors: 23;

/*
 * Dynamically generate calendar color utility classes.
 *
 * Examples of generated classes:
 *
 * .u-background-color-0 -> { background-color: #22A7F0 }
 * .u-color-0 -> { color: #22A7F0 }
 *
 * Adding the `i` suffix to the class will make it `!important`:
 *
 * .u-background-color-0i -> { background-color: #22A7F0 !important }
 */
.u-calendar-colors-classes (@i, @property) when (@i >= 0) {
  .u-@{property}-@{i} {
    @{property}: ~'@{color-@{i}}';

    &i {
      @{property}: ~'@{color-@{i}}' !important;
    }
  }

  .u-calendar-colors-classes(@i - 1, @property);
}

// Background color
.u-calendar-colors-classes(@u-total-colors, background-color);

// Text color
.u-calendar-colors-classes(@u-total-colors, color);

/*------------------------------------*\
  #FORMS
\*------------------------------------*/

.form-control--disabled {
  background-color: #eee;
}

.u-table-row-child {
  border-bottom: none !important;
  border-top: none !important;
  td {
    border-bottom: none !important;
    border-top: none !important;
  }
}
