.payment-summary {
  margin: 1.5rem;

  &.payment-summary--compact {
    width: 54%;
  }

  .panel-heading {
    background-color: #eee;
  }

  table {
    width: 100%;

    tr {
      border-bottom: 1px solid #efefef;

      &.row-bold {
        font-weight: bold;
      }
      &.row-italic {
        font-style: italic;
      }

      &.row-error {
        font-style: italic;
        background-color: #f6dddd;
        color: @brand-danger;
      }

      &.row-warning {
        font-style: italic;
        background-color: #fef8e1;
        color: @brand-warning;
      }

      &.no-border {
        border-bottom: none;
      }

      td {
        padding: 10px;

        &:nth-child(1) {
          width: 70%;
        }
        &:nth-child(2) {
          width: 30%;
          text-align: right;
        }
      }
    }
  }
}
