.page-statistics {
  display: flex;
  position: relative;
  margin: 0;

  > cd-page-statistic {
    flex: 1;
  }

  .page-statistics__statistic {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    background-color: #fff;
    padding: 1rem;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 4px;
    color: inherit;
  }

  .page-statistics__value {
    font-size: 2.75rem;
    font-weight: 700;
    line-height: 1em;
  }

  .page-statistics__value--red {
    color: @brand-danger;
  }
  .page-statistics__value--blue {
    color: @brand-primary;
  }
  .page-statistics__value--pink {
    color: #e03997;
  }
  .page-statistics__value--green {
    color: @brand-success;
  }
  .page-statistics__value--orange {
    color: @brand-warning;
  }
  .page-statistics__value--gray {
    color: #767676;
  }

  .page-statistics__text {
    margin-top: 0.25em;
    text-transform: uppercase;
  }

  .page-statistics__statistic--interactive:hover {
    background-color: #f9f9f9;
  }

  .page-statistics__statistic--active {
    background-color: #f0f0f0;
  }
}

@media (max-width: @screen-xs-max) {
  .page-statistics {
    flex-direction: column;

    > cd-page-statistic {
      &:first-child .page-statistics__statistic {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:last-child .page-statistics__statistic {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }

      &:not(:last-child) .page-statistics__statistic {
        border-bottom: 0;
      }

      &:not(:first-child):not(:last-child) .page-statistics__statistic {
        border-radius: 0;
      }
    }
  }
}

@media (min-width: @screen-sm-min) {
  .page-statistics {
    > cd-page-statistic {
      padding: 0;

      &:not(:first-of-type) .page-statistics__statistic {
        border-left: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      &:not(:last-of-type) .page-statistics__statistic {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
}
