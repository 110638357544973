.announcements {
  flex-direction: column;
  z-index: @announcementPosition;
}

.announcement {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  min-height: @announcementHeight;
  padding: 8px;

  a {
    color: white;
    text-decoration: underline;
    &:hover {
      color: white;
    }
  }

  .announcement__message {
    flex: 1;
    text-align: center;
    color: white;
  }

  .announcement__dismiss {
    padding: 0 1em;

    a {
      opacity: 0.8;
      &:hover {
        opacity: 1;
      }
    }
  }

  &.announcement--success {
    background-color: @brand-success;
  }

  &.announcement--warning {
    background-color: @brand-warning;
  }

  &.announcement--error {
    background-color: @brand-danger;
  }

  &.announcement--info,
  &.announcement--notice {
    background-color: @brand-info;
  }
}
