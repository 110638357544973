.people-custom-fields {
  position: relative;

  .app-header .steps {
    margin: 0;
    border: none;
    border-radius: 0;
    box-shadow: 0 3px 10px -2px #d4d4d5;
  }

  .people-fields__custom {
    padding-left: calc(@asideWidth + @gutter);
  }

  .formbuilder {
    .component-type-button {
      display: none;
    }

    .formcomponents {
      @_offset: 68px; // The height of the steps element

      padding-top: @_offset;
    }
  }

  .people-fields-tbody {
    border-top: 1px solid #ddd;
  }

  .people-fields-tr__section {
    background: #eee;
    font-size: 12px;
    font-weight: bold;
  }

  .people-fields-tr__toggle {
    cursor: pointer;

    &:hover {
      background: #f5f5f5;
    }
  }

  .people-fields-tr--disabled {
    background: rgba(238, 238, 238, 0.4);
    cursor: not-allowed;

    .people-fields-tr__toggle {
      opacity: 0.4;

      &:hover {
        cursor: not-allowed;
      }
    }
  }
}
