.sp-banner {
  font-size: 16px !important;
  .sp-banner-close {
    top: 0.5em !important;
  }
}

.sp-banner--scheduled {
  background-color: #008db6 !important;
}
