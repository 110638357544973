.form-viewer-edit {
  // fix the layout of datepicker components
  .form-field-type-datefield,
  .form-field-type-peopleBirthday {
    .input-group {
      display: table !important;

      .form-control {
        border-radius: 4px 0px 0px 4px !important;
      }
    }
  }
}
