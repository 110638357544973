@step-bg: @gray-lighter;

.stepTransition() {
  transition: background-color 0.1s ease, opacity 0.1s ease, color 0.1s ease,
    box-shadow 0.1s ease;
}

.steps {
  display: flex;
  margin: 1em 0;
  background: 0 0;
  box-shadow: none;
  line-height: 1.15em;
  border-radius: 4px;
  border: 1px solid rgba(34, 36, 38, 0.15);

  .step {
    display: flex;
    flex: 1 1 auto;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0em 0em;
    padding: 1.15em 2em;
    background: @step-bg;
    color: rgba(0, 0, 0, 0.87);
    box-shadow: none;
    border-radius: 0em;
    border: none;
    border-right: 1px solid rgba(34, 36, 38, 0.15);
    .stepTransition();

    &.step--fixed {
      flex: 0 1 auto;
      flex-wrap: nowrap;

      .btn {
        margin: 0 5px;
      }
    }

    &.step--small {
      font-size: 0.92857143rem;
    }

    &:first-child {
      border-top-left-radius: inherit;
      border-bottom-left-radius: inherit;
    }

    &:last-child {
      border-right: none;
      border-top-right-radius: inherit;
      border-bottom-right-radius: inherit;
    }

    &:not(:last-child):after {
      display: block;
      position: absolute;
      z-index: 2;
      content: '';
      top: 50%;
      right: 0%;
      border: medium none;
      width: 1.14285714em;
      height: 1.14285714em;
      background: @step-bg;
      border-style: solid;
      border-color: rgba(34, 36, 38, 0.15);
      border-width: 0px 1px 1px 0px;
      transform: translateY(-50%) translateX(50%) rotate(-45deg);
      .stepTransition();
    }

    &.step--disabled {
      cursor: auto;
      background: @step-bg;
      pointer-events: none;
      color: rgba(40, 40, 40, 0.3);

      .step__title,
      .step__description {
        color: rgba(40, 40, 40, 0.3);
      }
    }

    &.step--active {
      cursor: auto;
      background: white;
      border-bottom-color: white;

      &:after {
        background: white;
      }
    }

    &.step--without-arrows:after {
      display: none;
    }
  }

  .step__title {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji' !important;
    font-size: 1.14285714em;
    font-weight: bold;
    width: 100%;
  }

  .step__description {
    font-weight: normal;
    font-size: 0.92857143em;
    color: rgba(0, 0, 0, 0.87);
    width: 100%;
    margin-top: 0.25em;
  }

  .step__counter {
    display: none;
    position: static;
    text-align: center;
    align-self: middle;
    margin-right: 1rem;
    font-size: 2.5em;
    font-family: inherit;
    font-weight: bold;
  }

  a.step,
  .step--link {
    cursor: pointer;
  }

  &.steps--sticky {
    .sticky(@menuHeight, @navWidth, @navWidth);

    .step {
      border-bottom: none;
    }
  }
}
