/*
 * Mixins
 */
.accordion-theme(@background-color; @border-color; @text-color) {
  .cd-accordion__header {
    background-color: @background-color;
    border-color: @border-color;
    color: @text-color;

    &:hover,
    &:focus,
    &:active {
      background-color: darken(@background-color, 4%);
      border-color: darken(@border-color, 4%);
      color: @text-color;
    }
  }

  .cd-accordion__body {
    border-color: @border-color;
  }
}

/*
 * General styles
 */
.cd-accordion {
  .cd-accordion__header {
    cursor: pointer;
    border-style: solid;
    border-width: 1px;
    border-radius: @border-radius-base;
    transition-property: background-color, border-color;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
  }

  .cd-accordion__body {
    padding: calc(@gutter / 2);
    border-style: solid;
    border-width: 0 1px 1px 1px;
    border-bottom-left-radius: @border-radius-base;
    border-bottom-right-radius: @border-radius-base;
  }

  // States
  &.cd-accordion--open&:not(.cd-accordion--borderless) {
    .cd-accordion__header {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  &.cd-accordion--disabled {
    pointer-events: none;
  }
  &.cd-accordion--borderless {
    .cd-accordion__header,
    .cd-accordion__body {
      border: none;
    }

    .cd-accordion__body {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  // Themes
  &.cd-accordion--default {
    .accordion-theme(white; @btn-default-border; @text-color);
  }
  &.cd-accordion--primary {
    .accordion-theme(@brand-primary; @brand-primary;white);
  }
  &.cd-accordion--warning {
    .accordion-theme(@brand-warning; @brand-warning; white);
  }
  &.cd-accordion--danger {
    .accordion-theme(@brand-danger; @brand-danger; white);
  }

  // Hide accordion styles to make the accordion blend into a form
  &.cd-accordion--hide-accordion {
    .cd-accordion__header {
      display: none !important;
    }
    .cd-accordion__body {
      border-width: 1px 1px 1px 1px !important;
      border-radius: 4px;
    }
  }
}
