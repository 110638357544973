.flex-alert .alert {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  padding: 10px;

  i {
    margin: 0 0.5em;
  }
}
