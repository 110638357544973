#files .toolbar {
  position: relative;
  border: 1px solid #d4d4d5;
  border-bottom: none;
  background: #fff;
  padding: 1em;
  border-radius: 0.3em 0.3em 0 0;

  @media (max-width: 768px) {
    button {
      i.fa {
        margin: 0;
      }
      .text {
        display: none;
      }
    }
  }

  .breadcrumbs {
    ol.breadcrumb {
      background: none;
      padding: 0;
      border-radius: 0;

      > li {
        padding-top: 10px;
      }
    }
  }
}

#showmore {
  text-align: center;
  vertical-align: middle;
}

.nav_remove_margin_bottom ul {
  margin-bottom: 0;
}

a {
  cursor: pointer;
}

.clear {
  clear: both;
}

.file_type_icon {
  float: left;
  width: 28px;
  height: 28px;
  display: block;
  overflow: hidden;
  background-color: transparent;
  vertical-align: bottom;
  border: 1px solid transparent;
  cursor: pointer;
  border-radius: 4px;
}

.file_type_drag_helper_icon {
  float: left;
  width: 24px;
  height: 28px;
  display: block;
  overflow: hidden;
  background-color: #f4efe8;
  vertical-align: bottom;
  border: 1px solid #b7b7b7;
  margin-right: -10px;
  cursor: pointer;
}

.hidden {
  display: none;
}

#bw_file_upload_div_hidden {
  display: none;
}

.file-table-wrapper {
  background-color: #333;
  width: 166px;
  height: 166px;
  display: inline-block;
  float: left;
  margin: 0;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border: 0 solid #cccccc;
}

.file-table-wrapper:hover {
  color: #7ab800;
  background-color: #333;
  outline: 0;
}

.file-table-wrapper:hover .file-control-wrapper,
.file-table-wrapper:hover .file-image-title-wrapper {
  display: inline-block;
}

.file-table-wrapper.draggable {
  background-color: #008db6;
  outline: 0;
}

.file-table-wrapper.draggable .file-image-overlay {
  background-color: rgba(0, 0, 0, 0.6);
  width: 167px;
  height: 167px;
  position: absolute;
}

.file-table-wrapper.draggable .file-image-overlay i {
  color: #7ab800;
  width: 167px;
  position: absolute;
  font-size: 40px;
  margin-top: 75px;
  text-align: center;
  display: block;
}

.file-image-overlay i {
  display: none;
}

.file-table-wrapper.draggable .file-image-title-wrapper,
.file-table-wrapper.draggable .file-control-wrapper {
  display: inline-block;
}

.errorDrop {
  background-color: rgba(231, 76, 60, 0.2);
}

.activeDrop {
  background-color: rgba(122, 184, 0, 0.2);
}

#form_folder_create input,
#form_folder_create select {
  margin: 5px 5px;
}

.displayError div.alert {
  margin-bottom: 0;
}

.formError {
  border-color: #ff0000;
}

.check_all_files {
  margin-right: 5px;
  margin-left: 5px;
  float: left;
  display: inline-block;
  min-width: 12px;
  min-height: 1px;
}

.kw_files_edit_filename,
.kw_files_edit_visibility,
.kw_files_edit_group {
  background-color: inherit !important;
}

.kw_files_action_button {
  margin-right: 10px;
}

.btn-create-folder {
  padding: 1px 10px !important;
}

.icon-stack-create-folder {
  line-height: 2em !important;
}

.icon-create-folder {
  color: #5bb75b;
}

.folder_name {
  width: 26%;
  margin-left: 5px;
}

.folder_visibility {
  width: 26%;
}

.folder_group {
  width: 15%;
}

#file_folder_search_box input {
  width: 150px;
}

.ui-accordion-content {
  height: 300px;
}

#files {
  position: relative;
}

#files .action-header {
  display: block;
  width: 100%;
  overflow: hidden;
}

#files .action-header .actions {
  display: inline-block;
  position: relative;
  margin-bottom: 15px;
}

#files .action-header .search {
  width: 35%;
}

@media (max-width: 1210px) {
  #files .action-header .search {
    width: 35%;
    margin-bottom: 15px;
  }
}

#filepicker-dialog #files .action-header .search {
  width: 185px;
}

@media (max-width: 1210px) {
  #filepicker-dialog #files .action-header .search {
    width: 185px;
    margin-bottom: 15px;
  }
}

#files .browse-files-wrapper,
.filearchive-upl-modal .browse-files-wrapper {
  position: relative;
  clear: both;
}

#files .browse-files-wrapper .draggable,
.filearchive-upl-modal .browse-files-wrapper .draggable {
  background-color: rgba(0, 141, 182, 0.2);
}

#files .browse-files-wrapper .itemselected,
.filearchive-upl-modal .browse-files-wrapper .itemselected {
  background-color: rgba(0, 141, 182, 0.2);
}

.filearchive-upl-modal .browse-files-wrapper .browse-files-scroll {
  max-height: 500px;
  overflow: hidden;
  position: relative;
}

#files .browse-files-wrapper .browse-files-scroll {
  overflow: hidden;
  position: relative;
}

#files #browse-files,
.filearchive-upl-modal #browse-files {
  background: #ffffff;
  width: 100%;
  border: 1px solid #ddd;
}

#files #browse-files .browse-header {
  background-color: #f5f5f5;
}

#files #browse-files .browse-header th > a > i {
  margin-left: 8px;
}

#files #browse-files .browse-header .check-header,
#files #browse-files .check-col {
  width: 3%;
  text-align: center;
}

#files #browse-files .browse-header .filename-header,
#files #browse-files .filename-col {
  width: 30%;
}

#files #browse-files .browse-header .type-header,
#files #browse-files .type-col {
  width: 5%;
}

#files #browse-files .browse-header .tags-header,
#files #browse-files .tags-col {
  width: 1%;
}

#files #browse-files .upby-col,
#files #browse-files .browse-header .upby-header {
  width: 10%;
}

#files #browse-files .group-col,
#files #browse-files .browse-header .group-header {
  width: 10%;
}

#files #browse-files .filesize-col,
#files #browse-files .browse-header .filesize-header {
  width: 7%;
}

#files #browse-files .modif-col,
#files #browse-files .browse-header .modif-header {
  width: 12%;
}

#files #browse-files .actions-header,
#files #browse-files .actions-col {
  width: 8%;
  text-align: center;
}

#files .table-hover > tbody > tr:hover > td,
.table-hover > tbody > tr:hover > th {
  background-color: rgba(0, 141, 182, 0.1);
}

#files #browse-files .browse-file .check-col,
#files #browse-files .browse-file .actions-col {
  text-align: center;
}

#files .modal-body .actions-file-button {
  display: inline-block;
}

#files .file-tags .label,
.folder-image-group.label {
  cursor: pointer;
  border-radius: 0.25em;
  width: auto;
  height: 20px;
  display: inline-block;
  min-width: 10px;
  padding: 4px 6px;
  color: #fff;
  background-color: #999999;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  font-size: 12px;
  opacity: 1;
  margin-right: 5px;
  -moz-transition: all 0.25s ease-out;
  -webkit-transition: all 0.25s ease-out;
  -o-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
}

#files .file-tags .label:hover {
  opacity: 0.6;
}

#filepicker-dialog .browse-files-wrapper .show-more-files a {
  padding: 5px;
  width: 500px;
  font-size: 13px;
  height: 30px;
  position: absolute;
  bottom: 0;
}

/* Start of styling for responsive show more files button */

#files .show-more-files a,
.filearchive-upl-modal .show-more-files a {
  height: 46px;
  display: block;
  background-color: #f5f5f5;
  padding: 14px;
  text-align: center;
  border: 1px solid #ddd;
  border-top: 0;
}

#files .show-more-files a:hover,
.filearchive-upl-modal .show-more-files a:hover {
  background-color: #e1e1e1;
  text-decoration: none;
}

a.edit-file,
a.delete-file {
  color: #c1c1c1;
  cursor: pointer;
  width: 28px;
  display: inline-block;
}

a.edit-file:hover,
a.delete-file:hover {
  color: #444;
}

#files .table tr {
  height: 40px;
}

.folder-image-group {
  margin-top: 16px;
}

#group .file-title,
.file-title {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 10px;
}

.file-image {
  width: 28px;
  height: 28px;
  vertical-align: middle;
  margin-right: 5px;
}

.file-image i {
  float: left;
}

.filegroup-col div a,
.filename-col div a {
  width: 90%;
}

.filename-col div a img {
  vertical-align: middle;
}

@media (max-width: 1100px) {
  #group .col-md-8 {
    width: 100%;
  }
}

@media (max-width: 1100px) {
  #group .col-md-4 {
    width: 100%;
  }
}

.uploader-modal .modal-body {
  max-height: 455px;
  overflow: hidden;
}

.uploader-modal .modal-body .processing-btn {
  margin-right: 0;
}

.document-viewer-modal .file-modal-actions {
  position: absolute;
  right: 48px;
}

.document-viewer-modal .modal-dialog {
  width: 900px;
  height: 90%;
  max-height: 1000px;
}

.document-viewer-modal .modal-dialog .modal-body {
  padding: 0;
}

.document-viewer-modal .modal-dialog .modal-body.is-image {
  height: auto;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.document-viewer-modal .modal-dialog .modal-body.is-pdf {
  height: 600px;
}

.document-viewer-modal .modal-dialog .modal-body iframe {
  width: 100%;
  height: 100%;
  border: 0;
}

.document-download-modal .modal-dialog {
  width: 450px;
  margin: 150px auto;
}

.document-download-modal .modal-dialog .modal-body {
  text-align: center;
  padding: 60px 20px;
}

.document-download-modal .modal-dialog .modal-body div:not(:last-child) {
  margin-bottom: 15px;
}

.document-download-modal .modal-dialog .modal-body .download-file-icon i {
  font-size: 8em;
}

.document-download-modal .modal-dialog .modal-body .download-file-type {
  font-size: 16px;
}

.document-download-modal .modal-dialog .modal-body .download-file-size {
  color: gray;
}

#to[readonly] {
  background-color: white;
  border-color: #008db6;
}

.modal-body .file-modal-body .action-header .nav-pills > li {
  float: left;
  margin-right: 5px;
}

.modal-body #textareaBody {
  max-height: 318px;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

.file-modal-body {
  padding: 0 0 15px 0;
  width: 500px;
  float: left;
}

.file-image-body img {
  max-width: 295px;
  height: 166px;
  margin: 0 auto;
  display: block;
  -moz-transition: all 0.25s ease-out;
  -webkit-transition: all 0.25s ease-out;
  -o-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
}

.file-image-body img:hover {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.file-image-body .file-folder {
  font-size: 100px;
  margin-top: 30px;
  color: #ffffff;
  width: 160px;
  text-align: center;
}

.CKEditor .form-group.hide-form-group {
  display: inherit;
}

.form-group.hide-form-group {
  display: none;
}

.file-folder-title {
  display: inline-block;
  color: white;
  padding: 7px 0 7px 10px;
  width: 100px;
  font-size: 11px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  float: left;
}

.file-image-title-wrapper {
  line-height: 30px;
  height: 30px;
  display: none;
  position: absolute;
  top: 0;
  color: white;
  padding: 0 10px;
  width: 167px;
  font-size: 11px;
  background-color: rgba(0, 0, 0, 0.6);
}

.file-image-title {
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}

.file-control-wrapper {
  position: absolute;
  right: 5px;
  top: 5px;
  display: none;
}

.file-folder-control-wrapper {
  position: absolute;
  right: 5px;
  bottom: 5px;
  display: inline-block;
}

.file-folder-control-wrapper a.edit-file,
.file-folder-control-wrapper a.delete-file,
.file-control-wrapper a.edit-file,
.file-control-wrapper a.delete-file {
  color: #fff;
  cursor: pointer;
  display: inline-block;
  width: 16px;
}

.file-folder-control-wrapper a.edit-file:hover,
.file-folder-control-wrapper a.delete-file:hover,
.file-control-wrapper a.edit-file:hover,
.file-control-wrapper a.delete-file:hover {
  color: #7ab800;
}

.file-modal-body-aside {
  background-color: white;
  display: inline-block;
  width: 348px;
  height: 530px;
  padding-left: 5px;
  float: right;
  overflow: hidden;
}

.file-modal-body-aside-wrapper {
  background: #212124;
  margin-bottom: 10px;
}

.file-modal-body-aside .form-horizontal .form-group {
  margin-bottom: 10px;
}

.file-modal-body-aside .form-horizontal button {
  /* position: absolute; // removed due to #SUPPORT-4110 bug */
  bottom: 15px;
  right: 15px;
}

.file-modal-body-aside .form-control-static {
  word-break: break-all;
}

.file-modal-body-aside img {
  margin: auto;
  max-height: 208px;
  display: block;
}

.file-modal-body-aside .no-items {
  padding: 82px 20px;
  text-align: center;
  position: relative;
}

.file-modal-body-aside .no-items i {
  color: #999;
  padding-bottom: 20px;
}

.file-modal-body-aside .no-items span.text {
  font-size: 20px;
  color: #999;
  display: block;
}

.file-modal-body-aside .form-horizontal .col-sm-5,
.file-modal-body-aside .form-horizontal .col-sm-7 {
  padding-top: 5px;
  padding-right: 0;
  margin-bottom: 5px;
}

.file-modal-body-aside .form-horizontal .col-sm-7 {
  width: 238px;
}

.file-modal-body-aside .form-horizontal .col-sm-5 {
  width: 120px;
}

.file-modal-body-aside .form-horizontal .col-sm-5 span,
.file-modal-body-aside .form-horizontal .col-sm-7 .form-control-static {
  padding-top: 0;
  float: left;
  text-align: left;
  font-size: 13px;
  line-height: 1.3;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.file-modal-body-aside .form-horizontal .col-sm-7 .form-control-static {
  width: 228px;
}

.file-modal-body-aside .form-horizontal .col-sm-5 span {
  width: 120px;
}

.file-modal-body-aside
  .form-horizontal
  .col-sm-7
  .form-control-static
  select.form-control {
  width: 223px;
  position: relative;
  bottom: 0;
  font-size: 12px;
}

.modal-footer span.preloader {
  vertical-align: middle;
  margin-right: 5px;
}

/* File type colors */

.file-pdf {
  color: #b40808;
}

.file-word {
  color: #0060d7;
}

.file-ppt {
  color: #dd5900;
}

.file-excel {
  color: #007233;
}

.file-image {
  color: #39ccc8;
}

.file-audio {
  color: #5e2fc3;
}

.file-video {
  color: #252525;
}

.file-archive {
  color: #777bb4;
}

.file-txt {
  color: #999999;
}

.file-folder {
  color: #008db6;
}

form#form_folder_create {
  padding: 0;
}

/* Animate alert ng repeat  */
.fadealert-enter,
.fadealert-leave {
  -webkit-transition: 1s linear all;
  -moz-transition: 1s linear all;
  -o-transition: 1s linear all;
  transition: 1s linear all;
  position: relative;
}

.fadealert-enter {
  left: -10px;
  opacity: 0;
}

.fadealert-enter.fadealert-enter-active {
  left: 0;
  opacity: 1;
}

.fadealert-leave {
  left: 0;
  opacity: 1;
}

.fadealert-leave.fadealert-leave-active {
  left: -10px;
  opacity: 0;
}

a#cke_80_uiElement {
  margin-top: 15px !important;
}

#group #files .panel-heading {
  display: none;
}

#group #files .panel-body {
  padding: 0;
}

body #filepicker-dialog .modal-dialog.modal-lg {
  width: 890px;
  height: 620px;
  overflow: hidden;
  position: relative;
  -webkit-overflow-scrolling: touch;
}

body #filepicker-dialog .modal-dialog.modal-lg #files .action-header .actions {
  margin-bottom: 5px;
}

body #filepicker-dialog {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  min-height: 600px;
}

#filepicker-dialog .modal-body {
  padding: 15px;
}

#files .no-files {
  text-align: center;
  padding: 40px 0;
}

#files .no-files span.icon {
  color: #999;
  font-size: 4em;
}

#files .no-files .no-files-text {
  font-size: 24px;
  color: #999;
  padding: 30px 0;
}

.file-archive-upl-wrapper .no-results,
#files .no-results {
  text-align: center;
  padding: 40px 0 10px 0;
}

#files .no-results span i {
  color: #999;
}

#files .no-results .text {
  font-size: 24px;
  color: #999;
  padding: 30px 0;
}

.no-results {
  text-align: center;
  padding: 130px 0 10px 0;
  clear: both;
}

.no-results span i {
  color: #999999;
}

.no-results .text {
  font-size: 24px;
  color: #999999;
  padding: 30px 0;
}

#files {
  .panel {
    box-shadow: none;
    background: transparent;

    .panel-heading {
      background: transparent;
      border: 0;
      padding: 0;
      margin-bottom: 20px;

      .panel-title {
        margin: 4px 0;
        font-size: 1.28571429em !important;
      }
    }

    .panel-body {
      padding: 0;
    }
  }

  .edit-search {
    background-color: transparent !important;
  }
}
