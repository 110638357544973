#taxonomies {
  .nav-group li a {
    padding: 15px;
  }

  i {
    margin-right: 10px;
  }

  table {
    border-style: solid none;
    background: none;

    tr {
      border: 1px solid rgba(34, 36, 38, 0.15);
      background: #fff;

      td {
        .div-root-resource {
          display: flex;
          align-items: center;

          div {
            display: flex;
            flex-direction: column;
          }
        }
      }
    }

    .resource-row__separator {
      border-color: transparent;
      background: #f0f2f5;

      &:hover {
        background: #f0f2f5 !important;
      }

      .resource-td__separator {
      }
    }
  }
}

.color-picker {
  label {
    display: block;
  }

  .fa-stack {
    width: 1.8em;
    font-size: 1.7em;
  }
}

.parentTaxonomy-tr__color {
  background-color: #eee !important;
}
