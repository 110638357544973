.panel .contact-info {
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
}
