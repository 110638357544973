/**
 * This file contains styles that don't belong anywhere else. Use this for:
 *
 * 1) Generic global styles that don't belong to a specific component or page
 * 2) Other UI element styles, that are not otherwise self-contained components
 *
 * Any styles overwriting or adding to third-party libraries should be added in `overwrites.less`
 */

/*------------------------------------*\
  #LAYOUT
\*------------------------------------*/

html,
body,
.app {
  margin: 0;
  height: 100%;
  min-height: 100%;
}

body,
.app {
  flex-direction: column;
}

body,
.app,
.app-view,
.app-view > :only-child:not(.app-main),
.app-container,
.app-content,
.app-body {
  display: flex;
  flex: 1;
  min-width: 0;
  min-height: 0;
}

.app-main,
.app-view {
  flex-direction: column;
}

.app-content,
.app-main,
.app-view,
.app-body {
  flex: 1;
}

.app-body {
  position: relative;
}

.app-content {
  order: 1;
  flex-direction: column;
}

.app-main {
  order: 1;
  padding: @gutter;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.app-header {
  z-index: 10;
}

/*------------------------------------*\
  #GENERIC
\*------------------------------------*/

* {
  box-sizing: border-box;
}

a {
  transition: all 0.1s ease-in-out;
  outline: 0;
  cursor: pointer;

  &:hover {
    text-decoration: none;
    outline: 0;
  }

  &:focus {
    outline: 0;
  }
}

input[type='button'],
input[type='submit'],
input[type='reset'],
input[type='file']::-webkit-file-upload-button,
button {
  border-radius: 0;
}

.close {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.pre-line {
  white-space: pre-line;
}

.pre-wrap {
  white-space: pre-wrap;
}

// Show more files button in the image library.
#image-picker .show-more-files a {
  height: 46px;
  display: block;
  background-color: #f5f5f5;
  padding: 14px;
  text-align: center;
  border-top: 0;
  float: left;
  width: 100%;
}

#image-picker .show-more-files a:hover {
  background-color: #e1e1e1;
  text-decoration: none;
}

// Hide arrows in timepicker.
.custom-timepicker {
  tr.text-center {
    display: none;
  }
  td.form-group {
    display: table-cell !important;
  }
}

// Displayed when a request returns no results
// e.g. No notifications to display in Dashboard
.no-results {
  text-align: center;
  margin-top: -15px;
  padding: 50px 0 40px 0;
  clear: both;
  border-radius: 4px;
}

.custom-fieldset:not(:last-of-type) {
  border-bottom: 1px solid lightgrey;
  margin-bottom: 25px;
  padding-bottom: 10px;
}

.custom-fieldset:last-of-type {
  .form-group:last-of-type {
    margin-bottom: 0;
  }
}

.fieldset-title {
  margin-bottom: 15px;
  font-size: 13px;
  text-transform: uppercase;
}

.attachment-file-title {
  width: 270px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

.ui-selectable-helper {
  display: none;
}

.file-share-popover {
  word-wrap: break-word;
}

/*------------------------------------*\
  #COMPONENTS
\*------------------------------------*/

/**
 * Group image
 */

.group-image-thumbnail {
  width: 32px;
  height: 32px;
  display: inline-block;
  margin-right: 10px;
  border-radius: 50%;
  vertical-align: middle;
  background-size: cover;
  background-position: 50%;
  background-color: #e9e9e9;
  background-image: url('/public/img/group-default.png');

  &.group-image-thumbnail--sm {
    width: 24px;
    height: 24px;
  }

  &.group-image-thumbnail--lg {
    width: 48px;
    height: 48px;
  }
}

/**
 * Dimmer
 */

.dimmer {
  .dimmer();
}

/**
 * Panel navigation header
 */

.panel.panel-nav-group {
  .panel-heading {
    background: #f3f4f5;
    padding: 0;
    border-bottom: 0;

    .panel-title {
      padding: 16px 13px;
      background: #ffffff;
      border-radius: 4px;
    }
  }

  .nav-group {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    clear: both;
    width: 100%;
    height: auto;
    overflow: hidden;
    display: flex;
    align-items: stretch;

    li {
      display: inline-block;
      text-align: center;
      margin: 0;
      font-size: 13px;
      font-weight: 400;
      width: 50%;
      cursor: pointer;

      a {
        display: inline-block;
        width: 100%;
        height: 100%;
        text-decoration: none;
        color: @text-color;
        padding: 15px 12px;
        font-weight: 700;
        font-size: 13px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }

      a:hover,
      a:focus,
      a:active,
      a.active {
        background-color: white;
      }

      &.active > a,
      &.active > a:hover,
      &.active > a:focus {
        background-color: #fff;
      }

      &:first-of-type > a {
        border-top-right-radius: 0;
      }

      &:last-of-type > a {
        border-top-left-radius: 0;
      }
    }

    li.nav-left {
      a:hover,
      a:focus,
      a:active,
      a.active {
        border-top-right-radius: 0;
      }
    }

    li.nav-right {
      a:hover,
      a:focus,
      a:active,
      a.active {
        border-top-left-radius: 0;
      }
    }

    .badge {
      background-color: @brand-success;
      margin-left: 10px;
      font-size: 10px;
    }

    &.nav-group-left {
      li {
        text-align: left;
      }
    }
  }

  .nav-group > li > a.active,
  .nav-group > li > a.active:hover,
  .nav-group > li > a.active:focus {
    background-color: #fff;
  }
}

/**
 * Pager header (old)
 */

.pager-header {
  background-color: transparent;
  min-height: 35px;
  position: relative;
  margin-bottom: 20px;

  h1 {
    display: inline-block;
    font-size: 16px;
    height: 26px;
    margin: 10px 15px 0 15px;
    line-height: 26px;
  }

  .btn-toolbar {
    margin: auto auto auto 0;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    height: 35px;
  }

  .title,
  #page-details {
    margin: 4px 0;
    font-weight: bold;
    font-size: 18px;
  }

  .panel-search {
    margin: 0;
  }

  .form-inline {
    .form-group {
      vertical-align: top !important;
    }
  }
}

/**
 * Timeline - A timeline view used to show feeds and activities for users
 */

.timeline-centered {
  position: relative;

  .timeline-entry {
    position: relative;
    margin-top: 5px;
    margin-left: 20px;
    margin-bottom: 20px;
    clear: both;
  }

  .timeline-entry-inner {
    position: relative;
    margin-left: -20px;
  }

  .timeline-icon {
    background: #008db6;
    color: #fff;
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    text-align: center;
    line-height: 40px;
    font-size: 15px;
    float: left;

    &.load-more {
      background: #7ab800;
      cursor: pointer;
    }
  }

  .timeline-label {
    position: relative;
    border: 1px solid #f0f0f0;
    padding: 10px 15px 15px 15px;
    margin-left: 60px;
    border-radius: 3px;

    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 9px 9px 9px 0;
      border-color: transparent #f0f0f0 transparent transparent;
      left: 0;
      top: 10px;
      margin-left: -9px;
    }
  }

  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 2px;
    background: #f0f0f0;
    top: 20px;
    bottom: 0;
    margin-left: 20px;
  }
}

/**
 * Large modal - A custom Bootstrap modal used for content creation and image library
 */

.cd-large-modal {
  hr {
    border-color: #ccc;
  }

  .modal-dialog {
    position: fixed;
    top: 30px;
    left: 30px;
    right: 30px;
    bottom: 30px;
    margin: 0;
    width: auto;
  }

  .modal-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    min-height: 300px;
    border: none;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .modal-header {
    padding: 0;
    background-color: #eee;
    border-bottom: none;
  }

  .panel.panel-container {
    margin-bottom: 0;
    border-radius: 0;

    .panel-body {
      padding: 0;
    }
  }

  .modal-body {
    overflow: hidden;
    position: absolute;
    top: 52px;
    left: 0;
    right: 0;
    bottom: 0;
    max-height: none;
    padding: 0;
  }

  .modal-frame {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: auto;
    width: auto;
    margin: 0;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }

  .modal-frame-content {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .modal-main {
    position: absolute;
    top: 0;
    left: 0;
    right: 300px;
    bottom: 0;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    outline: 0;
  }

  .modal-aside {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 300px;
    padding: 20px 15px;
    overflow: auto;
    background-color: #eee;
  }

  .nav-tabs {
    border-bottom: none;
  }

  ul.nav.nav-tabs {
    margin-top: 0px;
  }

  .nav-tabs > li {
    margin-bottom: 0;

    a {
      text-align: center;
      font-size: 16px;
      padding: 15px 30px;
      margin-right: 0;
      border-radius: 0;
      color: inherit;
      border: none;

      i:not(.fa-times) {
        margin-right: 8px;
        opacity: 0.6;
      }

      &:hover {
        background-color: #e0e0e0;

        &.close {
          opacity: 0.7;
        }
      }

      &.close {
        margin: 0;
        opacity: 0.6;
      }
    }

    &.active > a {
      background-color: #fff;
      border: none;

      &:hover {
        border: none;
      }
    }
  }

  .nav-tabs > li > a.close {
    background-color: #000;
    color: #fff;
    text-shadow: none;
  }

  @media screen and (max-width: 1024px) {
    .modal-aside {
      width: 250px;
    }

    .modal-main {
      right: 250px;
    }
  }

  .panel {
    box-shadow: none;

    .footer {
      background: transparent;
      width: 100%;
    }
  }
}

ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

#image-preview-modal {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9997;
  background-color: #000;

  .image-preview-menu {
    position: absolute;
    height: 48px;
    padding-bottom: 15px;
    background-color: #000;
    left: 0;
    right: 0;
    top: 0;
    opacity: 1;
    clear: both;
    z-index: 9998;

    .image-preview-title {
      line-height: 46px;
      display: inline-block;
      padding-left: 30px;
      font-size: 15px;
      color: #ccc;
    }

    .image-preview-actions {
      float: right;
      line-height: 48px;
      padding-right: 20px;

      a {
        width: 48px;
        display: inline-block;
        text-align: center;
        color: #ccc;

        &:hover {
          color: white;
        }

        i {
          font-size: 24px;
        }
      }
    }
  }

  .image-left-nav,
  .image-right-nav {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100px;
    height: 100%;
    z-index: 9996;
    background-color: #000;

    &:hover {
      cursor: pointer;
      background-color: #151515;

      a {
        color: white;
      }
    }

    a {
      position: absolute;
      color: #333;
      top: 48%;
    }
  }

  .image-left-nav {
    left: 0;

    a.left-nav {
      left: calc(50% - 15px);
    }
  }

  .image-right-nav {
    right: 0;

    a.right-nav {
      right: calc(50% - 15px);
    }
  }

  .image-preview {
    position: absolute;
    top: 47px;
    bottom: 47px;
    left: 0;
    right: 0;
    text-align: center;

    .preview-container-parent {
      width: 100%;
      height: 100%;
      text-align: center;
      vertical-align: middle;
      border-collapse: collapse;
      margin: 0;
      padding: 0;

      .content-item {
        img {
          height: 700px;
        }
        @media (max-height: 800px) {
          img {
            height: 600px;
          }
        }
        @media (max-height: 700px) {
          img {
            height: 500px;
          }
        }
        @media (max-height: 600px) {
          img {
            height: 400px;
          }
        }
      }
    }
  }

  .tooltip-inner {
    background-color: #333;
  }
  .tooltip-arrow {
    border-bottom-color: #333;
  }
}

/**
 * Comments
 */

.comment-header {
  margin-top: 0;
  margin-bottom: 20px;
}

.comment {
  padding: 0 0 15px 0;
  border-bottom: 1px solid #e9e9e9;
}

.comment-body {
  h5 {
    margin-top: 0;
    margin-bottom: 5px;
  }

  .time {
    margin-bottom: 5px;
  }

  // Hide action as default.
  .actions {
    display: inline-block;

    i {
      cursor: pointer;
      margin-left: 5px;

      &:hover {
        color: #444;
      }
    }
  }
}

/**
 * Custom checkbox
 */

.custom-checkbox {
  position: relative;
  display: inline-block;
  top: 3px;

  &.custom-checkbox--disabled  {
    > .box > .tick  {
      border-bottom: 3px solid #787878;
      border-left: 3px solid #787878;
    }
  }
}

.custom-checkbox > .box {
  position: relative;
  display: block;
  width: 18px;
  height: 18px;
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 4px;
  -webkit-border-radius: 4px;
}

.custom-checkbox > .box > .tick {
  position: absolute;
  left: 2px;
  top: 4px;
  width: 12px;
  height: 6px;
  border-bottom: 3px solid #008db6;
  border-left: 3px solid #008db6;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  display: none;
}

.custom-checkbox > input:checked + .box > .tick {
  display: block;
}

.custom-checkbox > input {
  position: absolute;
  outline: none;
  left: 0;
  top: 0;
  padding: 0;
  width: 18px;
  height: 18px;
  border: none;
  margin: 0;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}

/**
 * Loading indicator (old)
 */

.processing {
  -webkit-animation: rotation 0.6s infinite linear;
  -moz-animation: rotation 0.6s infinite linear;
  -o-animation: rotation 0.6s infinite linear;
  animation: rotation 0.6s infinite linear;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  border-left: 2px solid rgba(0, 0, 0, 0.1);
  border-right: 2px solid rgba(0, 0, 0, 0.1);
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  border-top: 2px solid #767676;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}

@-moz-keyframes rotation {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(359deg);
  }
}

@-o-keyframes rotation {
  from {
    -o-transform: rotate(0deg);
  }
  to {
    -o-transform: rotate(359deg);
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.processing-btn {
  display: inline-block;
  margin-right: 10px;
}

.processing-pg {
  display: block;
  margin: 20px auto;
}

.processing-16 {
  width: 16px;
  height: 16px;
}

.processing-24 {
  width: 24px;
  height: 24px;
}

.processing-32 {
  width: 32px;
  height: 32px;
}

.processing-48 {
  width: 48px;
  height: 48px;
}

.processing-64 {
  width: 64px;
  height: 64px;
}

// Loading indicator globally within the application.
div.loading-indicator {
  position: relative;
  width: 20px;
  height: 34px;
  display: none;

  span.processing {
    position: absolute;
    top: 9px;
    left: 0;
    margin-right: 0;
  }
}

/**
 * Shortcut displayed in a tooltip
 */

.cd-shortcut {
  width: 20px;
  height: 18px;
  display: inline-block;
  background-color: #fff;
  text-align: center;
  border-radius: 2px;
  color: #000;
  font-size: 12px;
  line-height: 18px;
  margin-left: 4px;
}

/**
 * Search field
 */

.search {
  width: 33%;
}

.search,
.panel-search {
  .input-group {
    display: block;

    input {
      padding-top: 0.57142857em;
      padding-bottom: 0.57142857em;
      padding-right: 2.67142857em;
      border-radius: 4px !important;
    }

    i {
      cursor: pointer;
      position: absolute;
      line-height: 34px;
      text-align: center;
      top: 0;
      right: 0;
      margin: 0;
      height: 100%;
      width: 2.67142857em;
      opacity: 0.5;
      border-radius: 0 0.28571429rem 0.28571429rem 0;
      -webkit-transition: opacity 0.3s ease;
      transition: opacity 0.3s ease;
      color: rgba(0, 0, 0, 0.87);
    }
  }
}

@media (max-width: 945px) {
  .search {
    width: 100% !important;
    margin: 15px 0;
  }
}

.panel-search {
  margin: 7px;
  overflow: hidden;

  .form-group {
    max-width: 230px;
  }
}

/**
 * Redactor
 */

.redactor-box {
  .redactor-layer {
    border: 1px solid #ccc;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .redactor-toolbar {
    box-shadow: none;
    border: 1px solid #ccc;
    border-bottom: none;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    li {
      a {
        height: 32px;
        padding: 10px 12px;
        font-size: 14px;
        border-right: none;
      }

      &:nth-child(2),
      &:nth-child(6),
      &:nth-child(9),
      &:nth-child(13) {
        a {
          border-right: 1px solid #ccc;
        }
      }
    }
  }
}

.redactor-link-tooltip {
  z-index: 1051;
}

#redactor-modal-overlay {
  background-color: rgba(0, 0, 0, 0.5);
}

#redactor-modal-box {
  #redactor-modal {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    background-clip: padding-box;
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.2);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji' !important;

    #redactor-modal-header {
      padding: 20px;
      border-color: #e5e5e5;
      font-size: 16px;
      font-weight: 400;
    }

    #redactor-modal-close {
      font-size: 21px;
      font-weight: bold;
    }

    #redactor-modal-body {
      padding: 20px;

      label {
        font-weight: 700;
      }

      input {
        border-radius: 4px;
        font-size: 14px;
        font-family: inherit;
      }

      // The footer
      section:last-child {
        border-top: 1px solid #e5e5e5;
        text-align: right;
        margin: 0 -20px;
        padding-top: 20px;

        button {
          margin: 0;
        }

        #redactor-modal-button-action {
          color: white;
          background-color: @brand-primary;

          &:hover {
            background-color: darken(@brand-primary, 5%);
          }
        }

        #redactor-modal-button-cancel {
          background: none;
          color: @brand-primary;
        }
      }

      // Select2 input
      .select2-container {
        width: 100%;

        &.select2-dropdown-open .select2-choice {
          border: 1px solid @brand-primary;
        }

        .select2-choice {
          border: 1px solid #ddd;
          height: 40px;
          padding: 8px;
          background-image: none;
          color: #333;
          background-color: #fff;
          box-shadow: none;

          .select2-arrow {
            border-left: 0px solid #ccc;
            background: none;
            background-image: none;
          }
        }
      }
    }
  }
}

// Layout for dropdown menu.
.select2-drop {
  &.select2-drop-active {
    border-color: #008db6;
  }

  .select2-results {
    .select2-result-unselectable {
      background: #eee;
      &.select2-disabled {
        background: #fff;
      }
    }

    .select2-no-results,
    .select2-searching,
    .select2-selection-limit {
      background: #fff;
      padding-left: 6px;
    }

    .select2-result .select2-result-label {
      &.select2-disabled {
        background: #fff;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        padding: 3px 0;
        color: inherit !important;
        text-align: inherit !important;
        background-color: initial !important;
        margin: inherit !important;
        font-size: inherit !important;
        //  To overwrite the original css
      }
    }
  }
}

/**
 * Avatar
 */

.media {
  margin-top: 5px;

  &:first-child {
    margin-top: inherit;
  }

  .media-left {
    display: table-cell;
    vertical-align: top;
    padding-right: 10px;
  }

  .media-body {
    width: 100%;
    display: table-cell;
    vertical-align: middle;
  }
}

/**
 * Shifts
 */

#shifts {
  .pager-header {
    .btn-group {
      margin-bottom: 0.5em;
    }
  }
}

/**
 * Widgets
 */

.panel-link {
  display: block;
  color: inherit;

  .panel {
    height: 100%;
  }

  &:hover {
    color: inherit;

    .panel {
      box-shadow: 0 1px 3px 1px #bbb, 0 0 0 1px #bbb;
    }
  }

  &.active {
    .panel {
      box-shadow: 0 1px 3px 0 @brand-primary, 0 0 0 1px @brand-primary;
    }
  }
}

/**
 * Elastic textarea
 */

textarea.msd-elastic {
  min-height: 54px;
  max-height: 300px;
}

textarea.msd-elastic:focus {
  -webkit-transition: height 150ms ease-in-out;
  -moz-transition: height 150ms ease-in-out;
  -o-transition: height 150ms ease-in-out;
  transition: height 150ms ease-in-out;
}

/**
 * Resource icons
 */

.fa-circle.color-0 {
  color: @color-0 !important;
  &:before,
  &:after {
    color: @color-0 !important;
  }
}

.fa-circle.color-1 {
  color: @color-1 !important;
  &:before,
  &:after {
    color: @color-1 !important;
  }
}

.fa-circle.color-2 {
  color: @color-2 !important;
  &:before,
  &:after {
    color: @color-2 !important;
  }
}

.fa-circle.color-3 {
  color: @color-3 !important;
  &:before,
  &:after {
    color: @color-3 !important;
  }
}

.fa-circle.color-4 {
  color: @color-4 !important;
  &:before,
  &:after {
    color: @color-4 !important;
  }
}

.fa-circle.color-5 {
  color: @color-5 !important;
  &:before,
  &:after {
    color: @color-5 !important;
  }
}

.fa-circle.color-6 {
  color: @color-6 !important;
  &:before,
  &:after {
    color: @color-6 !important;
  }
}

.fa-circle.color-7 {
  color: @color-7 !important;
  &:before,
  &:after {
    color: @color-7 !important;
  }
}

.fa-circle.color-8 {
  color: @color-8 !important;
  &:before,
  &:after {
    color: @color-8 !important;
  }
}

.fa-circle.color-9 {
  color: @color-9 !important;
  &:before,
  &:after {
    color: @color-9 !important;
  }
}

.fa-circle.color-10 {
  color: @color-10 !important;
  &:before,
  &:after {
    color: @color-10 !important;
  }
}

.fa-circle.color-11 {
  color: @color-11 !important;
  &:before,
  &:after {
    color: @color-11 !important;
  }
}

.fa-circle.color-12 {
  color: @color-12 !important;
  &:before,
  &:after {
    color: @color-12 !important;
  }
}

.fa-circle.color-13 {
  color: @color-13 !important;
  &:before,
  &:after {
    color: @color-13 !important;
  }
}

.fa-circle.color-14 {
  color: @color-14 !important;
  &:before,
  &:after {
    color: @color-14 !important;
  }
}

.fa-circle.color-15 {
  color: @color-15 !important;
  &:before,
  &:after {
    color: @color-15 !important;
  }
}

.fa-circle.color-16 {
  color: @color-16 !important;
  &:before,
  &:after {
    color: @color-16 !important;
  }
}

.fa-circle.color-17 {
  color: @color-17 !important;
  &:before,
  &:after {
    color: @color-17 !important;
  }
}

.fa-circle.color-18 {
  color: @color-18 !important;
  &:before,
  &:after {
    color: @color-18 !important;
  }
}

.fa-circle.color-19 {
  color: @color-19 !important;
  &:before,
  &:after {
    color: @color-19 !important;
  }
}

.fa-circle.color-20 {
  color: @color-20 !important;
  &:before,
  &:after {
    color: @color-20 !important;
  }
}

.fa-circle.color-21 {
  color: @color-21 !important;
  &:before,
  &:after {
    color: @color-21 !important;
  }
}

.fa-circle.color-22 {
  color: @color-22 !important;
  &:before,
  &:after {
    color: @color-22 !important;
  }
}

.fa-circle.color-23 {
  color: @color-23 !important;
  &:before,
  &:after {
    color: @color-23 !important;
  }
}

/**
 * Toggleable labels
 */

.toggle-labels {
  .toggle-labels__label {
    display: inline-block;
    margin: 0 0.5em 0.5em 0;

    .label {
      display: inline-block;
      min-width: 2em;
      min-height: 2em;
      line-height: 1em;
      text-align: center;
      border-radius: 500rem;
      padding: 0.66em 1em;
      font-size: 12px;

      i:first-of-type {
        margin-right: 0.5rem;
      }
    }

    .label-default {
      color: rgba(0, 0, 0, 0.6);
      background-color: #fff;
      border: 1px solid rgba(34, 36, 38, 0.15);

      &:hover {
        color: rgba(0, 0, 0, 0.8);
      }
    }
  }

  .toggle-labels__label--active {
    .label-default {
      color: #333;
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}

/**
 * Fixed sidebar panel
 */

.app-aside {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  width: @asideWidth;
  background-color: #fff;
  z-index: @asidePosition;
  overflow: hidden;

  .app-aside__main {
    flex: 1;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding: @gutter;
  }

  .app-aside__bottom {
    flex: 0 0 auto;
    padding: @gutter;
    background-color: #fff;
  }

  .app-aside__bottom--shadowed {
    box-shadow: 0px -10px 20px 0px #fff;
    z-index: 1;
  }

  &.app-aside--left {
    order: 0;
    border-right: 1px solid #ddd;
  }

  &.app-aside--right {
    order: 2;
    border-left: 1px solid #ddd;
  }

  .app-aside__divider {
    padding: 17px 15px;
    margin-bottom: 8px;
    background-color: @gray-lighter;
    border-bottom: 1px solid @gray-lighter;
    font-weight: 700;

    a {
      font-weight: 400;
      white-space: nowrap;
      margin-left: 5px;
    }
  }
}

/**
 * Activity feed
 */

.feed {
  .feed-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 12px 15px;

    &:not(:last-child) {
      border-bottom: 1px solid #f6f6f6;
    }

    &.feed-item--clickable:hover {
      background-color: #f9f9f9;
    }
  }

  .feed-item__label {
    display: block;
    flex: 0 0 auto;
    width: auto;
    height: auto;
    align-self: stretch;
    text-align: left;
    margin-right: 1em;
  }

  .feed-item__content {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: stretch;
    text-align: left;
    word-wrap: break-word;
  }

  .feed-item__summary {
    margin: 0;
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
  }

  .feed-item__date {
    display: inline-block;
    font-weight: 400;
    font-size: 12px;
    font-style: normal;
    margin: 0 0 0 0.5em;
    padding: 0;
    color: rgba(0, 0, 0, 0.4);
  }

  .feed-item__meta {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-size: 11px;
    margin: 0.5em 0 0;
    background: 0 0;
    border: none;
    border-radius: 0;
    box-shadow: none;
    padding: 0;
    color: rgba(0, 0, 0, 0.6);

    a {
      cursor: pointer;
      opacity: 1;
      color: rgba(0, 0, 0, 0.5);
      transition: color 0.1s ease;

      &:hover {
        color: rgba(0, 0, 0, 0.95);
      }
    }

    i {
      margin-right: 0.25em;
    }

    > * {
      margin-right: 0.75em;
    }

    > :last-child {
      margin-right: 0;
    }
  }

  .feed-item__body {
    margin: 0.25em 0 0;
    line-height: 1.5em;
  }
}

/**
 * Attached toolbar
 */

.attached-toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #fff;
  margin-top: 24px;
  padding: 1.5rem;
  border-width: 1px 1px 0 1px;
  border-style: solid;
  border-color: rgba(34, 36, 38, 0.15);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.attached-toolbar__left {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  > * {
    margin-right: 10px;
  }
}

.attached-toolbar__right {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  > * {
    margin-left: 10px;
  }
}

.attached-toolbar--sticky {
  .sticky(@menuHeight, @navWidth, @navWidth);
}

.table-with-toolbar {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

/**
 * Qtip
 */

// Panel with navigation
.qtip-content,
#groupNavigation,
#contributions,
#dashboard {
  .nav-link {
    margin: 7px 7px 0 0;
  }
  #files .panel .panel-heading {
    display: none;
  }
}

// Qtip in calendar
.qtip {
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
  border: 1px solid rgba(34, 36, 38, 0.15);

  .qtip-content {
    .panel {
      box-shadow: none;

      .panel-heading {
        .nav-group li a {
          padding: 14px 14px 14px 0;
        }
      }
    }
  }
}

/**
 * Tasks
 */

#tasks {
  .panel.no-nav {
    box-shadow: none;
    background: transparent;

    .panel-heading {
      background: transparent;
      border: 0;
      padding: 0;
      margin-bottom: 20px;

      .panel-title {
        margin: 4px 0;
        font-size: 1.28571429em !important;
      }
    }

    .panel-body {
      padding: 0;
    }
  }
}

/**
 * Form.io Form Builder
 */

.formbuilder {
  .formarea {
    .dropzone {
      .no-components {
        .panel {
          box-shadow: none;
          margin: 0;
        }

        .panel-body {
          border: 2px dashed rgba(0, 0, 0, 0.1);
          background: transparent;
          border-radius: 4px;
        }
      }

      .component-list {
        .form-builder-component {
          padding: 12px;
          border: 2px dashed transparent;
          border-radius: 4px;
          -webkit-transition: border 0.2s ease 0s;
          transition: border 0.2s ease 0s;

          .panel-heading {
            background-color: #eee;
          }

          .component-btn-group {
            position: relative;
            float: right;
            margin-right: -5px;
          }

          .form-group {
            margin-bottom: 0;
          }

          .form-field-type-select {
            display: block;
          }

          .form-field-type-container,
          .form-field-type-panel {
            .component-form-group {
              padding: 1em;
            }
          }

          .form-field-type-person {
            .panel-body {
              padding: 0;

              .panel-body-content {
                padding: 15px;
              }
            }
          }

          fieldset {
            padding: 0;
            margin: 0;
            border: none;
          }

          .input-description,
          .help-block {
            white-space: pre-line;
          }
        }

        li.non-draggable {
          cursor: default;
        }

        li.draggable > .form-builder-component:hover {
          cursor: move;
          border-color: rgba(0, 0, 0, 0.1);
        }

        .component-type-panel,
        .component-type-container,
        .component-type-person {
          > div > .component-btn-group {
            margin-top: 10px;
            padding-right: 10px;
          }
        }

        .empty-root-list,
        .empty-container {
          -webkit-transition: all 350ms ease-in-out;
          -moz-transition: all 350ms ease-in-out;
          -o-transition: all 350ms ease-in-out;
          transition: all 350ms ease-in-out;
        }

        &.dndDragover > li > div.panel > .panel-body {
          .empty-container {
            background-color: #eee;
          }
          .empty-root-list {
            background-color: #eee;
          }
        }

        &.shouldHidePlaceholder {
          > .dndPlaceholder {
            display: none;
          }
        }
      }

      .dndPlaceholder {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        background-color: #ddd;
        border-radius: 4px;
        margin: 1em 0;
        min-height: 100px;
        clear: both;
      }
    }
  }

  .formcomponents {
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    position: absolute;
    top: 0;
    left: 0;
    width: @asideWidth;
    height: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    margin: 0 !important;
    border-right: 1px solid rgba(34, 36, 38, 0.15);
    background-color: #fff;

    .formcomponents__container {
      flex: 1;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      min-width: 0;
      min-height: 0;
      padding: @gutter;
    }

    .formcomponents__group-title {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 700;
      margin-top: 0;
      margin-bottom: 15px;
    }

    .formcomponentcontainer {
      margin: 3px 0;

      &.formcomponent-section {
        padding-bottom: 1em;

        .btn.btn-block {
          padding: 10px 16px;
          line-height: 1.33;
          border-radius: 6px;

          display: flex;
          align-items: center;

          i {
            font-size: 200%;
          }

          .component-title {
            margin-left: 0.5em;
          }

          .component-subheader {
            display: block;
            font-size: 90%;
            font-weight: normal;
          }
        }
      }

      &.dndDraggingSource {
        display: none;
      }

      .btn {
        padding-right: 0px;
        display: flex;
        align-items: center;
        white-space: normal;
        cursor: move;
        overflow: hidden;

        .component-title {
          flex: 1;
        }

        .fa {
          margin-right: 10px;
        }
        .far {
          margin-right: 10px;
        }
      }
    }

    .formcomponents__search {
      .input-group-btn .btn-sm {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}

.value-builder {
  .value-builder-option {
    display: flex;
    margin-bottom: 10px;

    .value-builder-option-text {
      flex-grow: 1;
    }
    .value-builder-option-remove {
      display: flex;
      align-content: center;
      align-items: center;
      padding-left: 10px;
    }
  }
}

.ant-select-selection-item {
  align-self: unset !important;
}

// ng-dialog
body.ngdialog-open,
html.ngdialog-open {
  overflow: visible;
}

.ngdialog-overlay {
  background: rgba(0, 0, 0, 0.5);
}

.ngdialog.ngdialog-theme-default .ngdialog-content {
  border: 1px solid rgba(0, 0, 0, 0.6);
  border-radius: 6px;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.2);
  outline: 0;
  background-color: white;
  padding: 0;

  .modal-body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
}

.ngdialog-close {
  display: none;
}

#component-settings,
#confirm-remove-dialog {
  border: none;

  .nav.nav-tabs {
    display: none;
  }

  .action-buttons {
    button {
      margin-left: 10px;
    }
  }

  .input-description,
  .help-block {
    white-space: pre-line;
  }
}

#component-settings .preview-panel .ng-invalid {
  border-color: #ccc;
}

/**
 * Sweet Alert
 */

.sweet-alert,
.sweet-alert * {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji' !important;
}

.sweet-overlay {
  -webkit-animation: fadeIn 0.5s;
  animation: fadeIn 0.5s;
}

.sweet-alert.showSweetAlert {
  -webkit-animation: fadeIn 0.5s ease;
  animation: fadeIn 0.5s ease;
}

.sweet-alert.hideSweetAlert {
  -webkit-animation: fadeOut 0.5s ease;
  animation: fadeOut 0.5s ease;
}

.sweet-alert {
  padding: 0 !important;
}

.sweet-alert .sa-icon {
  display: none !important;
}

.sweet-alert h2 {
  text-align: left;
  margin: 0;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
  padding: 1.25rem 1.5rem;
  color: rgba(0, 0, 0, 0.85);
  font-size: 1.3em;
  line-height: 1.2857em;
}

.sweet-alert p {
  display: block;
  font-size: 1em;
  line-height: 1.4;
  padding: 1.5rem;
  color: rgba(0, 0, 0, 0.87);
  text-align: left;
}

.sweet-alert .sa-button-container {
  background: #f9fafb;
  padding: 1rem;
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  text-align: right;
  margin: 0;
}

.sweet-alert button {
  min-height: 1em;
  border-radius: 4px;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 700;
  padding: 0.78571429em 1.5em;
  text-transform: none;
  box-shadow: none !important;
  -webkit-transition: opacity 0.1s ease, background-color 0.1s ease,
    color 0.1s ease, box-shadow 0.1s ease, background 0.1s ease;
  transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease,
    box-shadow 0.1s ease, background 0.1s ease;
}

.sweet-alert button.cancel {
  background: #e0e1e2;
  color: rgba(0, 0, 0, 0.6);
}

.sweet-alert button.cancel:hover {
  background-color: #cacbcd;
  color: rgba(0, 0, 0, 0.8);
}

.sweet-alert button.cancel:focus {
  box-shadow: none !important;
}

.sweet-alert input {
  margin-bottom: 0;
}

.sweet-alert fieldset {
  padding: 0 !important;
}

/**
 * Drag and drop area
 */
.file-drop {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 30px;
  text-align: center;
  cursor: pointer;
  border: 2px dashed #ddd;
  border-radius: 6px;
  background-color: #ffffff;
  transition: 0.15s border-color ease-in-out;

  i.fa-cloud-upload {
    transition: 0.15s color ease-in-out;
    color: @text-muted;
  }

  &.file-drop--over {
    border-color: @brand-primary;

    i.fa-cloud-upload {
      color: @brand-primary;
    }
  }
}

.unsupported-browser {
  position: absolute;
  display: none;
  border: 2px solid red;
  padding: 10px;
  margin: 10px;
  top: 0px;
}

.sp-status {
  display: none;
}