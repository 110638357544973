.event-detail-rotas {
  .panel-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: none;

    .btn-default {
      padding: 1px 5px;
    }
  }

  .panel-body {
    padding: 0;
    height: 100%;
  }

  .event-shift {
    .event-shift__heading {
      padding: 15px;
      border-style: solid;
      border-width: 1px 0 0;
      border-color: #ddd;
      background-color: #f9fafb;
    }

    .img-circle {
      margin-right: 0.7em;
      width: 27px;
    }

    .event-shift__body {
      padding: 0 15px;
    }

    .event-shift__assignee {
      display: flex;
      align-items: center;
      padding: 0.7em 0;

      &.event-shift__assignee--unassigned {
        color: #ce2c5a;
      }
    }

    .event-shift__assignee-details {
      display: flex;
      flex-direction: column;
    }
  }
}
