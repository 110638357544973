.legal-document-modal {
  .modal-dialog {
    width: 900px;
    max-width: 100%;
  }

  .modal-header {
    .download {
      float: right;
      font-size: 21px;
      line-height: 1;
      margin-top: -2px;
      margin-right: 5px;
      color: #000;
      opacity: 0.2;

      &:hover {
        opacity: 0.5;
      }
    }
  }

  .modal-body {
    height: 350px;
    padding: 0;

    iframe {
      width: 100%;
      height: 100%;
      border: 0;
    }
  }
}
