// Event cells
.rotas-table__event-cell {
  padding: 0.5em;

  a {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;

    &:hover,
    &:focus {
      color: #00526a;
      cursor: pointer;
    }
  }
}

.BodyGrid {
  width: 100%;
  border: 1px solid #e0e0e0;
}

.evenRow,
.oddRow {
  border-bottom: 1px solid #e0e0e0;
}

.oddRow {
  background-color: #fafafa;
}

.cell {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.5em;
  align-items: start;
  padding: 0.5em;
  font-size: 12px;
}

.cell {
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}

.date-grid {
  .cell {
    background: #f9fafb;
    height: 52px;
    font-weight: bold;
    border-top: 1px solid #e0e0e0;
    border-left: 1px solid #e0e0e0;

    i {
      padding-left: 5px;
    }
  }
}

.events-grid {
  .cell {
    border-left: 1px solid #e0e0e0;

    a {
      color: rgba(0, 0, 0, 0.87);

      &:hover,
      &:focus {
        color: #00526a;
      }
    }
  }
}

.rotas-header {
  .cell {
    background: #f9fafb;
    height: 52px;
    font-weight: bold;
    border-top: 1px solid #e0e0e0;
  }
}

//.rotas-grid {

// Task cells
.rotas-table__task-cell.cell {
  padding: 0;
  width: 90%;
  height: 100%;
  display: flex;
  align-items: center;
  white-space: nowrap;

  // Disabled cells
  &.rotas-table__task-cell--disabled {
    width: 100%;
    background-color: #f5f5f5;
    height: 100px;

    a:hover {
      cursor: not-allowed;
    }
  }

  // Clickable cells
  &:not(.rotas-table__task-cell--disabled) {
    a {
      height: 100%;
      width: 100%;
      display: flex;
      padding: 0.5em;

      .rotas-table__task-cell-content {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;

        .rotas-table__task-users {
          padding-left: 0px;
          margin-bottom: 0px;

          li {
            width: 100%;
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &:not(.text-danger) {
              color: rgba(0, 0, 0, 0.87);
            }
          }

          small {
            color: rgba(0, 0, 0, 0.87);
            font-size: 85%;
            white-space: nowrap;
            word-break: break-word;
          }
        }
      }

      .rotas-table__hover {
        display: none;
      }

      &:focus {
        text-decoration: none;
      }

      &:hover {
        color: rgba(0, 0, 0, 0.87);

        i {
          &.fa-plus {
            color: #7ab800;
          }

          &.fa-pencil-alt {
            color: #008db6;
          }
        }

        .rotas-table__hover {
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 1em;
          background: rgba(255, 255, 255, 0.9);
          border-color: #66afe9;
          outline: 0;
          z-index: 1;
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
            0 0 8px rgba(102, 175, 233, 0.6);
        }
      }
    }
  }
}

.rotas-table__task-cell-content {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .rotas-table__task-users {
    padding-left: 0px;
    margin-bottom: 0px;

    li {
      width: 100%;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:not(.text-danger) {
        color: rgba(0, 0, 0, 0.87);
      }
    }

    small {
      color: rgba(0, 0, 0, 0.87);
      font-size: 85%;
      white-space: nowrap;
      word-break: break-word;
    }
  }
}

.printTable {
  table-layout: fixed;
  height: 1px;
  margin-bottom: 80mm;

  th {
    padding: 0.5em;
    word-break: break-word;
    hyphens: auto;

    // Event cell
    &:first-child {
      cursor: pointer;
      width: 20%;
    }

    span {
      font-size: 11px;
    }

    i {
      margin-left: 0.25em;
    }
  }

  td {
    position: relative;
    height: 100%;
    width: 100%;
    padding: 0;
    font-size: 12px;

    &:first-child {
      vertical-align: top;
    }

    .rotas-table__cell {
      display: block;
      height: 100%;
      width: 100%;
    }

    .rotas-table__task-cell {
      // Truncate
      width: 90%;
      height: 100%;
      display: flex;
      align-items: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .rotas-table__task-cell-content-print {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;

      .rotas-table__task-users {
        padding-left: 0px;
        margin-bottom: 0px;
        margin-top: 6px;

        li {
          width: 100%;
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          &:not(.text-danger) {
            color: rgba(0, 0, 0, 0.87);
          }
        }

        small {
          color: rgba(0, 0, 0, 0.87);
          font-size: 85%;
          white-space: nowrap;
          word-break: break-word;
        }
      }
    }
  }
}