.login-form {
  input[name='password'] {
    padding-right: 36px;
  }

  .toggle-password {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
    padding: 6px 12px;
    height: 34px;
  }
}
