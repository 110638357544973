.left-width() {
  width: 55%;

  &.full-width {
    width: 100%;
  }

  @media print {
    width: 100%;
  }
}

.right-width() {
  width: 41%;
  margin-left: 4%;

  @media print {
    display: none;
  }
}

.form-response-people-section {
  position: relative;

  .alert--people-section {
    margin-bottom: -5px;
    display: flex;
    align-items: center;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    color: #f2994a;
    background-color: #f0e1b3;
  }

  .field-row {
    display: flex;

    .field-column {
      display: flex;
      align-items: center;
      padding-left: 1em;
      border-right: 1px solid #d9dada;
      border-left: 1px solid #d9dada;

      .field-column_content {
        flex: 1;
      }

      .field-column_label {
        font-size: 1em;
        line-height: 1.2em;
        font-weight: bold;
        color: #f2994a;
        padding: 0.2em 0.6em;
        align-self: center;
        @media print {
          color: #ffffff;
        }
      }

      &.field-column-left {
        .left-width();
        background-color: #ffffff;
      }

      &.field-column-right {
        .right-width();
        background-color: #ffffff;
        border-right: 1px solid @brand-primary;
        border-left: 1px solid @brand-primary;
      }

      &.field-diff-column-left {
        .left-width();
        border-right: none;
        border-left: none;
      }

      &.field-diff-column-right {
        .right-width();
        background-color: none;
        border-right: none;
        border-left: none;
      }
    }

    // Center the sensitive icon in the left field column
    &:nth-child(1) {
      .field-column-left {
        align-items: center;
      }
    }

    // Space between fields
    &:nth-child(2) {
      .field {
        padding-bottom: 1em;
      }
    }

    // Background colors for rows with a difference
    &.field-row--changed {
      .field-column-left,
      .field-column-right {
        background-color: #f0e1b3;
      }
    }

    // Panel headers
    &.field-row--header {
      .field-column {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }

      // Left section panel header (form submission)
      .field-column-left {
        min-height: 66px;
        padding: 1em 0.7em;
        border-top: 1px solid #d9dada;
        border-bottom: 1px solid #d9dada;
        background-color: #f0f0f0;
      }

      // Right section panel header (contact section)
      .field-column-right {
        padding: 1em 0.7em;
        background-color: @brand-primary !important;
        color: #ffffff;
      }
    }
  }

  .field {
    // Highlight background of rows when hovering over a field
    &:hover {
      .field-row--value {
        .field-column {
          transition: background-color 0.2s ease-out;
          background-color: #d9edf7;
        }
      }
    }

    // Space between field label rows in section
    .field-row:first-child {
      .field-column {
        padding-top: 1em;
      }
    }

    // Padding for the field value rows
    .field-row:last-child {
      .field-column {
        padding: 0.4em 1em;
      }
    }
  }

  // Bottom radius at the end of the panel
  .field:last-child {
    .field-row:last-child {
      .field-column {
        border-bottom: 1px solid #dededf;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }

      .field-column-right {
        border-bottom: 1px solid @brand-primary;
      }
    }
  }

  // Person avatar and name
  a.person:hover {
    text-decoration: underline;
  }

  .person {
    display: flex;
    align-items: center;
    flex: 1;
    color: #ffffff;

    .person__picture {
      width: 35px;
      margin-right: 0.5em;

      &.has-picture {
        border-radius: 50%;
        border: 2px solid #ffffff;
      }
    }
    .person__title {
      flex: 1;
      display: flex;
      flex-direction: column;
      margin-right: 1em;
    }
    .person__name {
      font-size: 1.2em;
      color: #ffffff;
    }
    .person__link {
      font-size: 0.8em;
      color: #ffffff;
    }
  }

  // Empty state at the right
  .panel-section-empty {
    position: absolute;
    width: 41%;
    height: 100%;
    left: 59%;
    top: 0;

    box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px @brand-primary;

    .panel-heading {
      background-color: @brand-primary;

      a {
        padding: 0;
      }
    }

    .panel-body {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    // Reduce padding on small screens to save space
    @media (max-width: @screen-sm-max) {
      .empty-state {
        padding: 0.5em;
      }
    }

    // Hide icon on extra-small screens to save space
    @media (max-width: @screen-xs-max) {
      i.fa {
        display: none;
      }
    }
  }
}
