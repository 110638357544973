#absence-detail__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  #absence-detail__header__actions {
    justify-content: end;
    float: right;
    margin-left: 5px;
  }
}
