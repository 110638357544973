.group-list {
  .table > tbody > tr > td {
    position: relative;
  }

  .group-list__row-label {
    background-color: #eee;
    font-size: 12px;
  }

  .group-list__title {
    flex: 1;
  }

  .group-list__members {
    display: inline-block;
    vertical-align: middle;

    .group-list__member {
      display: inline-block;
      margin: 0 2px;
    }
  }
}
