@import '../form-response-people-section/form-response-people-section.component.less';

.cd-form-response {
  &.is-root {
    padding-bottom: 1em;
  }

  .form-response__component {
    &.is-in-root-list {
      padding-left: 1.5em;
    }

    &.full-width {
      padding-right: 1.5em;
    }

    &:not(:last-child) {
      margin-bottom: 1.5em;
    }
  }

  .panel {
    margin-bottom: 10px;

    .panel-heading {
      background-color: #f0f0f0;
    }

    &.panel--contact {
      .panel-heading {
        background-color: #0197be;
        box-shadow: 0 1px 3px 0 #0197be, 0 0 0 1px #0197be;
        color: #fff;
      }
    }
  }

  &.has-diff {
    .form-response-normal-section {
      .left-width();
    }
  }

  &:not(.has-diff) {
  }
}
