.date-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 2px solid @brand-primary;
  border-radius: 4px;
  color: @brand-primary;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  width: 40px;
  height: 40px;

  .date-box__day {
    color: @brand-primary;
    font-size: 100%;
    font-weight: 400;
  }

  .date-box__month {
    color: @brand-primary;
    font-size: 55%;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: -4px;
  }
}
