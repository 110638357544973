.ui-select-expansible-box {
  display: flex;
  flex-wrap: wrap;
}

.ui-select-expansible-box-component {
  display: flex;
  align-items: center;
  margin-right: 3px;
  margin-bottom: 3px;
}

.resource__selector {
  .resource__selector--church__div {
    padding: 10px 0;

    .resource__selector--church__li {
      border-bottom: 1px solid #ddd;
    }
  }
}

.address__selector-button {
  display: flex;
  flex-direction: row;
  .fa-times {
    position: absolute;
    right: 5px;
    top: 10px;
  }
}

.address__selector {
  li {
    div {
      cursor: pointer;

      input {
        cursor: disabled;
        padding: 0;
        border: 1px solid transparent;
        background-color: transparent;
        &:focus {
          outline: none;
        }
      }
    }
    &.active {
      background: rgba(0, 0, 0, 0.03) !important;

      input {
        font-weight: 700 !important;
      }
    }
  }
}

button.btn.btn-basic:focus {
  border-color: #66afe9;
}
