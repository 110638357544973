.password-requirements {
  margin-top: 1em;

  ul {
    li {
      -ms-transition: all 0.25s ease-in-out 0s;
      transition: all 0.25s ease-in-out 0s;

      &.completed {
        opacity: 0.5;

        &:before {
          color: #d0d0d0;
        }
      }

      &.red,
      &.red:before {
        color: @brand-danger;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .grid {
      .column:first-of-type {
        padding: 1rem 0 0 0 !important;
      }

      .column:last-of-type {
        padding: 6px 0 1rem 0 !important;
      }
    }
  }
}
