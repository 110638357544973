#intention-report-template {
  padding-right: @gutter;

  .file-drop-area {
    border: 2px dashed #eee;
    border-radius: 6px;
    transition: 0.4s all ease-in-out;
    padding: 4em;
    cursor: pointer;
    text-align: center;
    background: #ffffff;

    h4 {
      display: flex;
      flex-direction: column;
    }

    &.drop-area-over {
      border-color: #008db6;
    }
    &.ng-not-empty:not(.ng-valid-parse) {
      pointer-events: none;
    }
  }
}
