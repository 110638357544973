/**
 * Mixins
 *
 * Snippets of reusable CSS to develop faster and keep code readable
 */

/*
 * Center-align a block level element
 */
.center-block() {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/*
 * Sizing shortcuts
 */
.size(@height, @width) {
  width: @width;
  height: @height;
}

.square(@size) {
  .size(@size, @size);
}

/*
 * Text overflow
 *
 * Requires inline-block or block for proper styling
 */
.text-overflow() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/*
 * Inline block
 */
.inline-block {
  display: inline-block;
}

/*
 * Position absolute
 */
.position (@left, @top) when (@left >= 0) {
  left: @left;
}

.position (@left, @top) when (@left < 0) {
  right: @left * -1;
}

.position (@left, @top) when (@top >= 0) {
  top: @top;
}

.position (@left, @top) when (@top < 0) {
  bottom: @top * -1;
}

.position (@left, @top) {
  position: absolute;
}

/*
 * Visibility
 */
.hide(@important: true) {
  display: none !important;
}

.hide() {
  display: none;
}

.transition-delay(@time) {
  -moz-transition-delay: @time; // Firefox 4
  -webkit-transition-delay: @time; // Safari and Chrome
  -o-transition-delay: @time; // Opera
  transition-delay: @time;
}

/*
 * Margins
 */
.vertical-margins(@top, @bottom) {
  margin-top: @top;
  margin-bottom: @bottom;
}

.horizontal-margins(@right, @left) {
  margin-right: @right;
  margin-left: @left;
}

.vertical-margin(@y) {
  .vertical-margins(@y, @y);
}

.horizontal-margin(@x) {
  .horizontal-margins(@x, @x);
}

.vertical-paddings(@top, @bottom) {
  padding-top: @top;
  padding-bottom: @bottom;
}

.horizontal-paddings(@right, @left) {
  padding-right: @right;
  padding-left: @left;
}

.vertical-padding(@y) {
  .vertical-paddings(@y, @y);
}

.horizontal-padding(@x) {
  .horizontal-paddings(@x, @x);
}

.rounded-corners (@radius: 5px) {
  border-radius: @radius;
  -webkit-border-radius: @radius;
  -moz-border-radius: @radius;
}

/**
 * @a is the background color. @b is the color for dark backgrounds, @c for light.
 */
.color-on-background (@a, @b: white, @c: black) when (lightness(@a) < 60%) {
  color: @b;
}
.color-on-background (@a, @b: white, @c: black) when (lightness(@a) >= 60%) {
  color: @c;
}

.color-X(@index) when (@index > 0) {
  .color-@{index} {
    .color-(@index);
  }

  .color-X(@index - 1);
}

/**
 * Sticky element at the top of the window
 */
.sticky (@top, @left, @widthOffset, @zIndex: 99) {
  position: fixed;
  top: @top;
  left: @left;
  width: ~'calc(100% - @{widthOffset})';
  z-index: @zIndex;
  margin: 0;
  border: none;
  border-radius: 0;
  box-shadow: 0px 3px 10px -2px #d4d4d5;
}

/**
 * Calendar mixins
 */
.event-color(@color) {
  color: white;
  background-color: @color;
  border-color: @color;

  .fc-event-skin {
    color: inherit;
  }

  &.fc-event-reply-no-answer,
  &.fc-event-reply-maybe {
    background-color: #fff;
    color: @color;
  }

  &.fc-event-reply-no {
    background-color: #fff;
    color: @color;
    text-decoration: line-through;
  }
}

.resource-color(@color) {
  color: white;
  border-color: desaturate(@color, 10%);
  background-color: @color !important;

  .fc-event-skin {
    color: inherit;
  }
}

/**
 * Dimmer
 */
.dimmer(@background-color: #f0f2f5, @background-opacity: 1) {
  position: absolute;
  top: auto;
  left: auto;
  width: 100%;
  height: 100%;
  line-height: 1;
  display: block;
  z-index: 1;
  text-align: center;
  border-radius: 0;
  background-color: @background-color;
  opacity: @background-opacity;
}

/**
 * Middle Align Content
 */
.middle-align-content() {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
