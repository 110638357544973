.empty-state {
  padding: 2em;
  text-align: center;
  clear: both;

  .empty-state__title {
    margin-top: 1em;
  }
  .empty-state__subtitle {
    margin-top: 0.5em;
    white-space: pre-wrap;
  }
  .empty-state__extra {
    margin-top: 1.25em;
  }
  .empty-state__image {
    width: 65vw;
    margin: 1.5vw auto 0;
    box-shadow: 0 1px 3px 0 rgba(21, 27, 38, 0.15);
    border: 1px solid #eee;
  }
  .empty-state__image-caption {
    margin-top: 1.5em;
  }

  &.empty-state--compact {
    padding: 0;
  }
}
