.confirmation-wrapper {
  padding: 2em;
  text-align: center;
  clear: both;

  .confirmation-wrapper__icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 40%;
    margin: 0 auto;
  }

  .confirmation-wrapper__title {
    margin-top: 1em;
    line-height: inherit;
  }
  .confirmation-wrapper__subtitle {
    margin-top: 0.5em;
    white-space: pre-wrap;
  }
}
