/**
 * Overflowing for intentions tables, horizontally scrollable
 */
.intentions-table-h-scroll {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 260px;
  margin-bottom: -250px;

  table {
    background: linear-gradient(90deg, #fff 100%, rgba(255, 255, 255, 0)) 0 0,
      linear-gradient(270deg, #fff 100%, rgba(255, 255, 255, 0)) 100% 0,
      linear-gradient(90deg, #f0f0f0 0, rgba(255, 255, 255, 0)) 0 0,
      linear-gradient(270deg, #f0f0f0 0, rgba(255, 255, 255, 0)) 100% 0;
    background-repeat: no-repeat;
    background-color: #fff;
    background-size: 16px 100%;
    background-attachment: local, local, scroll, scroll;
  }
}
