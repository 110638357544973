.advanced-pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .pagination {
    margin-bottom: 0.5em;
  }

  .advanced-pagination__dropdown {
    .dropdown-toggle {
      font-weight: 700;
      color: rgba(0, 0, 0, 0.6);

      &:hover {
        color: rgba(0, 0, 0, 0.95);
      }
    }
  }

  &.advanced-pagination--sm {
    .pagination {
      li a {
        padding: 0.6em 1em !important;
        font-size: 13px;
      }
    }
  }
}
