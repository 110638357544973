#people-import {
  .alert--attached-top {
    margin: -15px -15px 10px;
    border: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .import-step {
    hr {
      margin-left: 50px;
    }
  }

  .import-step__header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .import-step__number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-right: 15px;
    font-size: 1.3em;
    color: white;
    background-color: @brand-primary;
    box-shadow: 0px 1px 5px 0px rgba(34, 36, 38, 0.24);
    cursor: default;
  }

  .import-step__label {
    flex: 1;
    font-size: 1.4em;
  }

  .import-step__field {
    padding-left: 55px;
  }

  .dropdown-menu .dropdown-header {
    text-transform: capitalize;
    color: 'grey';
  }
}
