.form-conflicts-modal {
  .modal-body {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .form-conflicts-modal__header {
    font-weight: bold;
    margin-bottom: 5px;
  }

  .form-conflicts-modal__subheader {
    margin-bottom: 10px;
  }

  .list-group-item {
    padding: 8px 0 2px 14px;

    ul {
      padding-left: 2em;
    }
  }
}
