.folders {
  .folders__folder {
    padding: 10px 20px;
    transition: 0.1s background-color ease-in-out;

    &.folders__folder--selected,
    &:hover {
      background-color: fade(@brand-primary, 10%);
    }

    &:not(:last-child) {
      border-bottom: 1px solid #eee;
    }
  }
}
