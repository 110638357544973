.column-manager {
  ul.dropdown-menu {
    width: 300px;
    max-height: 400px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    li > label {
      display: block;
      padding: 8px 10px;
      margin: 0;
      line-height: 20px;
      font-weight: 400;
      cursor: pointer;
      transition: background-color 0.1s ease-in-out;

      &:hover {
        text-decoration: none;
        background-color: #f5f5f5;
      }
    }
  }
}
