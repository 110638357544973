cd-file-uploader {
  display: block;
  width: 100%;
  height: 100%;
}

.file-uploader {
  height: 100%;

  .file-queue {
    display: flex;
    flex-direction: column;
    height: 100%;

    .file-queue__header,
    .file-queue__footer {
      flex: 0 0 auto;
      padding: 20px;
      background-color: #fff;
      z-index: 10;
    }

    .file-queue__header {
      box-shadow: 0px 2px 10px -2px #ccc;
    }

    .file-queue__footer {
      box-shadow: 0px -2px 10px -2px #ccc;
    }

    .file-queue__body {
      flex: 1 1 auto;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
    }

    .file-queue__item {
      padding: 15px 20px;

      &:not(:last-child) {
        border-bottom: 1px solid #eee;
      }
    }

    &.ng-invalid {
      .form-control.ng-invalid {
        border-color: @brand-danger;
      }
    }
  }
}
