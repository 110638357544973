.app-nav {
  display: flex;
  flex: 0 0 @navWidth;
  max-width: @navWidth;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  background-color: @sidebar-bg;
  box-shadow: 0 0 20px rgba(34, 36, 38, 0.15);
  z-index: @navPosition;

  .app-nav__items {
    width: 100%;
  }

  .app-nav__item {
    display: flex;
    flex-direction: column;
    position: relative;
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);

    a {
      display: flex;
      flex-direction: column;
      padding: 10px 8px;
      text-align: center;
      text-decoration: none;
      line-height: 1;
      color: white;

      &:focus,
      &:hover {
        background: rgba(255, 255, 255, 0.08);
        color: #ffffff;
      }
    }

    i {
      display: block;
      font-size: 20px;
      margin: 0 auto 8px;
    }

    .app-nav__item-text {
      padding: 0;
      font-size: 12px;
      line-height: normal;
      word-wrap: break-word;
      hyphens: auto;
    }
  }

  .app-nav__item--active {
    background: none !important;

    a::after {
      position: absolute;
      top: 50%;
      right: 0;
      bottom: auto;
      left: auto;
      margin: 0;
      border: none;
      content: '';
      background: 0 0;
      width: 0.57142857em;
      height: 0.57142857em;
      z-index: 2;
      background-color: @body-bg !important;
      -webkit-transform: translateX(50%) translateY(-50%) rotate(45deg);
      transform: translateX(50%) translateY(-50%) rotate(45deg);
    }
  }
}
