.pastoral-notes-list {
  .pastoral-notes-toolbar {
    position: relative;
    border: 1px solid #d4d4d5;
    border-bottom: none;
    background: #fff;
    padding: 1em;
    border-radius: 0.3em 0.3em 0 0;
  }

  .pastoral-notes-list-table {
    border: 1px solid #d4d4d5;
    background-color: #fff;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    .pastoral-note {
      flex-grow: 1;
      margin: auto 0;
    }
  }
}
